import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAll, remove } from "@/actions/sliders.action";
import Table from "@/components/core/Table";
import { url } from "@/utils";

export default function SliderList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const sliders = useSelector((state: any) => state.sliders);
  console.log(sliders.sliders);
  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  useEffect(() => {
    if (sliders.status === "idle") {
      dispatch(getAll() as any);
    }
  }, [dispatch, sliders]);

  if (sliders?.sliders?.length > 0) {
    return (
      <div>
        <Table
          data={sliders.sliders}
          pageSizeOptions={[5, 10, 25]}
          whitelist={["title"]}
          onDelete={handleDelete}
          onEdit={(id: string) => {
            navigation(url("Admin.Sliders.Edit", { id: id }));
          }}
        />
      </div>
    );
  }

  return (
    <div className="text-center p-4 bg-yellow-300 text-gray-600 font-bold text-lg rounded shadow">
      No Content
    </div>
  );
}
