import React, { useMemo, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import OptionsMenu from "./TableOptionMenu";

interface DynamicTableProps {
  data: any[];
  pageSizeOptions?: number[];
  whitelist?: string[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

const Table: React.FC<DynamicTableProps> = ({
  data,
  pageSizeOptions = [5, 10],
  whitelist = [],
  onEdit,
  onDelete,
}) => {
  const columns: GridColDef[] = useMemo(() => {
    if (data.length === 0) return [];

    const fields = Object.keys(data[0]).filter((key) =>
      whitelist.length > 0 ? whitelist.includes(key) : true
    );

    const dataColumns = fields.map((key) => ({
      field: key,
      headerName: key.charAt(0).toUpperCase() + key.slice(1),
      width: 200,
      valueGetter: (param: any) => {
        if (param === undefined || param === null) return "N/A";
        return typeof param === "object" ? JSON.stringify(param) : param;
      },
    }));

    const optionsColumn: GridColDef = {
      field: "options",
      headerName: "Options",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation(); // Prevent row selection on click
          // You can manage the menu state in the parent or directly in the component
        };

        return (
          <Stack direction="row" spacing={1}>
            <OptionsMenu
              id={params.row.id}
              onEdit={onEdit}
              onDelete={onDelete}
              onOpenMenu={handleMenuOpen} // Pass the handler to OptionsMenu
            />
          </Stack>
        );
      },
    };

    return [...dataColumns, optionsColumn];
  }, [data, whitelist, onDelete, onEdit]);

  const rows = useMemo(() => {
    return data.map((item, index) => ({
      id: item._id,
      ...item,
    }));
  }, [data]);

  return (
    <Paper sx={{ height: 800 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={pageSizeOptions}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
          },
        }}
        checkboxSelection
        sx={{ border: 0 }}
      />
    </Paper>
  );
};

export default Table;
