import React from "react";
import MultiStepForm from "../../../../../components/auth/admin/MultiStepForm";

export default function CreateCourse() {
  return (
    <div className="p-4 w-full bg-white rounded shadow-lg">
      <MultiStepForm />
    </div>
  );
}
