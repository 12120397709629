import React from "react";

interface InputErrorProps {
  readonly messages?: any;
  readonly className?: string;
}

export default function InputError({
  messages,
  className = "",
  ...props
}: InputErrorProps) {
  // `messages`'ın array olup olmadığını kontrol edin, değilse boş bir array kullanın
  return (
    <div>
      {messages?.errors?.map((message, index) => (
        <p
          key={index} // Her eleman için benzersiz bir key ekleyin
          {...props}
          className={"text-sm text-red-600 dark:text-red-400 " + className}
        >
          {message}
        </p>
      ))}
    </div>
  );
}
