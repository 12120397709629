import { getSiteURL } from './lib/get-site-url';
import { LogLevel } from './lib/logger';

export interface Config {
  site: { name: string; description: string; themaColor: string; url: string };
  logLevel: keyof typeof LogLevel;
}

export const config: Config = {
  site: {
    name: 'Rovet',
    description: 'Online Learning Platform',
    themaColor: '#FF0000',
    url: getSiteURL(),
  },
  logLevel:
    (process.env.REACT_APP_LOG_LEVEL as keyof typeof LogLevel) || LogLevel.ALL,
};
