interface CategoryButtonProps {
  category: any;
  isSelected: boolean;
  onClick: () => void;
}

const CategoryButton: React.FC<CategoryButtonProps> = ({
  category,
  isSelected,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className={`category-button ${
        isSelected ? "category-button-selected" : ""
      }`}
    >
      <h2 className="font-semibold">{category.name}</h2>
    </button>
  );
};

export default CategoryButton;
