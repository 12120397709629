// src/pages/Forbidden.tsx
import { useLocationContext } from "@/context/LocationContext";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Forbidden = () => {
  const navigate = useNavigate();
  const { previousPath } = useLocationContext();
  console.log(previousPath);

  // Kullanıcıyı geri gönderen fonksiyon
  const redirectBack = () => {
    const from = previousPath || "/";
    navigate(from, { replace: true });
  };

  // Belirli bir süre sonra yönlendirme yapmak (opsiyonel)
  useEffect(() => {
    const timer = setTimeout(redirectBack, 5000); // 5 saniye sonra geri gönder
    return () => clearTimeout(timer); // Bileşen unmount olduğunda temizle
  });

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>403 - Erişim Engellendi</h1>
      <p>Bu sayfayı görüntüleme yetkiniz yok.</p>
      <button
        onClick={redirectBack}
        style={{ padding: "10px 20px", marginTop: "20px" }}
      >
        Geri Dön
      </button>
    </div>
  );
};

export default Forbidden;
