import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Checkbox,
} from "@mui/material";
import AddResourceModal from "./AddResourceModal";
import AddSectionModal from "./AddSectionModal";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  addResource,
  removeResource,
  removeSection,
  updateResource,
} from "../../../../../../actions/courses.actions";
import { ResourceDto } from "@/data/dtos/resource.dto";
import { SectionDto } from "@/data/dtos/section.dto";

interface EditCourseSectionProps {
  formData: any;
  setFormData: any;
  onSelectResource: (resource: ResourceDto) => void; // Yeni prop
}

export const EditCourseSection: React.FC<EditCourseSectionProps> = ({
  formData,
  setFormData,
  onSelectResource,
}) => {
  const dispatch = useDispatch();
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>(
    null
  );
  const [editingSection, setEditingSection] = useState<SectionDto | null>(null);
  const [currentResource, setCurrentResource] = useState<ResourceDto | null>(
    null
  );

  const handleAddResource = (newResource: ResourceDto) => {
    if (selectedSectionId) {
      setFormData({
        ...formData,
        sections: formData.sections?.map((section) =>
          section._id === selectedSectionId
            ? {
                ...section,
                resources: [...(section.resources || []), newResource],
              }
            : section
        ),
      });
      console.log(newResource);
      dispatch(addResource(newResource) as any);
    }
  };

  const handleUpdateResource = (updatedResource: ResourceDto) => {
    const updatedSections = formData.sections.map((section) =>
      section._id === selectedSectionId
        ? {
            ...section,
            resources: section.resources.map((res) =>
              res._id === updatedResource._id ? updatedResource : res
            ),
          }
        : section
    );
    console.log(updatedSections);
    dispatch(
      updateResource(updatedResource._id!, {
        title: updatedResource.title,
        type: updatedResource.type,
        order: updatedResource.order,
        course: updatedResource.course,
        video: updatedResource.video,
        quizz: updatedResource.quizz,
        liveClass: updatedResource.liveClass,
      }) as any
    );
  };

  const handleDeleteResource = (resourceId: string) => {
    const updatedSections = formData.sections.map((section) =>
      section._id === selectedSectionId
        ? {
            ...section,
            resources: section.resources.filter(
              (resource) => resource._id !== resourceId
            ),
          }
        : section
    );
    setFormData({
      ...formData,
      sections: updatedSections,
    });

    // Backend'e silme işlemi için dispatch gönderelim
    dispatch(removeResource(resourceId) as any);
  };

  const handleAddSection = (newSection: SectionDto) => {
    // Yeni eklenen bölümü formData'ya ekliyoruz
    setFormData({
      ...formData,
      sections: [...(formData.sections || []), newSection],
    });
  };

  const handleEditResource = (resource: ResourceDto) => {
    setCurrentResource(resource);
    setIsResourceModalOpen(true); // Kaynağı düzenlemek için modalı açıyoruz
  };

  const handleUpdateSection = (updatedSection: SectionDto) => {
    // Güncellenen bölümü formData'da güncelliyoruz
    setFormData({
      ...formData,
      sections: formData.sections?.map((section) =>
        section._id === updatedSection._id ? updatedSection : section
      ),
    });
  };

  const handleEditSection = (section: SectionDto) => {
    setEditingSection(section);
    setIsSectionModalOpen(true);
  };

  const handleDeleteSection = (sectionId: string) => {
    const updatedSections = formData.sections.filter(
      (section: any) => section._id !== sectionId
    );

    // formData'yı kopyalayarak güncellenmiş sections ile güncelle
    setFormData({
      ...formData,
      sections: updatedSections,
    });

    // Backend ile silme işlemi
    dispatch(removeSection(sectionId) as any);

    // formData'yı kopyalayıp ilgili bölümü çıkarıyoruz
  };

  return (
    <div className="w-1/5">
      <Box>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Kurs Bölümler
        </Typography>
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setEditingSection(null);
              setIsSectionModalOpen(true);
            }}
            sx={{ mb: 2 }}
          >
            Bölüm Ekle
          </Button>
          {formData?.sections
            ?.sort((a, b) => a.order - b.order) // Bölümleri 'order' alanına göre sıralıyoruz
            .map((section, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography sx={{ flexGrow: 1 }}>{section.title}</Typography>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation(); // Accordion'un açılmasını engeller
                      handleEditSection(section);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteSection(section._id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setSelectedSectionId(section._id);
                      setIsResourceModalOpen(true);
                    }}
                    sx={{ mb: 2 }}
                  >
                    Kaynak Ekle
                  </Button>
                  {section.resources
                    ?.sort((a, b) => a.order - b.order)
                    .map((resource, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "8px",
                          marginBottom: "8px",
                          backgroundColor: "#f1f1f1",
                          borderRadius: "4px",
                        }}
                      >
                        <Checkbox />
                        <Typography
                          onClick={() => onSelectResource(resource)}
                          style={{ flexGrow: 1, cursor: "pointer" }}
                        >
                          {resource.title}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleEditResource(resource)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteResource(resource._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      </Box>

      {/* Modallar */}
      <AddSectionModal
        open={isSectionModalOpen}
        onClose={() => setIsSectionModalOpen(false)}
        onAddSection={handleAddSection}
        onUpdateSection={handleUpdateSection}
        section={editingSection ?? undefined} // Düzenlenecek bölümü modal'a geçiriyoruz
      />

      <AddResourceModal
        open={isResourceModalOpen}
        sectionId={selectedSectionId ?? ""}
        onClose={() => {
          console.log("Modal kapatıldı");
          setCurrentResource(null);
          setIsResourceModalOpen(false);
        }}
        onAddResource={handleAddResource}
        onUpdateResource={handleUpdateResource} // Yeni fonksiyonu ekledik
        resource={currentResource}
      />
    </div>
  );
};
