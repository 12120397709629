import { create, getAll, remove, update } from "@/actions/image.actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Images() {
  const dispatch = useDispatch();
  const { images, status } = useSelector((state: any) => state.images);
  const [imageList, setImageList] = useState([]);
  const [viewMode, setViewMode] = useState<
    "list" | "small-icons" | "large-icons"
  >("list");
  const [newImage, setNewImage] = useState({ url: "", altText: "" });
  const [editingImage, setEditingImage] = useState<any>(null);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAll() as any);
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (images.length > 0) {
      setImageList(images);
    }
  }, [images]);

  const handleAddImage = () => {
    if (newImage.url.trim() && newImage.altText.trim()) {
      dispatch(create(newImage) as any);
      setNewImage({ url: "", altText: "" });
    }
  };

  const handleDeleteImage = (id: string) => {
    dispatch(remove(id) as any);
  };

  const handleEditImage = (image: any) => {
    setEditingImage(image);
  };

  const handleUpdateImage = () => {
    if (editingImage) {
      dispatch(update(editingImage._id, editingImage) as any);
      setEditingImage(null);
    }
  };

  return (
    <div className="p-4 w-full bg-white rounded shadow-lg">
      {/* Görüntüleme Seçenekleri */}
      <div className="w-full flex justify-between items-center mb-4">
        <span className="text-xl font-bold">Görseller</span>
        <div className="flex items-center gap-4" id="display-icons">
          <button onClick={() => setViewMode("list")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className={`w-8 h-8 ${
                viewMode === "list" ? "text-blue-500" : "text-gray-500"
              }`}
              fill="currentColor"
            >
              <rect x="4" y="5" width="16" height="2" rx="1" />
              <rect x="4" y="11" width="16" height="2" rx="1" />
              <rect x="4" y="17" width="16" height="2" rx="1" />
            </svg>
          </button>
          <button onClick={() => setViewMode("small-icons")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className={`w-8 h-8 ${
                viewMode === "small-icons" ? "text-blue-500" : "text-gray-500"
              }`}
              fill="currentColor"
            >
              <rect x="4" y="5" width="6" height="6" rx="1" />
              <rect x="14" y="5" width="6" height="6" rx="1" />
              <rect x="4" y="13" width="6" height="6" rx="1" />
              <rect x="14" y="13" width="6" height="6" rx="1" />
            </svg>
          </button>
          <button onClick={() => setViewMode("large-icons")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className={`w-8 h-8 ${
                viewMode === "large-icons" ? "text-blue-500" : "text-gray-500"
              }`}
              fill="currentColor"
            >
              <rect x="4" y="4" width="8" height="8" rx="1" />
              <rect x="12" y="4" width="8" height="8" rx="1" />
              <rect x="4" y="12" width="8" height="8" rx="1" />
              <rect x="12" y="12" width="8" height="8" rx="1" />
            </svg>
          </button>
        </div>
      </div>

      {/* Görsel Ekleme Alanı */}
      <div className="mb-6">
        <h3 className="text-lg font-bold mb-2">Yeni Görsel Ekle</h3>
        <div className="flex gap-4 items-center">
          <input
            type="text"
            placeholder="Görsel URL"
            value={newImage.url}
            onChange={(e) => setNewImage({ ...newImage, url: e.target.value })}
            className="border rounded px-3 py-2 w-1/3"
          />
          <input
            type="text"
            placeholder="Açıklama"
            value={newImage.altText}
            onChange={(e) =>
              setNewImage({ ...newImage, altText: e.target.value })
            }
            className="border rounded px-3 py-2 w-1/3"
          />
          <button
            onClick={handleAddImage}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Ekle
          </button>
        </div>
      </div>

      {/* Görseller */}
      <div>
        {viewMode === "list" && (
          <ul className="space-y-4">
            {imageList.map((image: any) => (
              <li key={image._id} className="flex items-center gap-4">
                <img
                  src={image.url}
                  alt={image.altText}
                  className="w-16 h-16   rounded"
                />
                <div className="flex-1">
                  {editingImage && editingImage._id === image._id ? (
                    <input
                      type="text"
                      value={editingImage.altText}
                      onChange={(e) =>
                        setEditingImage({
                          ...editingImage,
                          altText: e.target.value,
                        })
                      }
                      className="border rounded px-3 py-2 w-full"
                    />
                  ) : (
                    <p className="font-bold">
                      {image.altText || "No Description"}
                    </p>
                  )}
                </div>
                <div className="flex gap-2">
                  {editingImage && editingImage._id === image._id ? (
                    <button
                      onClick={handleUpdateImage}
                      className="bg-green-500 text-white px-3 py-1 rounded"
                    >
                      Kaydet
                    </button>
                  ) : (
                    <button
                      onClick={() => handleEditImage(image)}
                      className="bg-yellow-500 text-white px-3 py-1 rounded"
                    >
                      Düzenle
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteImage(image._id)}
                    className="bg-red-500 text-white px-3 py-1 rounded"
                  >
                    Sil
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
        {viewMode === "small-icons" && (
          <div className="grid grid-cols-4 gap-4">
            {imageList.map((image: any) => (
              <div
                key={image._id}
                className="flex flex-col items-center relative w-full"
              >
                <img
                  src={image.url}
                  alt={image.altText}
                  className="w-full min-w-16 min-h-16 rounded"
                />
                <p className="text-xs text-gray-500 mt-2">
                  {image.altText || "No Description"}
                </p>
                <button
                  onClick={() => handleDeleteImage(image._id)}
                  className="absolute top-0 right-0 bg-red-500 text-white px-1 rounded"
                >
                  Sil
                </button>
              </div>
            ))}
          </div>
        )}
        {viewMode === "large-icons" && (
          <div className="grid grid-cols-2 gap-8">
            {imageList.map((image: any) => (
              <div key={image._id} className="flex flex-col items-center">
                <img
                  src={image.url}
                  alt={image.altText}
                  className="w-full rounded-lg"
                />
                <p className="mt-2 font-bold">
                  {image.altText || "No Description"}
                </p>
                <button
                  onClick={() => handleDeleteImage(image._id)}
                  className="bg-red-500 text-white px-4 py-2 rounded mt-2"
                >
                  Sil
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
