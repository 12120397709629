import React from "react";

export default function AboutFictionalEvent() {
  return (
    <div>
      {" "}
      <section className="py-24 relative">
        <div id="about" className="relative bg-white overflow-hidden mt-16">
          <div className="max-w-7xl mx-auto">
            <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
              <svg
                className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                fill="currentColor"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                aria-hidden="true"
              >
                <polygon points="50,0 100,0 50,100 0,100"></polygon>
              </svg>

              <div className="pt-1"></div>

              <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                  <h2 className="my-6 text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                    Kurgusal Etkinlik Platformumuz Hakkında
                  </h2>

                  <p>
                    Say Medya markası ile 2003 yılından bu yana sektördeki
                    markalara iletişim stratejileri alanında faaliyet veriyoruz.
                    Yayıncılık alanında Sayda markası ile elde ettiğimiz
                    tecrübeleri aktardığımız online eğitim platformumuzun yanına
                    Say Medya markası ile Reklam, Medya Planlama ve PR alanında
                    elde ettiğimiz tecrübeleri de ‘Kurgusal Etkinlik’ başlığı
                    altında sunma kararı alarak, alanında duayen isimleri her
                    iki bölümde de sizler için hizmet verebilir hale getirmeyi
                    hedefledik. Peki Kurgusal Etkinlik ile ne demek istiyoruz?
                    Kurumların ihtiyacı olan etkinlik ve organizasyonlarda ilham
                    veren konuşmaları kurumunuzun ihtiyacına yönelik
                    özelleştirerek sunmaktan söz ediyoruz. Özetle standart
                    içerikteki eğitim paketleri ile seslendiğimiz online eğitim
                    platformumuzdan farklı olarak sizleri burada özelleştirilmiş
                    içerik ile buluşturmayı vaat ediyoruz ki yeni nesil iş
                    dünyası için bu noktada tercih edilir olacağımız
                    kanaatindeyiz. Kurgusal Etkinlik Başlığı Altında Verdiğimiz
                    Hizmetlerin Açılımı Bize vereceğiniz hedef brief çalışması
                    ardından sizler için yapmayı planladığınız etkinliğe uygun;
                    *İlham veren konuşmayı kim yapabilir? *İlham veren konuşmacı
                    bu konuşmayı hangi içerikte yapabilir? *Bu etkinliği kim
                    sunabilir/moderatörlüğünü kim yapabilir? *Etkinlikte kurumu
                    temsil eden kurum yetkilisinin sunumu nasıl olmalı ve nasıl
                    sunulmalı? Sunum ve Akış hazırlama hizmetleri… *Etkinlik
                    adına PR çalışması/Etkinliğin medyaya yansımasının
                    planlanması *Etkinliğin hedefinin eş zamanlı olarak sosyal
                    medya projelerine, yotube projelerine ve kanaat önderi iş
                    insanları ile gerçekleştirilecek influencer çalışmalarına
                    dönüştürülmesi ve uygulanması süreçlerinin kurgu ve yönetimi
                    hizmet açılımımızı tanımlamaktadır. Bu Hizmeti Kimler İle
                    Vermeyi Hedefledik? Ulusal ve Uluslararası üne sahip iş
                    insanları, oyuncular, sanatçılar ve kanaat önderi
                    ‘influencer’lar ile verebilecek kapasiteye sahibiz. Vizyon
                    toplantılarınız, yıl/dönem sonu toplantılarınız, bölge
                    toplantılarınız, bölge bayi ve bayi toplantılarınız, lansman
                    toplantılarınız, motivasyon toplantılarınız, kurum içi
                    eğitimleriniz, personel motivasyon etkinlikleriniz ve
                    workshoplarınızda yukarıdaki hizmetler için bizler ile
                    iletişimde olabilirsiniz. Dileyen kurumlar bu hizmetlere ek
                    olarak A’dan Z’ye organizasyon/etkinliğin planlama ve
                    yürütme sürecinde de (mekan, konaklama, VIP Transfer, ses
                    sistemi, barkovizyon, hostes vb) hizmet alabilirler. Bilgi
                    için lütfen aşağıdaki formu doldurunuz. İlgili birimimiz en
                    kısa sürede sizler ile iletişimde olacaktır.
                  </p>
                </div>
              </main>
            </div>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover object-top sm:h-72 md:h-96 lg:w-full lg:h-full"
              src="/assets/5.jpeg"
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  );
}
