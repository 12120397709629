import { slidersService } from "../services/sliders.service";
import { setError, setMessage } from "./message";
import {
  CREATE_SLIDER_FAILED,
  CREATE_SLIDER_SUCCESS,
  FETCH_SLIDER_FAILED,
  FETCH_SLIDER_SUCCESS,
  FETCH_SLIDERS_FAILED,
  FETCH_SLIDERS_SUCCESS,
  REMOVE_SLIDER_FAILED,
  REMOVE_SLIDER_SUCCESS,
  SLIDER_REQUEST,
  UPDATE_SLIDER_FAILED,
  UPDATE_SLIDER_SUCCESS,
} from "./type";

export const create = (payload: any) => async (dispatch: any) => {
  dispatch({ type: SLIDER_REQUEST });
  await slidersService
    .create(payload)
    .then((response: any) => {
      dispatch({ type: CREATE_SLIDER_SUCCESS, payload: response.data.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: CREATE_SLIDER_FAILED });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const getAll = () => async (dispatch: any) => {
  dispatch({ type: SLIDER_REQUEST });
  await slidersService
    .getAll()
    .then((response: any) => {
      console.log(response.data);
      dispatch({ type: FETCH_SLIDERS_SUCCESS, payload: response.data.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: FETCH_SLIDERS_FAILED, payload: err });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const get = (id: string) => async (dispatch: any) => {
  dispatch({ type: SLIDER_REQUEST });
  await slidersService
    .get(id)
    .then((response: any) => {
      console.log(response.data.data);
      dispatch({ type: FETCH_SLIDER_SUCCESS, payload: response.data.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: FETCH_SLIDER_FAILED, payload: err });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const update = (id: string, payload: any) => async (dispatch: any) => {
  dispatch({ type: SLIDER_REQUEST });
  await slidersService
    .update(id, payload)
    .then((response: any) => {
      dispatch({ type: UPDATE_SLIDER_SUCCESS, payload: response.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: UPDATE_SLIDER_FAILED, payload: err });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch({ type: SLIDER_REQUEST });
  await slidersService
    .remove(id)
    .then((response: any) => {
      dispatch({ type: REMOVE_SLIDER_SUCCESS, payload: response.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: REMOVE_SLIDER_FAILED, payload: err });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};
