import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAll, remove } from "@/actions/orders.actions";
import { useDispatch, useSelector } from "react-redux";
import Table from "@/components/core/Table";
import { formatMoney, url } from "@/utils";
import { DataGrid } from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import OptionsMenu from "@/components/core/TableOptionMenu";

export default function OrderList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { orders, status } = useSelector((state: any) => state.order);

  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  useEffect(() => {
    if (status === "idle" && orders.length === 0) {
      console.log("useEffect 1");
      dispatch(getAll() as any);
    }
  }, [dispatch, status, orders]);

  const pageSizeOptions = [25, 50, 100];

  const columns = [
    {
      field: "_id",
      headerName: "Order Id",
      width: 300,
    },
    {
      field: "buyer",
      headerName: "Buyer",
      width: 200,
      valueGetter: (param: any) => param.name + " " + param.surname,
    },
    {
      field: "price",
      headerName: "Price",
      width: 200,
      valueGetter: (param: any) => {
        return formatMoney(param);
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
    },
    {
      field: "courses",
      headerName: "Courses",
      width: 200,
      valueGetter: (param: any) => {
        return param.map((course: any) => course.title).join(", ");
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation(); // Prevent row selection on click
          // You can manage the menu state in the parent or directly in the component
        };

        return (
          <Stack direction="row" spacing={1}>
            <OptionsMenu
              id={params.row._id}
              onEdit={() => {
                navigation(url("Admin.Orders.Edit", { id: params.row._id }));
              }}
              onDelete={handleDelete}
              onOpenMenu={handleMenuOpen} // Pass the handler to OptionsMenu
            />
          </Stack>
        );
      },
    },
  ];

  if (orders?.length > 0) {
    return (
      <div className="w-full bg-white">
        <DataGrid
          rows={orders}
          columns={columns}
          pageSizeOptions={pageSizeOptions}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
            },
          }}
          getRowId={(row) => row._id} // Benzersiz ID için _id kullan
        />
      </div>
    );
  } else {
    return <div>No Content</div>;
  }
}
