export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// Category Types
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_FAILED = "FETCH_FAILED";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY";
export const CREATE_CATEGORY_FAILED = "CREATE_CATEGORY_FAILED";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILED = "UPDATE_CATEGORY_FAILED";
export const REMOVE_CATEGORY_SUCCESS = "REMOVE_CATEGORY_SUCCESS";
export const REMOVE_CATEGORY_FAILED = "REMOVE_CATEGORY_FAILED";

// Course Types
export const FETCH_COURSES = "FETCH_COURSES";
export const FETCH_COURSE = "FETCH_COURSE";
export const FETCH_COURSES_SUCCESS = "FETCH_COURSES_SUCCESS";
export const FETCH_COURSES_FAILED = "FETCH_COURSES_FAILED";
export const FETCH_COURSE_SUCCESS = "FETCH_COURSE_SUCCESS";
export const FETCH_COURSE_FAILED = "FETCH_COURSE_FAILED";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_FAILED = "CREATE_COURSE_FAILED";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAILED = "UPDATE_COURSE_FAILED";
export const REMOVE_COURSE_SUCCESS = "REMOVE_COURSE_SUCCESS";
export const REMOVE_COURSE_FAILED = "REMOVE_COURSE_FAILED";
export const ADD_SECTION_TO_COURSE_SUCCESS = "ADD_SECTION_TO_COURSE_SUCCESS";
export const ADD_SECTION_TO_COURSE_FAILED = "ADD_SECTION_TO_COURSE_FAILED";
export const UPDATE_COURSE_SECTION_SUCCESS = "UPDATE_COURSE_SECTION_SUCCESS";
export const UPDATE_COURSE_SECTION_FAILED = "UPDATE_COURSE_SECTION_FAILED";
export const ADD_REVIEW_TO_COURSE_SUCCESS = "ADD_REVIEW_TO_COURSE_SUCCESS";
export const ADD_REVIEW_TO_COURSE_FAILED = "ADD_REVIEW_TO_COURSE_FAILED";

export const REMOVE_SECTION_FROM_COURSE_SUCCESS =
  "REMOVE_SECTION_FROM_COURSE_SUCCESS";
export const REMOVE_SECTION_FROM_COURSE_FAILED =
  "REMOVE_SECTION_FROM_COURSE_FAILED";
export const ADD_RESOURCE_TO_COURSE_SECTION_SUCCESS =
  "ADD_RESOURCE_TO_COURSE_SECTION_SUCCESS";
export const ADD_RESOURCE_TO_COURSE_SECTION_FAILED =
  "ADD_RESOURCE_TO_COURSE_SECTION_SUCCESS";
export const UPDATE_RESOURCE_FROM_COURSE_SECTION_SUCCESS =
  "UPDATE_RESOURCE_FROM_COURSE_SECTION_SUCCESS";
export const UPDATE_RESOURCE_FROM_COURSE_SECTION_FAILED =
  "UPDATE_RESOURCE_FROM_COURSE_SECTION_FAILED";
export const REMOVE_RESOURCE_FROM_COURSE_SECTION_SUCCESS =
  "REMOVE_RESOURCE_FROM_COURSE_SECTION_SUCCESS";
export const REMOVE_RESOURCE_FROM_COURSE_SECTION_FAILED =
  "REMOVE_RESOURCE_FROM_COURSE_SECTION_FAILED";

// Order Types
export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILED = "FETCH_ORDERS_FAILED";
export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAILED = "FETCH_ORDER_FAILED";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILED = "CREATE_ORDER_FAILED";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILED = "UPDATE_ORDER_FAILED";
export const REMOVE_ORDER_SUCCESS = "REMOVE_ORDER_SUCCESS";
export const REMOVE_ORDER_FAILED = "REMOVE_ORDER_FAILED";

// Image Types
export const FETCH_IMAGES = "FETCH_IMAGES";
export const FETCH_IMAGES_SUCCESS = "FETCH_IMAGES_SUCCESS";
export const FETCH_IMAGES_FAILED = "FETCH_IMAGES_FAILED";
export const CREATE_IMAGE_SUCCESS = "CREATE_IMAGE_SUCCESS";
export const CREATE_IMAGE_FAILED = "CREATE_IMAGE_FAILED";
export const UPDATE_IMAGE_SUCCESS = "UPDATE_IMAGE_SUCCESS";
export const UPDATE_IMAGE_FAILED = "UPDATE_IMAGE_FAILED";
export const REMOVE_IMAGE_SUCCESS = "REMOVE_IMAGE_SUCCESS";
export const REMOVE_IMAGE_FAILED = "REMOVE_IMAGE_FAILED";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const FILE_UPLOADING_STARTED = "FILE_UPLOADING_STARTED";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILED = "UPLOAD_FILE_FAILED";

// Slider Types
export const SLIDER_REQUEST = "SLIDER_REQUEST";
export const FETCH_SLIDERS = "FETCH_SLIDERS";

export const FETCH_SLIDER = "FETCH_SLIDER";
export const FETCH_SLIDER_SUCCESS = "FETCH_SLIDER_SUCCESS";
export const FETCH_SLIDER_FAILED = "FETCH_SLIDER_FAILED";

export const FETCH_SLIDERS_SUCCESS = "FETCH_SLIDERS_SUCCESS";
export const FETCH_SLIDERS_FAILED = "FETCH_SLIDERS_FAILED";

export const CREATE_SLIDER_SUCCESS = "CREATE_SLIDER_SUCCESS";
export const CREATE_SLIDER_FAILED = "CREATE_SLIDER_FAILED";

export const UPDATE_SLIDER_SUCCESS = "UPDATE_SLIDER_SUCCESS";
export const UPDATE_SLIDER_FAILED = "UPDATE_SLIDER_FAILED";

export const REMOVE_SLIDER_SUCCESS = "REMOVE_SLIDER_SUCCESS";
export const REMOVE_SLIDER_FAILED = "REMOVE_SLIDER_FAILED";

// User Types
export const GET_PUBLIC_CONTENT = "GET_PUBLIC_CONTENT";
export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const REMOVE_USER_REQUEST = "REMOVE_USER_REQUEST";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const REMOVE_USER_FAILED = "REMOVE_USER_FAILED";
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
