import { CreateOrderInterface } from "@/data/interfaces/OrderInterface";
import axios from "axios";

const create = async (payload: CreateOrderInterface) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/orders",
    payload
  );
  return response;
};

const getAll = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/orders"
  );
  return response;
};

const get = async (id: any) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/orders/" + id
  );
  return response;
};

const update = async (id: any, payload: any) => {
  const response = await axios.patch(
    process.env.REACT_APP_API_BASE_URL + "/orders/" + id,
    payload
  );
  return response;
};

const remove = async (id: any) => {
  const response = await axios.delete(
    process.env.REACT_APP_API_BASE_URL + "/orders/" + id
  );
  return response;
};

const orderService = {
  create,
  getAll,
  get,
  update,
  remove,
};

export default orderService;
