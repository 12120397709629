import { useNavigate } from "react-router-dom";
import {
  FETCH_COURSES,
  FETCH_COURSES_SUCCESS,
  CREATE_COURSE_SUCCESS,
  UPDATE_COURSE_SUCCESS,
  REMOVE_COURSE_SUCCESS,
  CREATE_COURSE_FAILED,
  UPDATE_COURSE_FAILED,
  REMOVE_COURSE_FAILED,
  FETCH_COURSES_FAILED,
  FETCH_COURSE,
  ADD_RESOURCE_TO_COURSE_SECTION_FAILED,
  ADD_RESOURCE_TO_COURSE_SECTION_SUCCESS,
  ADD_SECTION_TO_COURSE_FAILED,
  REMOVE_SECTION_FROM_COURSE_FAILED,
  REMOVE_SECTION_FROM_COURSE_SUCCESS,
  ADD_SECTION_TO_COURSE_SUCCESS,
  UPDATE_COURSE_SECTION_FAILED,
  UPDATE_COURSE_SECTION_SUCCESS,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_FAILED,
  REMOVE_RESOURCE_FROM_COURSE_SECTION_FAILED,
  UPDATE_RESOURCE_FROM_COURSE_SECTION_FAILED,
  UPDATE_RESOURCE_FROM_COURSE_SECTION_SUCCESS,
  ADD_REVIEW_TO_COURSE_SUCCESS,
  ADD_REVIEW_TO_COURSE_FAILED,
} from "../actions/type";
import { cloneDeep } from "lodash";

interface CoursesState {
  courses: any[];
  currentCourse: any;
  status: string;
  listStatus: string;
  error: any;
}

const initialState: CoursesState = {
  courses: [],
  currentCourse: null,
  status: "idle",
  listStatus: "idle",
  error: null,
};

export default function coursesReducer(
  state = initialState,
  action: any
): CoursesState {
  const { type, payload } = action;

  switch (type) {
    case FETCH_COURSE:
      return {
        ...state,
        status: "loading",
      };

    case FETCH_COURSES:
      return {
        ...state,
        listStatus: "loading",
      };

    case FETCH_COURSES_SUCCESS:
      console.log(payload);
      return {
        ...state,
        courses: Array.isArray(payload) ? payload : [],
        listStatus: "success",
      };

    case FETCH_COURSE_SUCCESS:
      console.log(payload);
      return {
        ...state,
        currentCourse: cloneDeep(payload),
        courses: Array.isArray(state.courses)
          ? state.courses.map((course) =>
              course._id === payload._id ? cloneDeep(payload) : course
            )
          : [],
        status: "success",
      };

    case CREATE_COURSE_SUCCESS:
      return {
        ...state,
        courses: [...state.courses, cloneDeep(payload.data)],
        status: "success",
      };

    case UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        courses: state.courses.map((course: any) =>
          course._id === payload._id ? cloneDeep(payload) : course
        ),
        currentCourse: state.currentCourse,
        status: "success",
      };

    case ADD_REVIEW_TO_COURSE_SUCCESS:
      return {
        ...state,
        currentCourse: state.currentCourse,
        status: "success",
      };

    case REMOVE_COURSE_SUCCESS:
      console.log(payload);
      return {
        ...state,
        courses: state.courses.filter(
          (course: any) => course._id !== payload.data._id
        ),
        status: "success",
      };

    case ADD_SECTION_TO_COURSE_SUCCESS:
    case UPDATE_COURSE_SECTION_SUCCESS:
    case REMOVE_SECTION_FROM_COURSE_SUCCESS:
    case ADD_RESOURCE_TO_COURSE_SECTION_SUCCESS:
    case UPDATE_RESOURCE_FROM_COURSE_SECTION_SUCCESS: {
      const updatedPayload = cloneDeep(payload);
      console.log(updatedPayload);
      return {
        ...state,
        currentCourse: {
          ...state.currentCourse,
          data: updatedPayload.data,
        },
        courses: state.courses.map((course) =>
          course._id === updatedPayload.data._id ? updatedPayload.data : course
        ),
        status: "success",
      };
    }

    case FETCH_COURSE_FAILED:
    case CREATE_COURSE_FAILED:
    case UPDATE_COURSE_FAILED:
    case REMOVE_COURSE_FAILED:
    case FETCH_COURSES_FAILED:
    case UPDATE_COURSE_SECTION_FAILED:
    case ADD_SECTION_TO_COURSE_FAILED:
    case REMOVE_SECTION_FROM_COURSE_FAILED:
    case ADD_RESOURCE_TO_COURSE_SECTION_FAILED:
    case UPDATE_RESOURCE_FROM_COURSE_SECTION_FAILED:
    case REMOVE_RESOURCE_FROM_COURSE_SECTION_FAILED:
    case ADD_REVIEW_TO_COURSE_FAILED:
      return {
        ...state,
        status: "failed",
        listStatus: "failed",
        error: payload,
      };

    default:
      return state;
  }
}
