export enum LanguageEnum {
  TURKISH = 'Türkçe',
  ENGLISH = 'English',
  SPANISH = 'Spanish',
  FRENCH = 'French',
  GERMAN = 'German',
  ITALIAN = 'Italian',
  JAPANESE = 'Japanese',
  KOREAN = 'Korean',
  PORTUGUESE = 'Portuguese',
  RUSSIAN = 'Russian',
  CHINESE = 'Chinese',
  ARABIC = 'Arabic',
  INDONESIAN = 'Indonesian',
  HINDI = 'Hindi',
  VIETNAMESE = 'Vietnamese',
  MALAY = 'Malay',
  THAI = 'Thai',
  TELUGU = 'Telugu',
  PERSIAN = 'Persian',
  HEBREW = 'Hebrew',
  CATALAN = 'Catalan',
  POLISH = 'Polish',
  BULGARIAN = 'Bulgarian',
  CROATIAN = 'Croatian',
  CZECH = 'Czech',
  DANISH = 'Danish',
  DUTCH = 'Dutch',
  ESTONIAN = 'Estonian',
  FINNISH = 'Finnish',
  GREEK = 'Greek',
  HUNGARIAN = 'Hungarian',
  ICELANDIC = 'Icelandic',
  IRISH = 'Irish',
  LITHUANIAN = 'Lithuanian',
  LATVIAN = 'Latvian',
  LUXEMBOURGISH = 'Luxembourgish',
  MALTESE = 'Maltese',
  NORWEGIAN = 'Norwegian',
  ROMANIAN = 'Romanian',
  SERBIAN = 'Serbian',
  SLOVAK = 'Slovak',
  SWEDISH = 'Swedish',
  SWISS = 'Swiss',
  UKRAINIAN = 'Ukrainian',
  UZBEK = 'Uzbek',
  ZULU = 'Zulu',
  SLOVENIAN = 'Slovenian',
  OTHER = 'Other',
}
