import {
  Typography,
  Box,
  CircularProgress,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { remove, uploadFile } from "../../../../../../actions/file.actions";
import { DocumentDto } from "@/data/dtos/document.dto";

export default function DocumentSection({ formData, setFormData }) {
  const [documentFileName, setDocumentFileName] = useState("");
  const uploads = useSelector((state: any) => state.uploads);
  const dispatch = useDispatch();

  function getIconByExtension(extension: string) {
    switch (extension.toLowerCase()) {
      case "pdf":
        return <PictureAsPdfIcon color="error" />;
      case "doc":
      case "docx":
        return <DescriptionIcon color="primary" />;
      case "jpg":
      case "jpeg":
      case "png":
        return <ImageIcon color="secondary" />;
      default:
        return <InsertDriveFileIcon color="action" />;
    }
  }

  const onDrop = async (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const documentFromData = new FormData();
      documentFromData.append("file", file);
      dispatch(uploadFile(documentFromData) as any);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const saveDocument = () => {
    const documentFromData: DocumentDto = {
      url: uploads.URL,
      fileName: documentFileName,
      uploadedAt: new Date(),
    };

    // formData'nın mevcut durumunu klonlayarak güncelleme işlemini gerçekleştiriyoruz
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: [...(prevFormData.documents || []), documentFromData],
    }));

    setDocumentFileName("");
  };

  const removeDocument = (index: number) => {
    let documentToRemove = formData.documents[index];
    documentToRemove._id = formData._id;
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: prevFormData.documents.filter((_, i) => i !== index),
    }));
    dispatch(remove(documentToRemove) as any);
  };

  return (
    <div className="w-full">
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Kurs Dokümanlar
      </Typography>
      <div className=" grid grid-cols-2 gap-4">
        <div className="grid grid-cols-4 gap-4 ">
          {formData.documents?.map((document, index) => {
            const extension = document.fileName.split(".").pop();
            return (
              <Box
                sx={{
                  display: "flex",
                  border: "2px solid gray",
                  padding: 4,
                  alignItems: "center",
                  gap: 4,
                  flexDirection: "column",
                  borderRadius: 4,
                  maxHeight: "10rem",
                  position: "relative",
                }}
                key={index}
              >
                {getIconByExtension(extension || "")}
                <Typography sx={{ marginLeft: 1 }}>
                  {document.fileName}
                </Typography>
                <IconButton
                  aria-label="delete"
                  color="error"
                  onClick={() => removeDocument(index)}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            );
          })}
        </div>
        <Box
          sx={{
            border: "2px solid gray",
            padding: 4,
            textAlign: "center",
            borderRadius: 4,
          }}
        >
          {uploads?.status === "uploading" || uploads?.status === "started" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: 2,
                flexFlow: "column",
              }}
            >
              <CircularProgress sx={{ width: "100%" }} />
              <Typography>{Math.round(uploads?.progress * 100)}%</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr",
                gap: 4,
              }}
            >
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed gray",
                  padding: 2,
                  textAlign: "center",
                  width: "100%",
                  columnCount: 1,
                }}
              >
                <input {...getInputProps()} />
                <Typography>
                  Doküman yüklemek için tıklayın veya sürükleyin
                </Typography>
              </Box>
              <TextField
                label="Doküman Başlığı"
                name="title"
                value={documentFileName}
                onChange={(e) => setDocumentFileName(e.target.value)}
                required
                fullWidth
                className="h-auto"
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={saveDocument}
              >
                Doküman Ekle
              </Button>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
}
