import React, { useContext, useEffect, useState } from "react";
import Avatar from "../../../../components/Avatar";
import Cart from "../../../../components/Cart";
import ApplicationLogo from "@/components/core/ApplicationLogo";
import { UserContext } from "@/context/UserContext";

export default function StudentNavigation() {
  const [search, setSearch] = useState("");

  const onChangeSarch = (e: any) => {
    setSearch(e.target.value);
    console.log(search);
  };

  return (
    <div>
      <nav className="relative px-6 flex gap-4 justify-between items-center bg-white shadow-lg min-h-24">
        <ApplicationLogo className="w-20 h-20 fill-current text-gray-500" />
        Kategoriler
        <div className="w-full mx-auto  border border-black rounded-full">
          <div className="relative flex items-center w-full h-12 rounded-full focus-within:shadow-lg bg-white overflow-hidden">
            <div className="grid place-items-center h-full w-12 text-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>

            <input
              className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
              type="text"
              id="search"
              placeholder="Search something.."
              onChange={onChangeSarch}
            />
          </div>
        </div>
        <div className="flex gap-8 items-center">
          <Cart />
          <Avatar />
        </div>
      </nav>
    </div>
  );
}
