import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { create } from "@/actions/sliders.action";
import { useNavigate } from "react-router-dom";
import { url } from "@/utils";
import { getAll } from "@/actions/image.actions";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageModal from "@/components/ImageModal";

export default function CreateSlider() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [items, setItems] = useState<
    Array<{ title: string; description: string; image: string }>
  >([]);
  const { images, status: imageStatus } = useSelector(
    (state: any) => state.images
  );
  const { status } = useSelector((state: any) => state.sliders);

  const [item, setItem] = useState({ title: "", description: "", image: "" });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleSubmit = () => {
    const slider = {
      title,
      description,
      items,
    };

    dispatch(create(slider) as any);
    if (status === "success") {
      navigate(url("Admin.Sliders"));
    }
  };

  const addItem = () => {
    setItems((prev) => [...prev, item]);
    setItem({ title: "", description: "", image: "" });
  };

  const removeItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (imageStatus === "idle") {
      dispatch(getAll() as any);
    }
  }, [imageStatus, dispatch]);

  return (
    <div className="bg-white rounded shadow w-full p-4">
      <Box sx={{ width: "100%" }}>
        <Typography variant="h6">Create Slider</Typography>
        <div className="grid grid-cols-4 gap-4 items-center">
          <Typography variant="h6">Görseller</Typography>
          <div className="col-span-4 grid grid-cols-4 gap-4">
            <TextField
              label="Başlık"
              fullWidth
              margin="normal"
              className="col-span-4"
              value={item.title}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, title: e.target.value }))
              }
            />
            <div className="flex justify-between gap-4 col-span-4 items-center">
              <div className="w-full">
                <InputLabel id="item-image">Görsel</InputLabel>
                <Select
                  labelId="item-image"
                  id="item-image"
                  fullWidth
                  value={item.image}
                  label="Görsel"
                  onChange={(e) =>
                    setItem((prev) => ({ ...prev, image: e.target.value }))
                  }
                  className="col-span-4"
                >
                  {images?.map((image) => (
                    <MenuItem key={image?._id} value={image?._id}>
                      {image?.altText}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="w-max">
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded-md"
                  onClick={() => setModalOpen(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <ImageModal
                  isOpen={isModalOpen}
                  onClose={() => setModalOpen(false)}
                />
              </div>
            </div>
            <TextField
              label="Acıklama"
              fullWidth
              margin="normal"
              value={item.description}
              className="col-span-4"
              multiline
              onChange={(e) =>
                setItem((prev) => ({ ...prev, description: e.target.value }))
              }
            />
            <div className="col-span-4">
              <Button variant="contained" color="primary" onClick={addItem}>
                Ekle
              </Button>
            </div>
          </div>

          <div className="col-span-4 grid grid-cols-8 gap-4">
            {items?.map((item, index) => (
              <div key={index} className="w-full relative">
                <div className="w-48 bg-gray-200">
                  <div>
                    <span>{item.title}</span>
                    <br />
                    <span>{item.description}</span>
                  </div>
                  <img
                    src={images.find((image) => image._id === item.image)?.url}
                    alt={
                      images.find((image) => image._id === item.image)?.altText
                    }
                    className="object-cover w-full"
                  />
                </div>
                <button
                  className="bg-red-600 rounded p-2 text-white absolute top-1 right-1"
                  onClick={() => removeItem(index)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ))}
          </div>

          <TextField
            label="Başlık"
            fullWidth
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <TextField
            label="Acıklama"
            fullWidth
            margin="normal"
            value={description}
            className="col-span-3"
            multiline
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className="col-span-4">
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
}
