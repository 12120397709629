import { getAll, remove } from "@/actions/users.actions";
import Table from "@/components/core/Table";
import { url } from "@/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function UserList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { users } = useSelector((state: any) => state.users);
  console.log(users);

  useEffect(() => {
    dispatch(getAll() as any);
  }, [dispatch]);

  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  return (
    <div className="p-4 bg-white rounded shadow">
      <Table
        data={users}
        pageSizeOptions={[5, 10, 25]}
        whitelist={["name", "email", "phone"]}
        onDelete={(id: string) => handleDelete(id)}
        onEdit={(id: string) => {
          navigation(url("Admin.Users.Edit", { id: id }));
        }}
      />
    </div>
  );
}
