import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
} from "../actions/type";

interface AuthState {
  user: any;
  token: string;
  loading: boolean;
  error: string | any;
  isLoggedIn: boolean;
  rememberMe: boolean;
}

const rememberMeValue =
  localStorage.getItem("rememberMe") === "true" ? true : false;

const initialState: AuthState = {
  user: null,
  token: "",
  loading: false,
  error: null,
  isLoggedIn: false,
  rememberMe: rememberMeValue,
};

interface AuthAction {
  type: string;
  payload?: any;
}

export default function authReducer(
  state = initialState,
  action: AuthAction
): AuthState {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user ?? "",
        token: payload.access_token,
        rememberMe: false,
      };

    case REGISTER_FAIL:
      return { ...state, isLoggedIn: false };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user ?? {
          id: 1,
          username: "Jhon Doe",
          email: "johndoe@example.com",
        },
        token: payload.access_token,
        rememberMe: payload.remember ?? false,
      };

    case "UPDATE_STATE":
      return {
        ...state,
        user: payload ?? "",
      };

    case LOGOUT:
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAIL:
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        rememberMe: false,
        token: "",
      };

    default:
      return state;
  }
}
