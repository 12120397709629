import { ChangePasswordDto } from "@/data/dtos/change-password.dto";
import { UserData } from "@/data/dtos/user.dto";
import axios from "axios";

const getPublicContent = () => {
  return axios.get(process.env.REACT_APP_API_BASE_URL + "/user");
};

const create = async (payload: UserData) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/user",
    payload
  );
  return response;
};

const getAll = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/user"
  );
  return response;
};

const get = async (id: string) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/user/" + id
  );
  return response;
};

const update = async (id: string, payload: UserData) => {
  const response = await axios.patch(
    process.env.REACT_APP_API_BASE_URL + "/user/" + id,
    payload
  );
  console.log(response);
  return response;
};

const changePassword = async (id: string, payload: ChangePasswordDto) => {
  const response = await axios.patch(
    process.env.REACT_APP_API_BASE_URL + "/user/change-password/" + id,
    payload
  );
  console.log(response);
  return response;
};

const remove = async (id: string) => {
  const response = axios.delete(
    process.env.REACT_APP_API_BASE_URL + "/user/" + id
  );
  return response;
};

const userService = {
  getPublicContent,
  create,
  getAll,
  get,
  update,
  remove,
  changePassword,
};

export default userService;
