import React, { useRef } from "react";
import CourseCard from "../CourseCard";
import Card from "../core/Card";

const CardSlider = ({ selectedCourse }: any) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300, // Sol tarafa kaydırma
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300, // Sağ tarafa kaydırma
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative">
      {/* Sol buton */}
      <button
        onClick={scrollLeft}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700"
      >
        &lt; {/* Sol ok */}
      </button>

      {/* Kaydırma container */}
      <div
        ref={scrollContainerRef}
        className="overflow-hidden whitespace-nowrap flex gap-4"
      >
        <div className="grid grid-cols-4 gap-4 min-w-max px-8">
          {selectedCourse?.courses?.map((course: any) => (
            <Card key={course._id} course={course} />
          ))}
        </div>
      </div>

      {/* Sağ buton */}
      <button
        onClick={scrollRight}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700"
      >
        &gt; {/* Sağ ok */}
      </button>
    </div>
  );
};

export default CardSlider;
