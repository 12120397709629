import coursesService from "../services/courses.service";
import { uploadFileService } from "../services/file.service";
import { setError, setMessage } from "./message";
import {
  FILE_UPLOADING_STARTED,
  UPLOAD_FILE_FAILED,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from "./type";

export const uploadFile = (payload: any) => async (dispatch: any) => {
  dispatch({
    type: UPLOAD_FILE_REQUEST,
  });
  await coursesService
    .uploadFile(payload, (progressEvent: ProgressEvent) => {
      dispatch({
        type: FILE_UPLOADING_STARTED,
        payload: progressEvent,
      });
    })
    .then((response: any) => {
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        payload: response,
      });
      dispatch(setMessage(response.data.message ?? ""));
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      dispatch({ type: UPLOAD_FILE_FAILED });
      dispatch(setError(err.response?.data?.message ?? ""));
      return Promise.reject(new Error(err));
    });
};

export const update = (payload: any) => async (dispatch: any) => {
  await uploadFileService
    .update(payload, (progressEvent: ProgressEvent) => {
      dispatch({
        type: FILE_UPLOADING_STARTED,
        payload: progressEvent,
      });
    })
    .then((response: any) => {
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        payload: response,
      });
      dispatch(setMessage(response.data.message ?? ""));
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      dispatch({ type: UPLOAD_FILE_FAILED });
      dispatch(setError(err.response?.data?.message ?? ""));
      return Promise.reject(new Error(err));
    });
};

export const remove = (payload: any) => async (dispatch: any) => {
  await uploadFileService
    .remove(payload)
    .then((response: any) => {
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        payload: response,
      });
      dispatch(setMessage(response.data.message ?? ""));
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      dispatch({ type: UPLOAD_FILE_FAILED });
      dispatch(setError(err.response?.data?.message ?? ""));
      return Promise.reject(new Error(err));
    });
};
