import { Link } from "react-router-dom";
import React, { useContext } from "react";
import ApplicationLogo from "../../../../components/core/ApplicationLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { url } from "@/utils";
import { useSelector } from "react-redux";
import Avatar from "@/components/Avatar";
import { UserContext } from "@/context/UserContext";

export default function Navigation() {
  const [open, setOpen] = React.useState(false);
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }
  const { user } = userContext;

  const toggleMobileMenu = () => {
    setOpen(!open);
  };

  return (
    <div>
      {/* Desktop Menu */}
      <div className="hidden md:block ">
        <nav className=" bg-white w-full flex relative justify-between items-center mx-auto px-8 h-20">
          {/* <!-- logo --> */}
          <ApplicationLogo className="w-20 h-20 fill-current text-gray-500" />
          {/* <!-- end logo --> */}

          {/* <!-- search bar --> */}
          <div className="hidden sm:block flex-shrink flex-grow-0 justify-start px-2">
            <div className="inline-block">
              <div className="inline-flex items-center max-w-full">
                <button
                  className="flex items-center flex-grow-0 flex-shrink pl-2 relative w-60 border rounded-full px-1  py-1"
                  type="button"
                >
                  <div className="block flex-grow flex-shrink overflow-hidden">
                    Start your search
                  </div>
                  <div className="flex items-center justify-center relative  h-8 w-8 rounded-full">
                    <svg
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      style={{
                        display: "block",
                        fill: "none",
                        height: "12px",
                        width: "12px",
                        stroke: "currentColor",
                        strokeWidth: "5.33333",
                        overflow: "visible",
                      }}
                    >
                      <g fill="none">
                        <path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"></path>
                      </g>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
          {/* <!-- end search bar --> */}

          {/* <!-- login --> */}
          <div className="flex-initial">
            <div className="flex justify-end items-center relative">
              <Avatar />
            </div>
          </div>
          {/* <!-- end login --> */}
        </nav>
      </div>
      {/* Mobile Menu */}
      <div className="w-full block justify-between md:hidden text-end p-4">
        <button className="text-gray-400" onClick={toggleMobileMenu}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        {open && (
          <div className="w-full flex flex-col">
            <ApplicationLogo className="w-20 h-20 fill-current text-gray-500" />
            <div className="inline-block w-full">
              <div className="inline-flex items-center w-full">
                <button
                  className="flex items-center flex-grow-0 flex-shrink pl-2 relative border rounded-full px-1 py-1 w-full"
                  type="button"
                >
                  <div className="block flex-grow flex-shrink overflow-hidden">
                    Start your search
                  </div>
                  <div className="flex items-center justify-center relative  h-8 w-8 rounded-full">
                    <svg
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      style={{
                        display: "block",
                        fill: "none",
                        height: "12px",
                        width: "12px",
                        stroke: "currentColor",
                        strokeWidth: "5.33333",
                        overflow: "visible",
                      }}
                    >
                      <g fill="none">
                        <path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"></path>
                      </g>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
            <ul>
              {(user?.role === "admin" || user?.role === "teacher") && (
                <li>
                  <Link
                    to={url("Admin.Dashboard")}
                    className="block py-2 hover:text-blue-500 hover:bg-gray-50 p-2"
                  >
                    Panel
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={url("Profile.My-Courses")}
                  className="block py-2 hover:text-blue-500 hover:bg-gray-50 p-2"
                >
                  Öğrenim İçeriğim
                </Link>
              </li>
              <li>
                <Link
                  to={url("Cart")}
                  className="block py-2 hover:text-blue-500 hover:bg-gray-50 p-2"
                >
                  Sepetim
                </Link>
              </li>
              <li>
                <a
                  href="/home/my-courses/wishlist/"
                  className="block py-2 hover:text-blue-500 hover:bg-gray-50 p-2"
                >
                  İstek Listesi
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
