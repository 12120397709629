import { get, getAll, update } from "@/actions/category.actions";
import { getAll as getAllImages } from "@/actions/image.actions";
import InputLabel from "@/components/InputLabel";
import { CategoryData } from "@/data/dtos/category.dto";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "@emotion/styled";
import { upload } from "@/actions/image.actions";
import ImageModal from "@/components/ImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function UpdateCategory() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { message } = useSelector((state: any) => state.message);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { images, status: imageStatus } = useSelector(
    (state: any) => state.images
  );
  const { currentCategory, categories, status } = useSelector(
    (state: any) => state.categories
  );

  const [formData, setFormData] = useState<CategoryData | null>({
    name: "",
    parent: "",
    description: "",
    status: true,
    image: "",
    seo: { title: "", description: "", keywords: "" },
  });

  // Burada 3 ana başlık olacaktı. Öyle karar vermiştik.
  //  1) Mesleki ve Teknik Eğitimler (Kurumsal-Bireysel),
  //  2) Motivasyon ve Farkındalık Eğitimleri (Kurumsal-Bireysel)
  //  3) Kurgusal Etkinlik

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData!, status: event.target.checked });
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData!, [name]: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    console.log(formData);
    dispatch(update(id!, formData!) as any);
  };

  const handleSeoChange = (key: string, newValue: string, index: number) => {
    const updatedSeo = Array.from(Object.entries(formData!.seo ?? {}));
    updatedSeo[index] = [key, newValue]; // Belirtilen indeksi güncelle
    setFormData({ ...formData!, seo: Object.fromEntries(updatedSeo) });
  };

  const addSeoField = (key: string, value: string) => {
    const updatedSeo = new Map(Object.entries(formData!.seo ?? {}));
    updatedSeo.set(key, value);
    setFormData({ ...formData!, seo: Object.fromEntries(updatedSeo) });
  };

  useEffect(() => {
    if (id) {
      dispatch(get(id) as any);
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (status === "idle" || categories.length === 0) {
      dispatch(getAll() as any);
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (currentCategory) {
      setFormData({
        name: currentCategory.data.name,
        parent: currentCategory.data.parent || "",
        description: currentCategory.data.description,
        status: currentCategory.data.status,
        image: currentCategory.data.image?._id || "",
        seo: currentCategory.data.seo,
      });
    }
  }, [currentCategory]);

  useEffect(() => {
    if (imageStatus === "idle") {
      dispatch(getAllImages() as any);
    }
  }, [imageStatus, dispatch]);

  return (
    <div>
      <div className="bg-white rounded shadow w-full p-4">
        {message?.message && (
          <div className="flex flex-col gap-4 rounded bg-red-500 p-4 text-white">
            {message.message}
          </div>
        )}
        <Box component="form" onSubmit={handleSubmit}>
          <Typography variant="h5" gutterBottom>
            Category Form
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center">
            <TextField
              fullWidth
              label="Name"
              name="name"
              className="col-span-4"
              value={formData!.name}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              className="col-span-4"
              value={formData!.description}
              onChange={handleInputChange}
              margin="normal"
              multiline
              rows={4}
            />
            <FormControl fullWidth margin="normal" className="col-span-2">
              <InputLabel id="parent-label">Parent</InputLabel>
              <Select
                labelId="parent-label"
                name="parent"
                value={formData!.parent}
                onChange={(e) =>
                  setFormData({ ...formData!, parent: e.target.value })
                }
              >
                <MenuItem value="">None</MenuItem>
                {categories?.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={formData!.status}
                  onChange={handleSwitchChange}
                  name="status"
                />
              }
              label="Status"
              className="col-span-2"
            />
            <div className="flex justify-between gap-4 col-span-4 items-center">
              <div className="w-full">
                <InputLabel id="item-image">Görsel</InputLabel>
                <Select
                  labelId="item-image"
                  id="item-image"
                  fullWidth
                  value={formData!.image}
                  label="Görsel"
                  onChange={(e) =>
                    setFormData({ ...formData!, image: e.target.value })
                  }
                  className="col-span-4"
                >
                  {images?.map((image) => (
                    <MenuItem key={image?._id} value={image?._id}>
                      {image?.altText}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="w-max">
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded-md"
                  onClick={() => setModalOpen(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <ImageModal
                  isOpen={isModalOpen}
                  onClose={() => setModalOpen(false)}
                />
              </div>
            </div>
            <Box sx={{ mt: 2, gridColumn: "span 4", width: "100%" }}>
              <Typography variant="subtitle1">SEO Tags</Typography>
              {Array.from(Object.entries(formData!.seo ?? {})).map(
                ([key, value], index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mt: 1,
                      width: "100%",
                    }}
                  >
                    <TextField
                      label="Key"
                      fullWidth
                      value={key}
                      onChange={(e) =>
                        handleSeoChange(e.target.value, value, index)
                      }
                      sx={{ flex: 1 }}
                    />
                    <TextField
                      label="Value"
                      fullWidth
                      value={value}
                      onChange={(e) =>
                        handleSeoChange(key, e.target.value, index)
                      }
                      sx={{ flex: 2 }}
                    />
                  </Box>
                )
              )}
              <Button
                sx={{ mt: 2 }}
                variant="outlined"
                onClick={() => addSeoField("", "")}
              >
                Add SEO Tag
              </Button>
            </Box>
          </div>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </div>
    </div>
  );
}
