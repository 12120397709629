import React from "react";
import { Box, Typography } from "@mui/material";
import UploadSection from "@/components/auth/admin/UploadSection";

export default function CourseImages({ courseData, setCourseData }: any) {
  const handleChange = (field: string, value: any) => {
    if (courseData && field) {
      console.log(value);
      setCourseData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };
  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 2 }}>
        Kurs İmajları
      </Typography>

      <div>
        <UploadSection images={courseData?.images} onChange={handleChange} />
      </div>
    </Box>
  );
}
