import { Link, Outlet } from "react-router-dom";
import ApplicationLogo from "../../../../components/core/ApplicationLogo";
import { url } from "../../../../utils";

export default function AuthLayout() {
  return (
    <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100 dark:bg-gray-900">
      <div>
        <Link to={url("Home")}>
          <ApplicationLogo className="w-20 h-20 fill-current text-gray-500" />
        </Link>
      </div>

      <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white dark:bg-gray-800 shadow-md overflow-hidden sm:rounded-lg">
        <Outlet></Outlet>
      </div>
    </div>
  );
}
