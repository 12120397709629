import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function ReportCard({ metric }) {
  return (
    <div className="flex p-6 bg-white rounded shadow-md border-blue-600  border-t-4">
      <div className="w-min">
        <FontAwesomeIcon
          className="text-blue-500 hover:text-blue-700 w-6 h-6"
          icon={metric.icon}
        />
      </div>
      <div
        key={metric.id}
        className="flex flex-col items-center justify-center text-center w-full"
      >
        <h2 className="text-lg font-medium text-gray-500">{metric.title}</h2>
        <p className="text-2xl font-bold">{metric.value}</p>
        {metric.trend === "up" && (
          <span className="text-green-500 mt-2">⬆ Artış</span>
        )}
        {metric.trend === "down" && (
          <span className="text-red-500 mt-2">⬇ Azalış</span>
        )}
        {metric.trend === "neutral" && (
          <span className="text-gray-500 mt-2">- Sabit</span>
        )}
      </div>
    </div>
  );
}
