import axios from "axios";

export const uploadFile = async (
  payload: FormData,
  onUploadProgress: (progressEvent: any) => void
) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/resources/upload-file",
    payload,
    {
      onUploadProgress,
    }
  );
  return response;
};

export const remove = async (payload: any) => {
  const response = await axios.delete(
    process.env.REACT_APP_API_BASE_URL + "/resources/delete-file",
    {
      data: payload,
    }
  );
  return response;
};

export const update = async (
  payload: FormData,
  onUploadProgress: (progressEvent: any) => void
) => {
  const response = await axios.patch(
    process.env.REACT_APP_API_BASE_URL + "/resources/update-file",
    payload,
    {
      onUploadProgress,
    }
  );

  return response;
};

export const uploadFileService = {
  uploadFile,
  update,
  remove,
};
