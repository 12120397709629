import React, { useState, useRef, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../actions/authAction";
import { genProfileImage, url } from "../utils";
import { UserContext } from "@/context/UserContext";

export default function Avatar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }
  const { user, error, loading, checkSession } = userContext;

  useEffect(() => {
    console.log("checkSession");
    checkSession?.();
  }, [checkSession]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = (e: any) => {
    e.preventDefault();
    dispatch(logout() as any);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, dispatch, user]);

  return (
    <div className="relative" ref={menuRef}>
      {/* Kullanıcı Avatarı */}
      <button
        onClick={toggleMenu}
        className="w-12 h-12 rounded-full  flex items-center justify-center cursor-pointer"
      >
        <img
          className="w-10 h-10 rounded-full"
          src={genProfileImage(user?.name!, user?.surname!)}
          alt={user?.name + " " + user?.surname + "Avatar"}
        />
      </button>

      {/* Menü */}
      {isOpen && (
        <div className="absolute right-0 top-full mt-2 w-64 bg-white shadow-lg rounded-lg p-4 z-10">
          {/* Menü içeriği */}
          <div className="flex items-center mb-4">
            <div className="w-16 h-10 rounded-full  flex items-center justify-center mr-4">
              <img
                className="w-10 h-10 rounded-full"
                src={
                  user?.profileImage
                    ? user?.profileImage
                    : genProfileImage(user?.name!, user?.surname!)
                }
                alt={user?.name + " " + user?.surname + "Avatar"}
              />
            </div>
            <div>
              <div className="text-lg font-semibold">
                {user?.name + " " + user?.surname}
              </div>
              <div className="text-sm text-gray-500">{user?.email}</div>
            </div>
          </div>

          {/* Menü Linkleri */}
          <ul>
            {(user?.role === "admin" || user?.role === "teacher") && (
              <li>
                <Link
                  to={url("Admin.Dashboard")}
                  className="block py-2 hover:text-blue-500"
                >
                  Panel
                </Link>
              </li>
            )}
            <li>
              <Link
                to={url("Profile")}
                className="block py-2 hover:text-blue-500"
              >
                Profil
              </Link>
            </li>
            <li>
              <Link
                to={url("Profile.Security")}
                className="block py-2 hover:text-blue-500"
              >
                Güvenlik
              </Link>
            </li>
            <li>
              <Link
                to={url("Profile.Addresses")}
                className="block py-2 hover:text-blue-500"
              >
                Adreslerim
              </Link>
            </li>
            {user?.role === "student" && (
              <>
                <li>
                  <Link
                    to={url("Profile.My-Courses")}
                    className="block py-2 hover:text-blue-500"
                  >
                    Eğitimlerim
                  </Link>
                </li>

                <li>
                  <Link
                    to={url("Cart")}
                    className="block py-2 hover:text-blue-500"
                  >
                    Sepetim
                  </Link>
                </li>
                <li>
                  <a
                    href="/home/my-courses/wishlist/"
                    className="block py-2 hover:text-blue-500"
                  >
                    İstek Listesi
                  </a>
                </li>
              </>
            )}
          </ul>

          {/* Çıkış ve Yardım Bağlantıları */}
          <ul className="border-t mt-4 pt-2">
            {(user?.role === "admin" || user?.role === "teacher") && (
              <li>
                <a
                  href="/support/"
                  target="_blank"
                  className="block py-2 hover:text-blue-500"
                >
                  Yardım ve Destek
                </a>
              </li>
            )}

            <li>
              <a
                href="/user/logout/"
                className="block py-2 hover:text-blue-500"
                onClick={handleLogout}
              >
                Oturumu kapat
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
