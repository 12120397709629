import { Link, NavLink } from "react-router-dom";
import ApplicationLogo from "@/components/core/ApplicationLogo";
import NavList from "@/components/core/NavList";
import { url } from "@/utils";
import PrimaryButton from "@/components/PrimaryButton";
import SecondaryButton from "@/components/SecondaryButton";
import { useSelector } from "react-redux";
import Cart from "@/components/Cart";
import Avatar from "@/components/Avatar";

export default function Nav() {
  const { isLoggedIn, user } = useSelector((state: any) => state.auth);

  const dashboardButton =
    user?.role === "admin" || user?.role === "teacher" ? (
      <div className="flex gap-8 items-center">
        <div className="flex flex-row items-center">
          <Link to={url("Profile.My-Courses")}>Öğrenim İçeriğim</Link>
          <Cart />
        </div>
        <NavLink to={url("Admin.Dashboard")}>
          <PrimaryButton onClick={() => {}} icon={null}>
            Dashboard
          </PrimaryButton>
        </NavLink>
      </div>
    ) : (
      <div className="flex gap-8 items-center">
        <div className="flex flex-row items-center">
          <Cart />
          <Link to={url("Profile.My-Courses")}>Öğrenim İçeriğim</Link>
        </div>
        <Avatar />
      </div>
    );

  return (
    <header className="fixed top-0 left-0 w-full bg-white shadow-lg z-50 h-24">
      <nav className="relative px-4 flex justify-between items-center bg-white shadow-lg min-h-24">
        <ApplicationLogo className="w-20 h-20 fill-current text-gray-500" />

        <NavList />

        {isLoggedIn ? (
          <div>{dashboardButton}</div>
        ) : (
          <div className="flex gap-4">
            <Cart />
            <NavLink to={url("Auth.Login")}>
              <PrimaryButton onClick={() => {}} icon={null}>
                Login
              </PrimaryButton>
            </NavLink>
            <NavLink to={url("Auth.Register")}>
              <SecondaryButton onClick={() => {}} icon={null}>
                Register
              </SecondaryButton>
            </NavLink>
          </div>
        )}
      </nav>
    </header>
  );
}
