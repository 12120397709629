import { setMessage } from "./message";

export const addToCart = (payload: any) => async (dispatch: any) => {
  dispatch({ type: "ADD_TO_CART", payload: payload });
};

export const removeFromCart = (payload: any) => async (dispatch: any) => {
  dispatch({ type: "REMOVE_FROM_CART", payload: payload });
  dispatch(setMessage("Item removed from cart", 5000));
};

export const clearCart = () => async (dispatch: any) => {
  dispatch({ type: "CLEAR_CART" });
  dispatch(setMessage("Cart cleared", 5000));
};
