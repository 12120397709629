import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { QuizzDto } from "@/data/dtos/quizz.dto";

interface QuizComponentProps {
  quiz: QuizzDto;
}

const QuizComponent: React.FC<QuizComponentProps> = ({ quiz }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [userAnswers, setUserAnswers] = useState<number[]>([]);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [showResults, setShowResults] = useState<boolean>(false);

  const currentQuestion = quiz.questions[currentQuestionIndex];

  // Yeni eklenen useEffect hook'u
  useEffect(() => {
    // Quiz değiştiğinde state'i sıfırla
    setCurrentQuestionIndex(0);
    setUserAnswers([]);
    setSelectedOption(null);
    setShowResults(false);
  }, [quiz]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(parseInt(event.target.value));
  };

  const handleConfirmAnswer = () => {
    if (selectedOption === null) {
      alert("Lütfen bir seçenek seçin.");
      return;
    }

    // Kullanıcının cevabını kaydediyoruz
    setUserAnswers([...userAnswers, selectedOption]);

    // Seçilen seçeneği sıfırlıyoruz
    setSelectedOption(null);

    // Sonraki soruya geçiyoruz veya sonuçları gösteriyoruz
    if (currentQuestionIndex + 1 < quiz.questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowResults(true);
    }
  };

  const calculateResults = () => {
    let correctCount = 0;

    quiz.questions.forEach((question, index) => {
      if (userAnswers[index] === question.correctAnswer) {
        correctCount++;
      }
    });

    return { correctCount, total: quiz.questions.length };
  };

  if (showResults) {
    const { correctCount, total } = calculateResults();
    return (
      <div>
        <Typography variant="h5">Sonuçlar</Typography>
        <Typography variant="body1">
          Doğru Sayısı: {correctCount} / {total}
        </Typography>
        {quiz.questions.map((question, index) => (
          <div key={index}>
            <Typography variant="subtitle1">
              {index + 1}. {question.question}
            </Typography>
            <Typography
              variant="body2"
              color={
                userAnswers[index] === question.correctAnswer ? "green" : "red"
              }
            >
              Sizin cevabınız: {question.options[userAnswers[index]]}
            </Typography>
            <Typography variant="body2" color="green">
              Doğru cevap: {question.options[question.correctAnswer]}
            </Typography>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <Typography variant="h6">
        {currentQuestionIndex + 1}. {currentQuestion.question}
      </Typography>
      <RadioGroup
        value={selectedOption !== null ? selectedOption.toString() : ""}
        onChange={handleOptionChange}
      >
        {currentQuestion.options.map((option, idx) => (
          <FormControlLabel
            key={idx}
            value={idx.toString()}
            control={<Radio />}
            label={`${String.fromCharCode(65 + idx)}. ${option}`}
          />
        ))}
      </RadioGroup>
      <Button
        variant="contained"
        sx={{ position: "absolute", bottom: "20px", right: "20px" }}
        onClick={handleConfirmAnswer}
      >
        Onayla
      </Button>
    </div>
  );
};

export default QuizComponent;
