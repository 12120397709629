import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import authService from "../services/auth.service";
import { logger } from "../lib/default-logger";
import { url } from "@/utils";
import { UserData } from "@/data/dtos/user.dto";

export interface UserContextValue {
  user: UserData | null;
  error: string | null;
  loading: boolean;
  status: string;
  checkSession?: () => Promise<void>;
}

export const UserContext = React.createContext<UserContextValue | undefined>(
  undefined
);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const { token, user } = useSelector((state: any) => state.auth);

  const navigate = useNavigate();

  const [state, setState] = useState<UserContextValue>({
    user: user,
    error: null,
    loading: false,
    status: "idle",
  });

  const tokenRef = useRef(token);
  useEffect(() => {
    tokenRef.current = token;
  }, [token]);

  const checkSession = useCallback(async (): Promise<void> => {
    console.log("checkSession");
    const currentToken = tokenRef.current;
    // if (!currentToken) {
    //   setState((prev) => ({
    //     ...prev,
    //     user: null,
    //     error: "No token provided",
    //     loading: false,
    //     status: "failed",
    //   }));
    //   return navigate(url("Auth.Login"));
    // }

    try {
      if (currentToken) {
        const response = await authService.updateState(currentToken);
        const { data, error } = response;
        if (error) {
          logger.error(error);
          setState((prev) => ({
            ...prev,
            user: null,
            error: "Something went wrong",
            loading: false,
            status: "failed",
          }));
          return navigate(url("Auth.Login"));
        }

        setState((prev) => ({
          ...prev,
          user: data,
          error: null,
          loading: false,
          status: "success",
        }));
      }
    } catch (error) {
      logger.error(error);
      setState((prev) => ({
        ...prev,
        user: null,
        error: "Something went wrong",
        loading: false,
        status: "failed",
      }));
      navigate(url("Auth.Login"));
    }
  }, [navigate]);

  useEffect(() => {
    if (state.loading) {
      checkSession();
    }
  }, [checkSession, state.loading]);

  const value = useMemo(
    () => ({ ...state, checkSession }),
    [state, checkSession]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const UserConsumer = UserContext.Consumer;
