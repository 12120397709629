import axios, { AxiosRequestConfig } from 'axios';

class BaseService {
  private baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  async get(endpoint: string, config?: AxiosRequestConfig) {
    return await axios.get(this.baseURL + endpoint, config);
  }

  async post(endpoint: string, data: any, config?: AxiosRequestConfig) {
    return await axios.post(this.baseURL + endpoint, data, config);
  }

  async patch(endpoint: string, data: any, config?: AxiosRequestConfig) {
    return await axios.patch(this.baseURL + endpoint, data, config);
  }

  async delete(endpoint: string, config?: AxiosRequestConfig) {
    return await axios.delete(this.baseURL + endpoint, config);
  }
}

export const apiClient = new BaseService(
  process.env.REACT_APP_API_BASE_URL ?? ''
);
