import axios from "axios";
import { useNavigate } from "react-router-dom";

interface PayloadInterface {
  email: string;
  password: string;
  name: string;
  role: string;
}

interface LoginPayloadInterface {
  email: string;
  password: string;
}

const register = async (payload: PayloadInterface) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/user/register",
    payload
  );

  return response;
};

const login = async (payload: LoginPayloadInterface) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/auth/login",
    payload
  );
  return response;
};

const forgotPassword = async (email: string) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/auth/forgot-password",
    email
  );
  console.log(response);
  return response;
};

const resetPassword = async (payload: any) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/auth/reset-password",
    payload
  );
  console.log(response);
  return response;
};

const logout = () => {
  localStorage.setItem("rememberMe", "false");
  localStorage.removeItem("user");
  window.location.href = "/";
};

const updateState = async (token: string): Promise<any> => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/auth/state",
    {}, // Boş bir body ile POST isteği gönderiyoruz
    {
      headers: {
        Authorization: `Bearer ${token}`, // Token'ı header içinde gönderiyoruz
      },
    }
  );
  console.log(response);
  return response;
};

const authService = {
  register,
  login,
  logout,
  forgotPassword,
  resetPassword,
  updateState,
};

export default authService;
