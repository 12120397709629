import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "@/context/UserContext";
import { formatMoney, url } from "@/utils";
import { AddressDto } from "@/data/dtos/address.dto";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@mui/material";
import { create } from "@/actions/orders.actions";
import IyzicoPaymentModal from "@/components/cart/IyzicoPaymentModal";

export default function PaymentPage() {
  const dispatch = useDispatch();
  const { items, total, paidTotal, tax, discount } = useSelector(
    (state: any) => state.cart
  );
  const userContext = useContext(UserContext);
  const [shippingAddress, setShippingAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [checkoutFormContent, setCheckoutFormContent] = useState<string | null>(
    null
  );
  const { order } = useSelector((state: any) => state.order);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  //TODO: send just courses ids to backend calculate payment there again

  const { user } = userContext;

  const handleCheckout = async () => {
    const buyerId = user?._id;
    if (!buyerId) {
      throw new Error("User ID is required");
    }
    const checkoutObject = {
      price: total,
      paidPrice: paidTotal,
      currency: "TRY",
      paymentChannel: "WEB",
      installment: 1,
      buyer: buyerId,
      courses: items?.map((item: any) => item._id),
      shippingAddress: shippingAddress,
      billingAddress: billingAddress,
      status: "Pending",
    };

    // Backend'den ödeme sayfası içeriğini almak
    await dispatch(create(checkoutObject) as any);
  };

  useEffect(() => {
    if (order?.data?.checkoutFormContent) {
      setCheckoutFormContent(order.data.checkoutFormContent);
    }
  }, [order]);

  return (
    <div>
      <section className=" py-8 antialiased md:py-16">
        <div className="mx-auto max-w-screen-xl  bg-white rounded p-4 shadow">
          <ol className="items-center flex w-full max-w-2xl text-center text-sm font-medium text-gray-500  sm:text-base">
            <li className="after:border-1 flex items-center text-primary-700 after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200  sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10">
              <Link to={url("Cart")}>
                <span className="flex items-center after:mx-2 after:text-gray-200 after:content-['/']  sm:after:hidden">
                  <svg
                    className="me-2 h-4 w-4 sm:h-5 sm:w-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  Cart
                </span>
              </Link>
            </li>

            <li className="after:border-1 flex items-center text-primary-700 after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200  sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10">
              <span className="flex items-center after:mx-2 after:text-gray-200 after:content-['/']  sm:after:hidden">
                <svg
                  className="me-2 h-4 w-4 sm:h-5 sm:w-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                Checkout
              </span>
            </li>

            <li className="flex shrink-0 items-center">
              <svg
                className="me-2 h-4 w-4 sm:h-5 sm:w-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              Order summary
            </li>
          </ol>

          <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12 xl:gap-16">
            <div className="min-w-0 flex-1 space-y-8">
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-gray-900 ">
                  Delivery Details
                </h2>

                <div className="grid grid-cols-2 gap-4 my-4">
                  {(user?.addresses as AddressDto[])?.length > 0 ? (
                    (user?.addresses as AddressDto[])?.map(
                      (address: AddressDto) => (
                        <button
                          onClick={() => setShippingAddress(address._id!)}
                          key={address._id}
                          className={`rounded shadow bg-white relative ${
                            shippingAddress === address._id
                              ? "border-2 border-indigo-600"
                              : ""
                          }`}
                        >
                          <div className="p-4 flex flex-col">
                            <div className="w-full text-lg">
                              {address.title}
                            </div>
                            <div className="text-gray-500">
                              {address.address}
                            </div>
                            <div className="text-gray-500">
                              {address.contactName}
                            </div>
                            <div className="text-gray-500">
                              {address.isBillingAddress
                                ? "Fatura Adresi"
                                : "Kargo Adresi"}
                            </div>
                          </div>
                        </button>
                      )
                    )
                  ) : (
                    <Link to={url("Profile.Addresses")}>
                      Adress Eklemelisiniz
                    </Link>
                  )}
                </div>
              </div>

              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-gray-900 ">
                  Invoice Details
                </h2>

                <div className="grid grid-cols-2 gap-4 my-4">
                  {(user?.billingAddresses as AddressDto[])?.length > 0 ? (
                    (user?.billingAddresses as AddressDto[])?.map(
                      (address: AddressDto) => (
                        <button
                          onClick={() => setBillingAddress(address._id!)}
                          key={address._id}
                          className={`rounded shadow bg-white relative ${
                            billingAddress === address._id
                              ? "border-2 border-indigo-600"
                              : ""
                          }`}
                        >
                          <div className="p-4 flex flex-col">
                            <div className="w-full text-lg">
                              {address.title}
                            </div>
                            <div className="text-gray-500">
                              {address.address}
                            </div>
                            <div className="text-gray-500">
                              {address.contactName}
                            </div>
                            <div className="text-gray-500">
                              {address.isBillingAddress
                                ? "Fatura Adresi"
                                : "Kargo Adresi"}
                            </div>
                          </div>
                        </button>
                      )
                    )
                  ) : (
                    <Link to={url("Profile.Addresses")}>
                      Adress Eklemelisiniz
                    </Link>
                  )}
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-900 ">
                  Payment
                </h3>

                <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                  <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="credit-card"
                          aria-describedby="credit-card-text"
                          type="radio"
                          name="payment-method"
                          value=""
                          className="h-4 w-4 border-gray-300 bg-white text-primary-600 focus:ring-2 focus:ring-primary-600   "
                          checked
                        />
                      </div>

                      <div className="ms-4 text-sm">
                        <label
                          htmlFor="credit-card"
                          className="font-medium leading-none text-gray-900 "
                        >
                          Credit Card
                        </label>
                        <p
                          id="credit-card-text"
                          className="mt-1 text-xs font-normal text-gray-500 "
                        >
                          Pay with your credit card
                        </p>
                      </div>
                    </div>

                    <div className="mt-4 flex items-center gap-2">
                      <button
                        type="button"
                        className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                      >
                        Delete
                      </button>

                      <div className="h-3 w-px shrink-0 bg-gray-200 "></div>

                      <button
                        type="button"
                        className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 w-full space-y-6 sm:mt-8 lg:mt-0 lg:max-w-xs xl:max-w-md">
              <div className="flow-root">
                <div className="-my-3 divide-y divide-gray-200 ">
                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-normal text-gray-500 ">
                      Subtotal
                    </dt>
                    <dd className="text-base font-medium text-gray-900 ">
                      {formatMoney(total)}
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-normal text-gray-500 ">
                      Savings
                    </dt>
                    <dd className="text-base font-medium text-green-500">
                      {formatMoney(discount)}
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-normal text-gray-500 ">
                      Tax
                    </dt>
                    <dd className="text-base font-medium text-gray-900 ">
                      {formatMoney(tax)}
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-bold text-gray-900 ">
                      Total
                    </dt>
                    <dd className="text-base font-bold text-gray-900 ">
                      {formatMoney(paidTotal)}
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="space-y-3">
                <button
                  type="submit"
                  className="flex w-full items-center disabled:bg-gray-500 disabled:cursor-none justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4  focus:ring-primary-300 "
                  disabled={user ? false : true}
                  onClick={handleCheckout}
                >
                  Proceed to Payment
                </button>
                {!user && (
                  <p className="text-sm font-normal text-gray-500 ">
                    One or more items in your cart require an account.
                    <Link
                      to={url("Auth.Login")}
                      title=""
                      className="font-medium text-primary-700 underline hover:no-underline"
                    >
                      Sign in or create an account now.
                    </Link>
                    .
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {checkoutFormContent && (
        <IyzicoPaymentModal checkoutFormContent={checkoutFormContent} />
      )}
    </div>
  );
}
