import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Box, Button, Select, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { get, update } from "../../../../../actions/courses.actions";
import { EditCourseSection } from "./edit/EditCourseSection";
import QuizComponent from "../../../courses/QuizComponent";
import VideoComponent from "../../../courses/VideoComponent";
import { deepClone } from "@mui/x-data-grid/internals";
import DocumentSection from "./edit/DocumentSection";
import CourseImages from "./edit/CourseImages";
import { CourseDto } from "@/data/dtos/course.dto";
import { ResourceDto } from "@/data/dtos/resource.dto";
import { CourseLevel } from "@/data/enums/course-level.enum";
import { ResourceType } from "@/data/enums/resource-type.enum";
import { languageArray } from "@/data/preDefined/language.array";
import { CourseStatus } from "@/data/enums/course-status.enum";
import LiveClassComponent from "@/app/views/courses/liveClassComponents/LiveClassComponent";
import { url } from "@/utils";
import { logger } from "@/lib/default-logger";

const CourseUpdateForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { id } = useParams();
  const { currentCourse, error } = useSelector((state: any) => state.courses);
  const { user } = useSelector((state: any) => state.auth);

  const [currentResource, setCurrentResource] = useState<ResourceDto | null>(
    null
  );
  const [formData, setFormData] = useState<CourseDto>(
    deepClone(currentCourse?.data) || {
      title: "",
      description: "",
      level: CourseLevel.BEGINNER,
      languages: [],
      price: 0,
      discountPercentage: 0,
      images: [],
    }
  );

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
    >
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name!]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(formData);
    // Güncellenmiş verileri göndermek için bir aksiyon dispatch edebilirsiniz
    dispatch(update(currentCourse.data._id!, formData) as any);
  };

  useEffect(() => {
    console.log("useEffect 1");
    dispatch(get(id) as any);
  }, [id, dispatch]);

  useEffect(() => {
    console.log("useEffect 2");
    if (currentCourse?.data) {
      // Derin kopya oluştur
      const clonedData = { ...currentCourse.data };

      // İstenmeyen alanları çıkar
      const { _id, __v, slug, createdAt, ...filteredData } = clonedData;

      console.log("Filtered Data:", filteredData);

      // Form datasını güncelle
      setFormData(filteredData);
    }
  }, [currentCourse]);

  useEffect(() => {
    logger.debug(error?.status);
    if (error?.status === 404) {
      return navigation(url("404"));
    }
  }, [error, navigation]);

  const handleSelectResource = (resource: ResourceDto) => {
    setCurrentResource(resource);
  };

  const getResourceComponent = () => {
    if (!currentResource) {
      return <Typography>Lütfen bir kaynak seçin.</Typography>;
    }
    switch (currentResource.type) {
      case ResourceType.VIDEO:
        return <VideoComponent currentResource={currentResource} />;
      case ResourceType.QUIZZ:
        return (
          <div>
            {/* Quiz içeriği */}
            <QuizComponent quiz={currentResource.quizz!} />
            {/* Quiz bileşenini buraya ekleyebilirsiniz */}
          </div>
        );
      case ResourceType.LIVE_CLASS:
        return (
          <div>
            {/* Canlı ders içeriği */}
            <Typography>Canlı Ders: {currentResource.title}</Typography>
            <LiveClassComponent />
            {/* Canlı ders bileşenini veya bağlantısını buraya ekleyebilirsiniz */}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="p-4 bg-white rounded shadow w-full">
      <div className="w-full flex flex-row gap-4 justify-between">
        <div className="w-full">
          <Box>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              {currentResource?.title || "Seçili Kaynak Yok"}
            </Typography>
            <div className="relative min-h-96 rounded border p-4">
              {getResourceComponent()}
            </div>
            <DocumentSection formData={formData} setFormData={setFormData} />
          </Box>
        </div>
        <EditCourseSection
          formData={formData}
          setFormData={setFormData}
          onSelectResource={handleSelectResource}
        />
      </div>

      {/* Kurs bilgileri */}
      <div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <form onSubmit={handleSubmit} className="space-y-6">
            <CourseImages courseData={formData} setCourseData={setFormData} />

            <hr />

            <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 2 }}>
              Kurs Bilgileri
            </Typography>

            <div>
              <TextField
                label="Başlık"
                name="title"
                value={formData?.title}
                onChange={handleChange}
                required
                fullWidth
                variant="outlined"
              />
            </div>

            <div>
              <TextField
                label="Açıklama"
                name="description"
                value={formData?.description}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
                variant="outlined"
              />
            </div>

            <div>
              <TextField
                select
                label="Seviye"
                name="level"
                value={formData?.level}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              >
                <MenuItem value={undefined}>Lütfen seviye seçin</MenuItem>
                <MenuItem value={CourseLevel.BEGINNER}>Başlangıç</MenuItem>
                <MenuItem value={CourseLevel.INTERMEDIATE}>Orta</MenuItem>
                <MenuItem value={CourseLevel.ADVANCED}>İleri</MenuItem>
              </TextField>
            </div>

            <div>
              <Select
                labelId="language-label"
                id="language-select"
                fullWidth
                value={
                  Array.isArray(formData?.languages)
                    ? formData?.languages
                    : [formData?.languages]
                }
                label="Diller"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    languages: e.target.value.toString().split(","),
                  })
                }
                className="col-span-1"
                multiple
              >
                {languageArray.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {user?.role === "admin" && (
              <div>
                <TextField
                  label="Fiyat"
                  name="price"
                  type="number"
                  value={formData?.price}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </div>
            )}

            <div>
              <TextField
                select
                label="Durum"
                name="status"
                value={formData?.status ?? ""}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              >
                <MenuItem value={CourseStatus.DRAFT}>Taslak</MenuItem>
                <MenuItem value={CourseStatus.PENDING}>Onay Bekliyor</MenuItem>
                {user?.role === "admin" && (
                  <MenuItem value={CourseStatus.PUBLISHED}>Yayınlandı</MenuItem>
                )}
                <MenuItem value={CourseStatus.ARCHIVED}>Arşivlendi</MenuItem>
              </TextField>
            </div>

            {/* Diğer alanlar için benzer şekilde form elemanları ekleyebilirsiniz */}

            <div>
              <Button variant="contained" color="primary" type="submit">
                Güncelle
              </Button>
            </div>
          </form>
        </Box>
      </div>
    </div>
  );
};

export default CourseUpdateForm;
