import React, { useEffect, useState } from "react";

export default function ImageColumn({ param }: { param: any }) {
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      // Asenkron işlemi burada yapıyoruz
      const url = param?.image?.url ?? "N/A";
      console.log(url);
      setImageUrl(url);
    };

    fetchImageUrl();
  }, [param]);

  return (
    <img
      src={imageUrl}
      alt={param?.row?.name || "Image"}
      className="w-full rounded"
      height={50}
      style={{ objectFit: "cover" }}
    />
  );
}
