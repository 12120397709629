import React, { useMemo } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const IyzicoPaymentModal = ({
  checkoutFormContent,
}: {
  checkoutFormContent: string;
}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const modalBody = useMemo(() => {
    const content = document.createElement("div");
    content.innerHTML = checkoutFormContent;
    const scripts = content.getElementsByTagName("script");
    for (let i = 0; i < scripts.length; i++) {
      const newScript = document.createElement("script");
      if (scripts[i].src) {
        newScript.src = scripts[i].src;
      } else {
        newScript.textContent = scripts[i].innerHTML;
      }
      newScript.async = true;
      document.head.appendChild(newScript);
    }
    return content;
  }, [checkoutFormContent]);

  return (
    <div
      className="z-50"
      dangerouslySetInnerHTML={{ __html: modalBody.innerHTML }}
    />
  );
};

export default IyzicoPaymentModal;
