import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addReview, get } from "../../../actions/courses.actions";
import {
  Box,
  Typography,
  Modal,
  Button,
  Rating,
  TextField,
} from "@mui/material";
import QuizComponent from "./QuizComponent";
import VideoComponent from "./VideoComponent";
import CourseSection from "./CourseSection";
import { ResourceType } from "@/data/enums/resource-type.enum";
import { ResourceDto } from "@/data/dtos/resource.dto";
import LiveClassComponent from "./liveClassComponents/LiveClassComponent";
import { ReviewDto } from "@/data/dtos/review.dto";
import { UserContext } from "@/context/UserContext";

export default function Course() {
  const userContext = useContext(UserContext);
  const id = useParams().id;

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;
  const { currentCourse } = useSelector((state: any) => state.courses);
  const dispatch = useDispatch();
  const [currentResource, setCurrentResource] = useState<ResourceDto | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(true); // Modal durumunu yönet
  const [review, setReview] = useState<ReviewDto | null>({
    comment: "",
    rating: 0,
    user: user?._id!,
    isVerified: false,
    likeAndDislike: { like: 0, dislike: 0 },
  });
  const [reviewCompleted, setReviewCompleted] = useState(false); // İnceleme durumu

  const handleModalClose = () => {
    if (!reviewCompleted) {
      setIsModalOpen(true); // İnceleme yapılmadıysa modal tekrar açılır
    } else {
      setIsModalOpen(false); // İnceleme yapıldıysa modal kapanır
    }
  };

  const handleReviewSubmit = () => {
    setReviewCompleted(true); // İnceleme tamamlandığında durum güncellenir
    setIsModalOpen(false); // Modal kapanır
    console.log(currentCourse?.data?._id);
    dispatch(addReview(currentCourse?.data?._id, review!) as any);
  };

  const getResourceComponent = () => {
    if (!currentResource) {
      return <Typography>Lütfen bir kaynak seçin.</Typography>;
    }
    switch (currentResource.type) {
      case ResourceType.VIDEO:
        return <VideoComponent currentResource={currentResource} />;
      case ResourceType.QUIZZ:
        return (
          <div>
            <QuizComponent quiz={currentResource.quizz!} />
          </div>
        );
      case ResourceType.LIVE_CLASS:
        return (
          <div>
            <LiveClassComponent />
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(get(id) as any);
  }, [dispatch, id]);

  useEffect(() => {
    setCurrentResource(currentCourse?.data?.sections[0].resources[0]);
  }, [currentCourse]);

  return (
    <div className="bg-white rounded shadow w-full flex flex-col gap-2">
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="review-modal-title"
        aria-describedby="review-modal-description"
      >
        <div className="container mx-auto mt-4 relative bg-white rounded shadow p-4 ">
          <Typography id="review-modal-title" variant="h6" component="h2">
            Kursu Puanlayın
          </Typography>
          <TextField
            label="Yorumunuz"
            fullWidth
            multiline
            rows={4}
            value={review!.comment}
            onChange={(e) =>
              setReview((prev) => ({
                ...prev!,
                comment: e.target.value,
                rating: prev!.rating,
              }))
            }
            sx={{ mt: 2 }}
          />
          <Typography sx={{ mt: 2 }}>Puanlama:</Typography>
          <Rating
            name="rating"
            value={review!.rating}
            onChange={(event, newValue) =>
              setReview(
                (prev) =>
                  (prev ?? ({} as ReviewDto)) && {
                    ...prev!,
                    rating: newValue ?? 0,
                  }
              )
            }
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button variant="outlined" onClick={handleModalClose}>
              Kapat
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReviewSubmit}
            >
              Gönder
            </Button>
          </Box>
        </div>
      </Modal>
      <div className="w-full bg-slate-900 flex items-center p-4">
        <Typography
          variant="h5"
          className="text-white flex items-center text-center"
        >
          {currentCourse?.data?.title || "Seçili Kaynak Yok"}
        </Typography>
      </div>
      <div className="w-full flex md:flex-row flex-col px-2 md:px-0 gap-4 justify-between">
        <div className="w-full">
          <Box>
            <div className="relative min-h-96 container mx-auto rounded border p-4">
              {getResourceComponent()}
            </div>
          </Box>
        </div>
        <CourseSection
          sections={currentCourse?.data?.sections}
          setCurrentResource={setCurrentResource}
        />
      </div>
    </div>
  );
}
