import React, { useState } from "react";
import { Button, Stepper, Step, StepLabel, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CourseInfo from "./CourseInfo";
import UploadSection from "./UploadSection";
import Confirmation from "./Confirmation";
import Sections from "./Section";
import { create } from "@/actions/courses.actions";
import { url } from "@/utils";
import { clearState } from "@/actions/image.actions";
import { useNavigate } from "react-router-dom";
import { CourseDto } from "@/data/dtos/course.dto";
import { CourseStatus } from "@/data/enums/course-status.enum";
import { CourseLevel } from "@/data/enums/course-level.enum";

const steps = ["Kurs Bilgileri", "Bölümler", "Görseller", "Onay ve Oluştur"];

const MultiStepForm: React.FC = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const { user } = useSelector((state: any) => state.auth);
  const [courseData, setCourseData] = useState<CourseDto>({
    title: "",
    description: "",
    instructors: [user._id],
    schedule: [],
    level: CourseLevel.BEGINNER,
    languages: ["Türkçe"],
    reviews: [],
    documents: [],
    price: 0,
    purchasedBy: [],
    categories: [],
    status: CourseStatus.DRAFT,
    discountPercentage: 0,
    images: [],
    seo: { title: "", description: "", keywords: "" },
    relatedCourses: [],
    isOnSale: false,
    isCertified: false,
    sections: [
      {
        title: "",
        description: "",
        course: "",
        order: 1,
        resources: [],
      },
    ],
    tax: 0,
  });

  const handleNext = () => setActiveStep((prev) => prev + 1);

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleChange = (field: string, value: any) => {
    console.log(value);
    if (courseData && field) {
      console.log(value);
      setCourseData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleSubmit = () => {
    console.log("Submit edildi: " + courseData);
    console.log(courseData);
    dispatch(create(courseData) as any)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    setCourseData({
      title: "",
      description: "",
      instructors: [user._id],
      schedule: [],
      level: CourseLevel.BEGINNER,
      languages: ["Türkçe"],
      reviews: [],
      documents: [],
      price: 0,
      purchasedBy: [],
      categories: [],
      status: CourseStatus.DRAFT,
      discountPercentage: 0,
      images: [],
      seo: { title: "", description: "", keywords: "" },
      relatedCourses: [],
      isOnSale: false,
      isCertified: false,
      sections: [
        {
          title: "",
          description: "",
          course: "",
          order: 1,
          resources: [],
        },
      ],
      tax: 0,
    });
    dispatch(clearState() as any);
    navigator(url("Admin.Courses"));
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <CourseInfo courseData={courseData} onChange={handleChange} />;
      case 1:
        return (
          <Sections
            sections={courseData.sections ?? []}
            onChange={handleChange}
          />
        );
      case 2:
        return (
          <UploadSection
            images={courseData.images ?? []}
            onChange={handleChange}
          />
        );
      case 3:
        return <Confirmation courseData={courseData} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>{renderStepContent()}</Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Geri
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={
            activeStep === steps.length - 1 ? () => handleSubmit() : handleNext
          }
        >
          {activeStep === steps.length - 1 ? "Oluştur" : "İleri"}
        </Button>
      </Box>
    </Box>
  );
};

export default MultiStepForm;
