import { SET_MESSAGE, CLEAR_MESSAGE } from "./type";

export const setMessage =
  (message: string, timeout = 5000) =>
  (dispatch: any) => {
    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
      });
    });
  };

export const setError = (message: any) => ({
  type: SET_MESSAGE,
  payload: message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});
