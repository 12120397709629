import React, { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { addToCart } from "@/actions/cart.actions";
import { formatMoney, url } from "@/utils";
import { useContext } from "react";
import { UserContext } from "@/context/UserContext";
import { CourseDto } from "@/data/dtos/course.dto";
import { Link } from "react-router-dom";

export default function Card({ course }) {
  const dispatch = useDispatch();
  const cart = useSelector((state: any) => state.cart);
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }
  const { user } = userContext;

  const handleAddToCart = () => {
    dispatch(addToCart(course) as any);
  };

  const coursePrice = formatMoney(
    course.price - (course.price * course.discountPercentage) / 100
  );

  const isAlreadyInCart = cart.items?.some((item) => item._id === course._id);
  const isOwnedByUser = user?.courses?.some(
    (crs) => (crs as unknown as CourseDto)._id === course._id
  );

  return (
    <div className="max-w-80 bg-white shadow rounded max-h-fit group relative block">
      {/* Kurs Resmi ve Başlığı Link'e Sarıldı */}
      <Link
        to={url("Courses.Course-By-Slug", { slug: course.slug })}
        className="block"
      >
        <div
          className="h-48 w-full bg-gray-200 flex flex-col justify-between p-4 bg-cover bg-center"
          style={{
            backgroundImage: `url("${course.images[0].url}")`,
          }}
        ></div>
      </Link>
      <div className="p-4 flex flex-col items-center">
        <p className="text-gray-400 font-light text-xs text-center grid grid-cols-2 gap-2">
          {course.instructors?.map((instructor, index) => (
            <span className="flex gap-4" key={index}>
              {instructor.name}
            </span>
          ))}
        </p>
        {/* Kurs Başlığı Link'e Sarıldı */}
        <Link to={url("Courses.Course-By-Slug", { slug: course.slug })}>
          <h2 className="text-gray-800 text-center mt-1 whitespace-normal">
            {course.title}
          </h2>
        </Link>
        {course.discountPercentage > 0 ? (
          <div className="flex justify-center gap-4 w-full">
            <p className="text-center text-gray-800 mt-1">{coursePrice} </p>
            <p className="text-center text-gray-600 text-sm mt-1 line-through">
              {formatMoney(course.price)}
            </p>
          </div>
        ) : (
          <p className="text-center text-gray-800 mt-1">
            {formatMoney(course.price)}
          </p>
        )}

        {isOwnedByUser ? (
          <div className="text-green-600 mt-4">Bu Kursu Satın Aldınız</div>
        ) : (
          <>
            {isAlreadyInCart ? (
              <div className="text-gray-500 mt-4">Sepette Mevcut</div>
            ) : (
              <div className="flex justify-between gap-2 w-full mt-4">
                {/* Sepete Ekle Butonu */}
                <button
                  onClick={handleAddToCart}
                  className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 active:bg-blue-700 disabled:opacity-50 mt-4 flex gap-2 items-center"
                >
                  Sepete Ekle
                  <FontAwesomeIcon icon={faShoppingCart} />
                </button>
                {/* Favori Butonu */}
                <button className="px-2 py-1 disabled:opacity-50 mt-4 flex gap-2 items-center rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-heart group-hover:fill-red-500 text-red-500"
                    width="24"
                    height="24"
                  >
                    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 1 0-7.78 7.78l1.06 1.06L12 20.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                  </svg>
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
