import { ReviewDto } from "@/data/dtos/review.dto";
import coursesService from "../services/courses.service";
import { setError, setMessage } from "./message";
import {
  ADD_RESOURCE_TO_COURSE_SECTION_FAILED,
  ADD_RESOURCE_TO_COURSE_SECTION_SUCCESS,
  ADD_REVIEW_TO_COURSE_FAILED,
  ADD_REVIEW_TO_COURSE_SUCCESS,
  ADD_SECTION_TO_COURSE_FAILED,
  ADD_SECTION_TO_COURSE_SUCCESS,
  CREATE_COURSE_FAILED,
  CREATE_COURSE_SUCCESS,
  FETCH_COURSE,
  FETCH_COURSE_FAILED,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSES,
  FETCH_COURSES_FAILED,
  FETCH_COURSES_SUCCESS,
  FILE_UPLOADING_STARTED,
  REMOVE_COURSE_FAILED,
  REMOVE_COURSE_SUCCESS,
  REMOVE_RESOURCE_FROM_COURSE_SECTION_FAILED,
  REMOVE_RESOURCE_FROM_COURSE_SECTION_SUCCESS,
  REMOVE_SECTION_FROM_COURSE_FAILED,
  REMOVE_SECTION_FROM_COURSE_SUCCESS,
  UPDATE_COURSE_FAILED,
  UPDATE_COURSE_SECTION_FAILED,
  UPDATE_COURSE_SECTION_SUCCESS,
  UPDATE_COURSE_SUCCESS,
  UPDATE_RESOURCE_FROM_COURSE_SECTION_FAILED,
  UPDATE_RESOURCE_FROM_COURSE_SECTION_SUCCESS,
  UPLOAD_FILE_SUCCESS,
} from "./type";

export const create = (payload: any) => async (dispatch: any) => {
  await coursesService
    .create(payload)
    .then((response: any) => {
      dispatch({ type: CREATE_COURSE_SUCCESS, payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: CREATE_COURSE_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const getAll = () => async (dispatch: any) => {
  dispatch({ type: FETCH_COURSES });
  await coursesService
    .getAll()
    .then((response: any) => {
      dispatch({ type: FETCH_COURSES_SUCCESS, payload: response.data.data });
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: FETCH_COURSES_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const get = (payload: any) => async (dispatch: any) => {
  dispatch({ type: FETCH_COURSE });
  await coursesService
    .get(payload)
    .then((response: any) => {
      dispatch({ type: FETCH_COURSE_SUCCESS, payload: response.data });
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: FETCH_COURSE_FAILED, payload: err });
      dispatch(
        setError({
          message: err.response?.data.message,
          errors: { course: { errors: ["Kurs bulunamadı"] } },
        })
      );
    });
};

export const getByInstructer = (id: string) => {
  return async (dispatch: any) => {
    dispatch({ type: FETCH_COURSES });
    await coursesService
      .getByInstructer(id)
      .then((response: any) => {
        dispatch({ type: FETCH_COURSES_SUCCESS, payload: response.data.data });
        return Promise.resolve();
      })
      .catch((err) => {
        dispatch({ type: FETCH_COURSES_FAILED });
        dispatch(setError(err.response?.data));
        return Promise.reject(new Error(err));
      });
  };
};

export const getCourseBySlug = (slug: string) => async (dispatch: any) => {
  dispatch({ type: FETCH_COURSE });
  await coursesService
    .getBySlug(slug)
    .then((response: any) => {
      dispatch({ type: FETCH_COURSE_SUCCESS, payload: response.data });
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: FETCH_COURSE_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const update = (id: string, payload: any) => async (dispatch: any) => {
  await coursesService
    .update(id, payload)
    .then((response: any) => {
      dispatch({ type: UPDATE_COURSE_SUCCESS, payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: UPDATE_COURSE_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const remove = (payload: any) => async (dispatch: any) => {
  await coursesService
    .remove(payload)
    .then((response: any) => {
      dispatch({ type: REMOVE_COURSE_SUCCESS, payload: response.data });
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: REMOVE_COURSE_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const filter = (payload: any) => async (dispatch: any) => {
  await coursesService
    .filter(payload)
    .then((response: any) => {
      dispatch({ type: FETCH_COURSES_SUCCESS, payload: response.data });
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: FETCH_COURSES_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const sales = () => async (dispatch: any) => {
  await coursesService
    .sales()
    .then((response: any) => {
      console.log(response);
      dispatch({ type: FETCH_COURSES_SUCCESS, payload: response.data.data });
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: FETCH_COURSES_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const addSection = (payload: any) => async (dispatch: any) => {
  await coursesService
    .addSection(payload)
    .then((response: any) => {
      dispatch({ type: ADD_SECTION_TO_COURSE_SUCCESS, payload: response.data });
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADD_SECTION_TO_COURSE_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const updateSection =
  (id: string, payload: any) => async (dispatch: any) => {
    await coursesService
      .updateSection(id, payload)
      .then((response: any) => {
        dispatch({
          type: UPDATE_COURSE_SECTION_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
      })
      .catch((err) => {
        dispatch({ type: UPDATE_COURSE_SECTION_FAILED });
        dispatch(setError(err.response?.data));
        return Promise.reject(new Error(err));
      });
  };

export const removeSection = (payload: any) => async (dispatch: any) => {
  await coursesService
    .removeSection(payload)
    .then((response: any) => {
      console.log(response.data);
      dispatch({
        type: REMOVE_SECTION_FROM_COURSE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: REMOVE_SECTION_FROM_COURSE_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const addResource = (payload: any) => async (dispatch: any) => {
  await coursesService
    .addResource(payload)
    .then((response: any) => {
      dispatch({
        type: ADD_RESOURCE_TO_COURSE_SECTION_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: ADD_RESOURCE_TO_COURSE_SECTION_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const uploadVideo = (payload: any) => async (dispatch: any) => {
  await coursesService
    .uploadVideo(payload, (ProgressEvent: any) => {
      dispatch({
        type: FILE_UPLOADING_STARTED,
        payload,
        ProgressEvent,
      });
    })
    .then((response: any) => {
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    })
    .catch((error: any) => {
      console.log(error);
      const message = error.response.data;
      dispatch({
        type: FILE_UPLOADING_STARTED,
        payload: message,
      });
      dispatch(setError(message));
      return Promise.reject(new Error(message));
    });
};

export const updateResource =
  (id: string, payload: any) => async (dispatch: any) => {
    console.log(id);
    await coursesService
      .updateResource(id, payload)
      .then((response: any) => {
        dispatch({
          type: UPDATE_RESOURCE_FROM_COURSE_SECTION_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: UPDATE_RESOURCE_FROM_COURSE_SECTION_FAILED });
        dispatch(setError(err.response?.data));
        return Promise.reject(new Error(err));
      });
  };

export const removeResource = (payload: any) => async (dispatch: any) => {
  await coursesService
    .removeResource(payload)
    .then((response: any) => {
      dispatch({
        type: REMOVE_RESOURCE_FROM_COURSE_SECTION_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: REMOVE_RESOURCE_FROM_COURSE_SECTION_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};

export const addReview =
  (id: string, payload: ReviewDto) => async (dispatch: any) => {
    await coursesService
      .addReview(id, payload)
      .then((response: any) => {
        dispatch({
          type: ADD_REVIEW_TO_COURSE_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
      })
      .catch((err) => {
        dispatch({ type: ADD_REVIEW_TO_COURSE_FAILED });
        dispatch(setError(err.response?.data));
        return Promise.reject(new Error(err));
      });
  };
