import { getByInstructer } from "@/actions/courses.actions";
import Card from "@/components/core/Card";
import { UserContext } from "@/context/UserContext";
import { url } from "@/utils";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const disptach = useDispatch();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { courses } = useSelector((state: any) => state.courses);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  useEffect(() => {
    disptach(getByInstructer(user?._id!) as any);
  }, [disptach, user?._id]);

  return (
    <div className="container mx-auto my-4 relative">
      <Typography variant="h5" component="h5" sx={{ marginBottom: 2 }}>
        You are currently inspection your public profile
      </Typography>
      <div className="relative max-w-2xl mx-4 sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto mt-16 bg-white shadow-xl rounded-lg text-gray-900">
        <div className="rounded-t-lg h-32 overflow-hidden">
          <img
            className="object-cover object-top w-full"
            src="https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
            alt="Mountain"
          />
        </div>
        <div className="mx-auto w-32 h-32 relative -mt-16 border-4 border-white rounded-full overflow-hidden">
          <img
            className="object-cover object-center h-32"
            src={user?.profileImage ?? ""}
            alt={`${user?.name} ${user?.surname}`}
          />
        </div>
        <div className="text-center mt-2">
          <h2 className="font-semibold">
            {user?.name} {user?.surname}
          </h2>
          <p className="text-gray-500">{user?.job ?? ""}</p>
        </div>
        <ul className="py-4 mt-2 text-gray-700 flex items-center justify-around">
          <li className="flex flex-col items-center justify-around">
            <svg
              className="w-4 fill-current text-blue-900"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
            </svg>
            <div>2k</div>
          </li>
          <li className="flex flex-col items-center justify-between">
            <svg
              className="w-4 fill-current text-blue-900"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M7 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1c2.15 0 4.2.4 6.1 1.09L12 16h-1.25L10 20H4l-.75-4H2L.9 10.09A17.93 17.93 0 0 1 7 9zm8.31.17c1.32.18 2.59.48 3.8.92L18 16h-1.25L16 20h-3.96l.37-2h1.25l1.65-8.83zM13 0a4 4 0 1 1-1.33 7.76 5.96 5.96 0 0 0 0-7.52C12.1.1 12.53 0 13 0z" />
            </svg>
            <div>10k</div>
          </li>
          <li className="flex flex-col items-center justify-around">
            <svg
              className="w-4 fill-current text-blue-900"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
            </svg>
            <div>{courses.length}</div>
          </li>
        </ul>
        <div className="absolute -top-5 -right-16">
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-full"
            onClick={() =>
              navigate(url("Profile.Edit-Profile", { id: user?._id }))
            }
          >
            Edit Profile <FontAwesomeIcon icon={faPen} />
          </button>
        </div>
      </div>

      {/* <div className="w-full ">
        <div className="grid grid-cols-4 my-4 relative">
          {courses?.map((course: any) => (
            <div key={course._id} className="relative w-full">
              <Card course={course} />
              <div className="absolute -top-4 right-10">
                <button
                  className="bg-yellow-500 text-white px-4 py-2 rounded-full"
                  onClick={() =>
                    navigate(url("Admin.Courses.Edit", { id: course?._id }))
                  }
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}
