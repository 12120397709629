import metricsService from "@/services/metrics.service";
import { setError } from "./message";

export const get = () => async (dispatch: any) => {
  await metricsService
    .get()
    .then((response: any) => {
      dispatch({ type: "FETCH_METRICS_SUCCESS", payload: response.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "FETCH_METRICS_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};
