import React, { useEffect, useState } from "react";
import Table from "../../../../../components/core/Table";
import { useDispatch, useSelector } from "react-redux";
import { getAll, remove } from "../../../../../actions/courses.actions";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../../../../utils";

export default function CourseList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const allCourses = useSelector((state: any) => state.courses);
  const { user } = useSelector((state: any) => state.auth);
  const [coursesByInstructor, setCoursesByInstructor] = useState([]);

  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  useEffect(() => {
    console.log("useEffect 1");
    if (allCourses.listStatus === "idle") {
      dispatch(getAll() as any);
    }
    if (allCourses?.courses?.length > 0) {
      setCoursesByInstructor(
        allCourses?.courses?.filter((course: any) =>
          course.instructors?.some((instructor: any) => {
            if (instructor._id) {
              return instructor._id === user._id;
            } else {
              return instructor === user._id;
            }
          })
        )
      );
    }
  }, [dispatch, allCourses, user._id]);

  if (coursesByInstructor.length > 0) {
    return (
      <div>
        <Table
          data={coursesByInstructor}
          pageSizeOptions={[25, 50, 100]}
          whitelist={[
            "title",
            "level",
            "price",
            "status",
            "isOnSale",
            "saleStartDate",
          ]}
          onDelete={(id: string) => handleDelete(id)}
          onEdit={(id: string) => {
            navigation(url("Admin.Courses.Edit", { id }));
          }}
        />
      </div>
    );
  }
  return (
    <div className="text-center p-4 bg-yellow-300  rounded shadow">
      <span className="font-bold text-lg text-gray-600">No Content</span>
      <br />
      <Link to={url("Admin.Courses.Create")}>Create Course</Link>
    </div>
  );
}
