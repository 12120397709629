import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { create, get } from "@/actions/address.action";
import { AddressDto } from "@/data/dtos/address.dto";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
} from "@mui/material";
import { UserContext } from "@/context/UserContext";

export default function CreateAddress({
  id,
}: {
  readonly id?: string | undefined;
}) {
  const dispatch = useDispatch();
  const { currentAddress, status } = useSelector(
    (state: any) => state.addresses
  );
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  const [formData, setFormData] = useState<AddressDto>(
    currentAddress || {
      title: "",
      contact: user?._id,
      contactName: "",
      address: "",
      city: "",
      country: "",
      isBillingAddress: false,
    }
  );

  useEffect(() => {
    if (id) {
      dispatch(get(id) as any);
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (currentAddress) {
      setFormData((prev) => ({
        ...prev,
        ...currentAddress,
        contact: user?._id,
      }));
    }
  }, [currentAddress, user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Burada submit işlemi yapılacak (örneğin create veya update action çağrılabilir)
    console.log("Form Submitted: ", formData);
    dispatch(create(formData) as any);
    setFormData({
      title: "",
      contact: user?._id ?? "",
      contactName: "",
      address: "",
      city: "",
      country: "",
      isBillingAddress: false,
    });
  };

  return (
    <div className="container mx-auto mt-4 relative bg-white rounded shadow ">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: 500,
          margin: "auto",
          padding: 2,
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          {id ? "Edit Address" : "Create Address"}
        </Typography>

        <TextField
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          fullWidth
          required
        />
        <TextField
          label="Contact Name"
          name="contactName"
          value={formData.contactName}
          onChange={handleInputChange}
          fullWidth
          required
        />
        <TextField
          label="Address"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          fullWidth
          required
          multiline
          rows={3}
        />
        <TextField
          label="City"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          fullWidth
          required
        />
        <TextField
          label="Country"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          fullWidth
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              name="isBillingAddress"
              checked={formData.isBillingAddress}
              onChange={handleInputChange}
            />
          }
          label="Is Billing Address?"
        />
        <Button type="submit" variant="contained" color="primary">
          {id ? "Update Address" : "Create Address"}
        </Button>
        {status === "loading" && (
          <Typography variant="body2" color="textSecondary">
            Loading...
          </Typography>
        )}
      </Box>
    </div>
  );
}
