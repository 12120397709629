import {
  CREATE_IMAGE_SUCCESS,
  FETCH_IMAGES,
  FETCH_IMAGES_FAILED,
  FETCH_IMAGES_SUCCESS,
  REMOVE_IMAGE_FAILED,
  REMOVE_IMAGE_SUCCESS,
  UPDATE_IMAGE_FAILED,
  UPDATE_IMAGE_SUCCESS,
} from "../actions/type";

const initialState = {
  images: [],
  currentImage: null,
  status: "idle",
};

export default function imageReducer(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_IMAGES:
      return {
        ...state,
        status: "loading",
      };
    case FETCH_IMAGES_SUCCESS:
      return {
        ...state,
        images: payload.data,
        status: "success",
      };

    case CREATE_IMAGE_SUCCESS:
      console.log(payload);
      return {
        ...state,
        images: [...state.images, payload],
        currentImage: payload,
        status: "success",
      };

    case UPDATE_IMAGE_SUCCESS:
      return {
        ...state,
        images: state.images.map((image: any) => {
          if (image._id === payload.data._id) {
            return payload;
          }
          return image;
        }),
        status: "success",
      };

    case REMOVE_IMAGE_SUCCESS:
      console.log(payload);
      return {
        ...state,
        images: state.images.filter((image: any) => {
          console.log(image._id, payload.data._id);
          return image._id !== payload.data._id;
        }),
        status: "success",
      };

    case "CLEAR_IMAGE_STATE":
      return {
        ...state,
        images: [],
        status: "idle",
      };

    case REMOVE_IMAGE_FAILED:
    case UPDATE_IMAGE_FAILED:
    case FETCH_IMAGES_FAILED:
      return {
        ...state,
        status: "failed",
      };

    default:
      return state;
  }
}
