import React, {
  useContext,
  useEffect,
  useState,
  createContext,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";

interface LocationContextProps {
  currentPath: string;
  previousPath: string;
}

const LocationContext = createContext<LocationContextProps | undefined>(
  undefined
);

export const useLocationContext = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error(
      "useLocationContext must be used within a LocationProvider"
    );
  }
  return context;
};

export const LocationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [previousPath, setPreviousPath] = useState<string | null>(null);

  useEffect(() => {
    setPreviousPath(currentPath);
    setCurrentPath(location.pathname);
  }, [location.pathname, currentPath]);

  const value = useMemo(
    () => ({ currentPath, previousPath: previousPath ?? "" }),
    [currentPath, previousPath]
  );

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};
