import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";

interface AuthContextInterface {
  user: any;
}

const Context = createContext<AuthContextInterface>({
  user: null,
});

export const AuthProvider = ({ children }) => {
  const { user, roles } = useSelector((state: any) => state.auth);

  const data = {
    user,
    roles,
  };

  return <Context.Provider value={data}>{children}</Context.Provider>;
};

export const useAuth = () => {
  console.log(Context);
  return useContext(Context);
};
