import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../../actions/authAction";
import { url } from "../../../../utils";
import InputLabel from "../../../../components/InputLabel";
import TextInput from "../../../../components/core/TextInput";
import InputError from "../../../../components/core/InputError";
import PrimaryButton from "../../../../components/PrimaryButton";
import React, { useState } from "react";

export default function ForgotPassword() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state: any) => state.message);

  const onChangeEmail = (e: any) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleForgetPassword = (e: any) => {
    e.preventDefault();
    setLoading(true);

    dispatch(forgotPassword({ email }) as any)
      .then((res: any) => {
        setLoading(false);
        navigate(url("Home"));
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="rounded w-full border-slate-100 dark:text-slate-100 grid grid-cols-1 gap-4">
      Giriş Yap
      {message?.message && (
        <div className="flex flex-col gap-4 rounded bg-red-500 p-4">
          {message.message}
        </div>
      )}
      <div>
        <InputLabel htmlFor="email" value="Email" />
        <TextInput
          type="email"
          name="email"
          value={email}
          className="mt-1 block w-full"
          autoComplete="true"
          isFocused={true}
          onChange={(e: any) => onChangeEmail(e)}
        />
        <InputError messages={message?.errors?.email} className="mt-2" />
      </div>
      <div className="flex items-center justify-end mt-4">
        <PrimaryButton
          className="ms-4"
          onClick={handleForgetPassword}
          disabled={loading}
        >
          Şifremi Unut
        </PrimaryButton>
      </div>
    </div>
  );
}
