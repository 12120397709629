import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_FAILED,
} from "../actions/type";

interface CategoriesState {
  categories: any;
  currentCategory: any;
  status: string;
}

const initialState: CategoriesState = {
  categories: [],
  currentCategory: null,
  status: "idle",
};

export default function categoriesReducer(
  state = initialState,
  action: any
): CategoriesState {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CATEGORIES:
      return {
        ...state,
        status: "loading",
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload,
        status: "success",
      };
    case FETCH_FAILED:
      return {
        ...state,
        status: "failed",
      };

    case "CREATE_CATEGORY_SUCCESS":
      return {
        ...state,
        categories: [...state.categories, payload],
        status: "success",
      };

    case "UPDATE_CATEGORY_SUCCESS":
      console.log(payload);
      return {
        ...state,
        categories: state.categories.map((category: any) =>
          category._id === payload.data._id ? payload.data : category
        ),
        status: "success",
      };

    case "REMOVE_CATEGORY_SUCCESS":
      return {
        ...state,
        categories: state.categories.filter(
          (category: any) => category._id !== payload
        ),
        status: "success",
      };
    case "GET_CATEGORY_SUCCESS":
      return {
        ...state,
        currentCategory: payload,
        status: "success",
      };
    default:
      return state;
  }
}
