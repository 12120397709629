import { url } from "@/utils";
import { faFile, faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

export default function ContentList() {
  return (
    <div className="p-4 w-full bg-white rounded shadow-lg flex justify-center gap-4">
      <Link to={url("Admin.Contents.Images")}>
        <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow flex justify-center flex-col items-center hover:cursor-pointer">
          <FontAwesomeIcon
            icon={faImage}
            className="text-gray-500"
            style={{ fontSize: "10rem" }}
          />
          <span className="text-2xl text-gray-800">Görseller</span>
        </div>
      </Link>
      <Link to={url("Admin.Contents.Images")}>
        <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow flex justify-center flex-col items-center hover:cursor-pointer">
          <FontAwesomeIcon
            icon={faVideo}
            className="text-gray-500"
            style={{ fontSize: "10rem" }}
          />
          <span className="text-2xl text-gray-800">Videolar</span>
        </div>
      </Link>
      <Link to={url("Admin.Contents.Images")}>
        <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow flex justify-center flex-col items-center hover:cursor-pointer">
          <FontAwesomeIcon
            icon={faFile}
            className="text-gray-500"
            style={{ fontSize: "10rem" }}
          />
          <span className="text-2xl text-gray-800">Dokümanlar</span>
        </div>
      </Link>
    </div>
  );
}
