import axios from "axios";

const create = async (payload: any) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/images",
    payload
  );
  return response;
};

const upload = async (
  payload: FormData,
  onUploadProgress: (ProgressEvent: any) => void
) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/images/upload",
    payload,
    {
      onUploadProgress,
    }
  );

  return response;
};

const getAll = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/images"
  );
  console.log(response);
  return response;
};

const get = async (id: string) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/images/" + id
  );
  return response;
};

const update = async (id: string, payload: ImageData) => {
  const response = await axios.put(
    process.env.REACT_APP_API_BASE_URL + "/images/" + id,
    payload
  );
  return response;
};

const remove = async (id: string) => {
  const response = await axios.delete(
    process.env.REACT_APP_API_BASE_URL + "/images/" + id
  );
  console.log(response);
  return response;
};

const imageService = {
  create,
  getAll,
  get,
  update,
  remove,
  upload,
};

export default imageService;
