import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  LocalUser,
  RemoteUser,
  useJoin,
  useIsConnected,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
} from "agora-rtc-react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPhoneSlash } from "@fortawesome/free-solid-svg-icons";

export default function Room() {
  const { user } = useSelector((state: any) => state.auth);
  const APP_ID = "c8990a11e83b40aba91ba5f338c5bf15";
  const TEMP_TOKEN =
    "007eJxTYPh81rdZUGXHikPmRtFxyXzTN03cYyR8zNk9YGXByg4HthoFhmQLS0uDREPDVAvjJBODxKRES8OkRNM0Y2OLZNOkNEPTP94O6Q2BjAxu+5OYGBkgEMRnYUjOSCxhYAAA1wUdyw==";
  const CHANNEL = "chat";

  // const CHANNEL = "chat";
  const { id } = useParams();
  // set the connection state
  const [calling, setCalling] = useState(false);
  const isConnected = useIsConnected();

  //local user
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);

  // to leave the call

  useJoin({ appid: APP_ID, channel: CHANNEL, token: TEMP_TOKEN }, calling);

  usePublish([localMicrophoneTrack, localCameraTrack]);

  //remote users
  const remoteUsers = useRemoteUsers();
  const { audioTracks } = useRemoteAudioTracks(remoteUsers);

  // play the remote user audio tracks
  audioTracks.forEach((track) => track.play);

  return (
    <>
      <div className="room flex flex-col p-4 min-h-[80vh] bg-gray-900 text-white rounded-se justify-center">
        {isConnected ? (
          <div className="user-list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full p-4">
            <div className="user relative w-80 h-80 flex flex-col items-center bg-gray-800 border border-gray-700 rounded-md p-4 shadow-md">
              <LocalUser
                audioTrack={localMicrophoneTrack}
                cameraOn={cameraOn}
                micOn={micOn}
                videoTrack={localCameraTrack}
                cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
              >
                <span className="user-name absolute bottom-2 left-2 bg-black bg-opacity-50 text-xs px-2 py-1 rounded">
                  {user?.name}
                </span>
              </LocalUser>
            </div>
            {remoteUsers.map((user) => (
              <div
                className="user relative flex flex-col items-center w-80 h-80 rounded-md p-4 shadow-md"
                key={user.uid}
              >
                <RemoteUser
                  cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                  user={user}
                  className="w-80 h-80"
                >
                  <span className="user-name absolute bottom-2 left-2 bg-black bg-opacity-50 text-xs px-2 py-1 rounded">
                    {user.uid}
                  </span>
                </RemoteUser>
              </div>
            ))}
          </div>
        ) : (
          <div className="join-room max-w-md mx-auto bg-gray-800 rounded-lg shadow-lg p-6 text-center justify-center">
            <button
              className={`join-channel ${
                !APP_ID || !CHANNEL
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700"
              } text-white font-bold py-2 px-4 rounded`}
              disabled={!APP_ID || !CHANNEL}
              onClick={() => setCalling(true)}
            >
              <span>Join Channel</span>
            </button>
          </div>
        )}
      </div>
      {isConnected && (
        <div className="control justify-between items-center bg-gray-800 text-gray-200 px-6 py-3 bottom-0 w-full rounded-br rounded-bl">
          <div className="left-control flex gap-4">
            <button
              className="btn flex items-center justify-center p-2 rounded-full bg-gray-700 hover:bg-green-500 transition"
              onClick={() => setMic((a) => !a)}
            >
              <i className={`i-microphone ${!micOn ? "off opacity-50" : ""}`} />
            </button>
            <button
              className="btn flex items-center justify-center p-2 rounded-full bg-gray-700 hover:bg-blue-500 transition"
              onClick={() => setCamera((a) => !a)}
            >
              <i className={`i-camera ${!cameraOn ? "off opacity-50" : ""}`} />
            </button>
            <button
              className={`btn btn-phone p-2 rounded-full ${
                calling ? "bg-red-600" : "bg-green-500"
              } hover:opacity-90 transition`}
              onClick={() => setCalling((a) => !a)}
            >
              {calling ? (
                <FontAwesomeIcon icon={faPhoneSlash} />
              ) : (
                <FontAwesomeIcon icon={faPhone} />
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
