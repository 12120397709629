import { CLEAR_MESSAGE, SET_MESSAGE } from "../actions/type";

interface MessageState {
  message: any;
}

const initialState: MessageState = {
  message: null,
};

export default function messageReducer(
  state = initialState,
  action: any
): MessageState {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return { ...state, message: payload };

    case CLEAR_MESSAGE:
      return { message: null };

    default:
      return state;
  }
}
