import React from "react";

export default function AboutOnlineEducation() {
  return (
    <div>
      <section className="py-24 relative">
        <div className="sm:flex items-center max-w-screen-xl">
          <div className="sm:w-1/2 p-10">
            <div className="image object-center text-center">
              <img src="https://i.imgur.com/WbQnbas.png" alt="sdasda" />
            </div>
          </div>
          <div className="sm:w-1/2 p-5">
            <div className="text">
              <h2 className="my-4 font-bold text-3xl  sm:text-4xl ">
                Online Eğitim Platformumuz
                <span className="text-indigo-600">Hakkında</span>
              </h2>
              <p className="text-gray-700">
                Yıllardır mesleğimiz ‘iletişim’ ile paralel gerek bireysel gerek
                kurumsal eğitimler düzenlemekteyiz. Bu eğitimler başlangıçta
                yalnızca mesleki marifetleri geliştirme amaçlı iken zaman içinde
                bireysel ihtiyaç alanlarından doğmuş, ruha yönelik çalışmalar
                olarak da belirmiştir. Kurum ve birey arasındaki bu gel git
                neticesinde, akıl tarafında olanlar için kalben yaptıklarımız,
                kalp tarafında olanlar için ise aklen yaptıklarımız müptezel hal
                almıştır. Hedef kitlenin ikircikli hali ve bu ikircikliğe sebep
                sektör yapısı bizi bir disipline doğru itelemiştir. Kimliğimizi
                daha net ortaya koyma ihtiyacını beraberinde getirmiştir. Geçen
                zamanda sizlerin de bizzati tanıklığında, o kadar çok kişi ve
                kurum eğitimden söz etmiştir ki, Kıymetli Yaşar Kemal’in Ölmez
                Otu’ndaki şu cümle; Yerde, gökte bir kaynaşma, bir kartal
                hercümerci… ‘Eğitim&Danışmanlık&Kişisel Gelişim’ alanında
                karmaşayı net özetler. Sektörde ki otomatikleşmiş, ihtiyaç
                dahilinde olmadan sunulan bilgi, gün sonunda hedef kitleyi
                tatminkar olmayan sonuçlara sürüklemiştir. Birçok kurum/kişi
                kendilerini ‘av’ olarak hissettiklerini beyan etmektedir. Bu
                gerçek ‘hedef kitle’ odaklı, bilgiye yüksek saygı gösteren,
                kaliteyi önde tutan yapımızda sektör içindeki konumumuza dair
                bir seçim yapma zorunluluğu doğurmuştur. Bizler, hedef
                kitlemizin hissedişinde memnuniyet ve yüksek tatmine odaklı bir
                işleyiş ile tamamladığımız geçmişi yine aynı algı ile geleceğe
                taşıma maksadındayız. Bu maksat ile iş hayatımızda bazı
                değişiklikler yapma kararı aldık. Yenilendik. Yeniliğin
                letafetten yana ruhumuza, kimliğimize şimdiden iyi geldiğini
                söyleyebiliriz. Sizlerde de aynı iyiliğin tezahürü temennisi
                ile; Say bünyesindeki eğitim ve danışmanlık faaliyetlerimiz
                içerisinde kurumsal ve bireysel eğitimlerle yola devam
                edeceğimizi duyurmak isteriz. Sizlere geniş bir eğitimci
                yelpazesi sunacağız. Her geçen gün geliştirmeyi hedeflediğimiz
                bu yelpazede hem kurumsal hem de bireylerin ruhsal ihtiyaçlarına
                yönelik eğitimleri verebilecek güçlü bir kadro ile sesleneceğiz.
                Sayda Yayıncılık markası adı altında 30 yılı aşkın süredir
                faaliyet gösterdiğimiz yayıncılık faaliyetleri esnasında
                biriktirdiğimiz tecrübe ve bizlerle birlikte yol alan
                yayınevimizin değerli yazarları/uzman kadromuzdan aldığımız güç
                ile sektörde size duayenler ile sesleniyor olmanın onuru
                içindeyiz. Tüm bu online eğitimlerimizi de iki kategori altında
                sunacağız; Mesleki ve Teknik Eğitimler (Kurumsal-Bireysel)
                Kurumun/bireyin faaliyet alanına uygun iş marifetlerini
                geliştirecek teknik eğitimler, Motivasyon ve Farkındalık
                Eğitimleri (Kurumsal-Bireysel) Kurum personelinin/bireyin ruhuna
                hitap edecek motivasyon ve farkındalık eğitimleri En önemlisi
                tüm bu faaliyetleri geçmişten farklı olarak bir BİLGİ DİSİPLİNİ
                içerisinde yapacağız. Dayanakları olan, dayanakları kabul görmüş
                disiplinler ile iş birliği içerisinde yürüteceğiz. Bundan
                sonraki zamanlarda bizden alacağınız eğitim duyuru haberlerinde
                ulusal, uluslararası bilgi disiplinlerine tanık edeceğinizi
                müjdeleyebiliriz. Tarihe ilgili okurlarımız hatırlayacaktır ki
                Göktürk ve Uygur devirlerinde ‘kartal’ koruyucu ruhu ve adaleti
                temsil ederdi. Biz ‘kartal’ı bu hali ile benimsedik. Ve yerin,
                göğün birbiri ile kaynaştığı kartal hercümerci içinde koruyucu
                ve adil olan kartal olmayı seçtik. Bununla hedef kitleye
                vaadimiz, zihinlerinizde bir karmaşaya sürüklemeden,
                kalplerinizde bir şüpheye mahal vermeden, çıktıları ile yolda
                pozitif itki oluşturabileceğiniz eğitimler. Kendinize yatırım
                yapma fırsatı. Geleceğinize yatırım yapma fırsatı. Bize ve tüm
                ilgililere hayırlı, uğurlu olsun.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
