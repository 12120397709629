import React from "react";

export default function VideoComponent({ currentResource }) {
  console.log(currentResource.video);
  return (
    <iframe
      title={currentResource.title}
      src={currentResource.video?.url}
      className="w-full min-h-96 h-[80vh]"
    ></iframe>
  );
}
