import React from "react";
import { Outlet } from "react-router-dom";
import Nav from "./Nav";
import Footer from "@/components/core/Footer";

const MainLayout = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white  relative">
      {/* Header */}
      <Nav />

      {/* Main Content */}
      <main className="flex-grow mt-24">
        <Outlet></Outlet>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default MainLayout;
