import { CategoryData } from "@/data/dtos/category.dto";
import axios from "axios";

const create = async (payload: any) => {
  console.log(payload);
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/categories",
    payload
  );
  return response;
};

const getAll = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/categories"
  );

  return response;
};

const get = async (id: any) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/categories/" + id
  );
  return response;
};

const update = async (id: string, payload: CategoryData) => {
  console.log(id);
  const response = await axios.patch(
    process.env.REACT_APP_API_BASE_URL + "/categories/" + id,
    payload
  );
  return response;
};

const remove = async (id: any) => {
  const response = await axios.delete(
    process.env.REACT_APP_API_BASE_URL + "/categories/" + id
  );
  return response;
};

const categoriesService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default categoriesService;
