import React from "react";
import { TextField, Button, Box } from "@mui/material";
import { SectionDto } from "@/data/dtos/section.dto";

interface SectionsResourcesProps {
  sections: Array<SectionDto>;
  onChange: (field: string, value: any) => void;
}

const Sections: React.FC<SectionsResourcesProps> = ({ sections, onChange }) => {
  const addNewSection = () => {
    onChange("sections", [
      ...sections,
      { title: "", description: "", order: sections.length + 1, resources: [] },
    ]);
  };

  return (
    <Box>
      {sections.map((section, sectionIndex) => (
        <Box key={sectionIndex} sx={{ marginBottom: 4 }}>
          <TextField
            label="Bölüm Başlığı"
            fullWidth
            margin="normal"
            value={section.title}
            onChange={(e) => {
              const updatedSections = [...sections];
              updatedSections[sectionIndex].title = e.target.value;
              onChange("sections", updatedSections);
            }}
          />
          <TextField
            label="Bölüm Açıklması"
            fullWidth
            margin="normal"
            value={section.description}
            multiline
            onChange={(e) => {
              const updatedSections = [...sections];
              updatedSections[sectionIndex].description = e.target.value;
              onChange("sections", updatedSections);
            }}
          />
          <TextField
            label="Bölüm Sırası"
            fullWidth
            margin="normal"
            type="number"
            value={section.order}
            onChange={(e) => {
              const updatedSections = [...sections];
              updatedSections[sectionIndex].order = parseInt(
                e.target.value,
                10
              );
              onChange("sections", updatedSections);
            }}
          />
        </Box>
      ))}
      <Button variant="contained" onClick={addNewSection}>
        Yeni Bölüm Ekle
      </Button>
    </Box>
  );
};

export default Sections;
