import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navigation from "./StudentNavigation";
import { UserContext } from "@/context/UserContext";
import { url } from "@/utils";

export default function StudentLayout() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user, error, loading, checkSession } = userContext;

  useEffect(() => {
    console.log("checkSession");
    checkSession?.();
  }, [checkSession]);

  useEffect(() => {
    // Kullanıcı bulunmuyorsa logine yönlendir
    if (!loading && !user) {
      navigate(url("Auth.Login")); // Login sayfasına yönlendirme
    }
  }, [user, loading, navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {userContext.error}</p>;
  }

  if (!user) {
    return (
      <p className="container mx-auto flex justify-center">
        User not found. Redirecting to login...
      </p>
    );
  }
  return (
    <div className="bg-gray-100">
      <Navigation />
      <div>
        <Outlet></Outlet>
      </div>
    </div>
  );
}
