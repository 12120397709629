import UploadSection from "./auth/admin/UploadSection";

const ImageModal = ({ isOpen, onClose }) => {
  function handleImageUpload(field, value) {
    console.log(field, value);
  }

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl p-6 relative max-h-screen overflow-y-auto my-10">
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-red-500 font-bold text-xl hover:text-red-700"
          onClick={onClose}
        >
          ✕
        </button>
        {/* Modal Content */}
        <h2 className="text-lg font-semibold text-gray-800 mb-4">
          Resim Yükle
        </h2>
        <div className="space-y-4">
          <UploadSection
            images={[]}
            title="Slider Resimleri"
            onChange={handleImageUpload}
          />
        </div>
        <div className="mt-4 flex justify-end">
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            onClick={onClose}
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
