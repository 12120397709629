import React, { useEffect } from "react";
import Table from "../../../../../components/core/Table";
import { useDispatch, useSelector } from "react-redux";
import { getAll, remove } from "../../../../../actions/courses.actions";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../../../../utils";

export default function AllCourses() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const allCourses = useSelector((state: any) => state.courses);

  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  useEffect(() => {
    console.log("useEffect 1");
    if (allCourses.listStatus === "idle") {
      dispatch(getAll() as any);
    }
  }, [dispatch, allCourses]);

  if (allCourses.courses.length > 0) {
    return (
      <div>
        <Table
          data={allCourses.courses}
          pageSizeOptions={[25, 50, 100]}
          whitelist={[
            "title",
            "level",
            "price",
            "status",
            "isOnSale",
            "startDate",
          ]}
          onDelete={(id: string) => handleDelete(id)}
          onEdit={(id: string) => {
            navigation(url("Admin.Courses.Edit", { id }));
          }}
        />
      </div>
    );
  }
  return (
    <div className="text-center p-4 bg-yellow-300  rounded shadow">
      <span className="font-bold text-lg text-gray-600">No Content</span>
      <br />
      <Link to={url("Admin.Courses.Create")}>Create Course</Link>
    </div>
  );
}
