import {
  FORGOT_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_REQUEST,
  RESET_PASSWORD_REQUEST,
} from "./type";
import authService from "../services/auth.service";
import { setError, setMessage } from "./message";

interface LoginPayloadInterface {
  email: string;
  password: string;
  remember: boolean;
}

interface RegisterPayloadInterface {
  email: string;
  password: string;
  name: string;
  role: string;
}

export const login =
  (payload: LoginPayloadInterface) => async (dispatch: any) => {
    dispatch({ type: LOGIN_REQUEST });

    await authService.login(payload).then(
      (response) => {
        dispatch({ type: LOGIN_SUCCESS, payload: response.data });
        dispatch(setMessage(response.data.message, 5000));

        return Promise.resolve();
      },
      (error) => {
        const message = error.response.data;
        dispatch({ type: "LOGIN_FAIL" });
        dispatch(setError(message));
        return Promise.reject();
      }
    );
  };

export const register =
  (payload: RegisterPayloadInterface) => async (dispatch: any) => {
    dispatch({ type: REGISTER_REQUEST });

    await authService.register(payload).then(
      (response) => {
        dispatch({ type: "REGISTER_SUCCESS", payload: response.data });
        dispatch(setMessage(response.data.message, 5000));
        console.log(response.data);
        return Promise.resolve();
      },
      (error) => {
        const message = error.response.data;
        dispatch({ type: "REGISTER_FAIL" });
        dispatch(setError(message));
        return Promise.reject();
      }
    );
  };

export const forgotPassword = (payload: any) => async (dispatch: any) => {
  dispatch({
    type: FORGOT_PASSWORD_REQUEST,
  });
  await authService
    .forgotPassword(payload)
    .then((response) => {
      dispatch({ type: "FORGOT_PASSWORD_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve();
    })
    .catch((error) => {
      const message = error.response.data;
      dispatch({ type: "FORGOT_PASSWORD_FAIL" });
      dispatch(setError(message));
      return Promise.reject();
    });
};

export const resetPassword = (payload: any) => async (dispatch: any) => {
  dispatch({
    type: RESET_PASSWORD_REQUEST,
  });
  await authService
    .resetPassword(payload)
    .then((response) => {
      console.log(response);
      dispatch({ type: "RESET_PASSWORD_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve();
    })
    .catch((error) => {
      console.log(error);
      const message = error.response.data;
      console.log(message);
      dispatch({ type: "RESET_PASSWORD_FAIL" });
      dispatch(setError(message));
      return Promise.reject();
    });
};

export const updateState = (token: string) => async (dispatch: any) => {
  await authService
    .updateState(token)
    .then((response) => {
      console.log(response);
      dispatch({
        type: "UPDATE_STATE",
        payload: response.data,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const logout = () => (dispatch: any) => {
  authService.logout();

  dispatch({
    type: LOGOUT,
  });
  dispatch(setMessage("You have been logged out", 5000));
};
