// src/components/PrivateRoute.tsx
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

interface PrivateRouteProps {
  readonly children: React.ReactNode;
  readonly role: string; // Gerekli rol
}

const PrivateRoute = ({ children, role }: PrivateRouteProps) => {
  const { user } = useSelector((state: any) => state.auth); // Redux'tan kullanıcı bilgisi
  const location = useLocation();

  if (!user) {
    // Kullanıcı giriş yapmamışsa, login sayfasına yönlendir
    return <Navigate to="/auth/login" state={{ from: location.pathname }} />;
  }

  if (Array.isArray(user.roles)) {
    // Eğer "admin" rolüne sahipse, bileşeni doğrudan döndür
    if (user.roles.includes("admin")) {
      return <>{children}</>;
    }

    // Eğer kullanıcı gerekli role sahip değilse, 403 sayfasına yönlendir
    if (!user.roles.includes(role)) {
      return <Navigate to="/403" state={{ from: location.pathname }} />;
    }
  } else {
    if (user.role === "admin") {
      return <>{children}</>;
    }
    // Eğer user.roles bir dizi değilse, eski role kontrolünü kullan
    if (user.role !== role) {
      return <Navigate to="/403" state={{ from: location.pathname }} />;
    }
  }

  // Tüm kontroller geçilirse bileşeni render et
  return <>{children}</>;
};

export default PrivateRoute;
