import { useLocationContext } from "@/context/LocationContext";
import React from "react";
import { generatePath, Link } from "react-router-dom";

const NotFound = () => {
  const { previousPath, currentPath } = useLocationContext();
  console.log(currentPath);
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>404 - Sayfa Bulunamadı</h1>
      <p>Aradığınız sayfa mevcut değil.</p>
      <Link to={generatePath(previousPath)}>Geri Dön</Link>
    </div>
  );
};

export default NotFound;
