import { combineReducers } from "@reduxjs/toolkit";
import counterReducer from "./counter";
import authReducer from "./auth";
import messageReducer from "./message";
import categoryReducers from "./categories.reducer";
import coursesReducer from "./courses.reducer";
import cartReducer from "./cart.reducer";
import orderReducer from "./order.reducer";
import imageReducer from "./image.reducer";
import uploadReducer from "./file.reducer";
import slidersReducer from "./slider.reducer";
import usersReducer from "./users.reducer";
import addressReducer from "./address.reducer";
import metricsReducer from "./metrics.reducers";

const rootReducer = combineReducers({
  // auth
  auth: authReducer,
  message: messageReducer,

  // other reducers
  counter: counterReducer,
  categories: categoryReducers,
  courses: coursesReducer,
  cart: cartReducer,
  order: orderReducer,
  images: imageReducer,
  uploads: uploadReducer,
  sliders: slidersReducer,
  users: usersReducer,
  addresses: addressReducer,
  metrics: metricsReducer,
});

export default rootReducer;
