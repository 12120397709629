import { UserData } from "@/data/dtos/user.dto";
import userService from "@/services/user.service";
import { setError, setMessage } from "./message";
import {
  CREATE_USER_REQUEST,
  UPDATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  FETCH_USERS_FAILED,
  FETCH_USERS_SUCCESS,
  REMOVE_USER_FAILED,
  REMOVE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCESS,
  FETCH_USERS_REQUEST,
  FETCH_USER_REQUEST,
} from "./type";
import { ChangePasswordDto } from "@/data/dtos/change-password.dto";
import { url } from "@/utils";
import { useNavigate } from "react-router-dom";
import { logout } from "./authAction";

export const create = (payload: UserData) => async (dispatch: any) => {
  dispatch({ type: CREATE_USER_REQUEST });
  await userService
    .create(payload)
    .then((response: any) => {
      console.log(response);
      dispatch({ type: CREATE_USER_SUCCESS, payload: response.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: CREATE_USER_FAILED });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const getAll = () => async (dispatch: any) => {
  dispatch({ type: FETCH_USERS_REQUEST });
  await userService
    .getAll()
    .then((response: any) => {
      console.log(response);
      dispatch({ type: FETCH_USERS_SUCCESS, payload: response.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: FETCH_USERS_FAILED });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const get = (id: string) => async (dispatch: any) => {
  dispatch({ type: FETCH_USER_REQUEST });
  await userService
    .get(id)
    .then((response: any) => {
      dispatch({ type: FETCH_USER_SUCCESS, payload: response });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: FETCH_USER_FAILED });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const update =
  (id: string, payload: UserData) => async (dispatch: any) => {
    dispatch({ type: UPDATE_USER_REQUEST });
    await userService
      .update(id, payload)
      .then((response: any) => {
        console.log(response);
        dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data });
        dispatch(setMessage(response.data.message, 5000));
        return Promise.resolve(response);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_USER_FAILED });
        dispatch(setError(err.response.data));
        return Promise.reject(new Error(err));
      });
  };

export const changePassword =
  (id: string, payload: ChangePasswordDto) => async (dispatch: any) => {
    dispatch({ type: UPDATE_USER_REQUEST });
    await userService
      .changePassword(id, payload)
      .then((response: any) => {
        dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data });
        dispatch(setMessage(response.data.message, 5000));
        dispatch(logout());

        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: UPDATE_USER_FAILED });
        dispatch(setError(err.response.data));
      });
  };

export const remove = (id: string) => async (dispatch: any) => {
  dispatch({ type: CREATE_USER_REQUEST });
  await userService
    .remove(id)
    .then((response: any) => {
      dispatch({ type: REMOVE_USER_SUCCESS, payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: REMOVE_USER_FAILED });
      dispatch(setError(err.response?.data));
      return Promise.reject(new Error(err));
    });
};
