import { ReviewDto } from "@/data/dtos/review.dto";
import { UserData } from "@/data/dtos/user.dto";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Reviews({ reviews }: { reviews: any }) {
  const [mostLikedReview, setMostLikedReview] = useState<ReviewDto | null>(
    null
  );
  const [ratingsDistribution, setRatingsDistribution] = useState({
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  });
  const totalReviews = reviews?.length;
  const [isReviewsOpen, setIsReviewsOpen] = useState(false);

  useEffect(() => {
    if (reviews?.length > 0) {
      const sortedReviews = [...reviews].sort(
        (a, b) => b.likeAndDislike.like - a.likeAndDislike.like
      );
      setMostLikedReview(sortedReviews[0]);
    }
  }, [reviews]);

  useEffect(() => {
    if (reviews?.length > 0) {
      const distribution = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
      reviews?.forEach((review) => {
        distribution[review.rating] = (distribution[review.rating] || 0) + 1;
      });
      setRatingsDistribution(distribution);
    }
  }, [reviews]);

  return (
    <section className="py-12 relative bg-white rounded shadow my-4">
      <div className="w-full max-w-7xl px-4 md:px-5 lg:px-6 mx-auto">
        <div className="">
          <h2 className="font-manrope font-bold text-3xl sm:text-4xl leading-10 text-black mb-8 text-center">
            Customer reviews & rating
          </h2>
          <div className="grid grid-cols-12 mb-11">
            <div className="col-span-3 xl:col-span-4 flex items-center">
              <div className="box flex flex-col gap-y-4 w-full max-xl:max-w-3xl mx-auto">
                {Object.keys(ratingsDistribution)
                  .sort((a, b) => Number(b) - Number(a))
                  .map((rating) => {
                    const count = ratingsDistribution[Number(rating)];
                    const percentage = totalReviews
                      ? Math.round((count / totalReviews) * 100)
                      : 0;
                    return (
                      <div key={rating} className="flex items-center w-full">
                        <p className="font-medium text-lg py-[1px] text-black mr-[2px]">
                          {rating}
                        </p>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_12042_8589)">
                            <path
                              d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                              fill="#FBBF24"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_12042_8589">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <p className="h-2 w-full sm:min-w-[278px] rounded-[30px] bg-gray-200 ml-5 mr-3">
                          <span
                            className="h-full rounded-[30px] bg-indigo-500 flex"
                            style={{ width: `${percentage}%` }}
                          ></span>
                        </p>
                        <p className="font-medium text-lg py-[1px] text-black mr-[2px]">
                          {count}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-span-3 max-xl:mt-8 xl:col-span-8 xl:pl-8 w-full min-h-[230px]">
              {mostLikedReview && (
                <div className="pb-8 border-b border-gray-200 max-xl:max-w-3xl max-xl:mx-auto">
                  <h4 className="font-manrope font-semibold text-3xl leading-10 text-black mb-6">
                    Most Helpful Review
                  </h4>
                  <div className="flex sm:items-center flex-col sm:flex-row justify-between mb-4">
                    <div className="flex items-center gap-3">
                      <h6 className="font-semibold text-lg leading-8 text-black">
                        @{(mostLikedReview.user as UserData).name}
                      </h6>
                      <p className="font-medium text-base leading-7 text-gray-400">
                        {new Date(mostLikedReview.date!).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <p className="font-normal text-lg leading-8 text-gray-500">
                    {mostLikedReview.comment}
                  </p>
                  <div className="flex items-center mt-4 gap-4">
                    <span className="text-green-600 font-semibold">
                      👍 {mostLikedReview.likeAndDislike.like}
                    </span>
                    <span className="text-red-600 font-semibold">
                      👎 {mostLikedReview.likeAndDislike.dislike}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-7xl px-4 md:px-5 lg:px-6 mx-auto">
        <div className="flex flex-col sm:flex-row items-center justify-between pt-8  max-xl:max-w-3xl max-xl:mx-auto border-b">
          <p className="font-normal text-lg py-[1px] text-black">
            {reviews?.length} reviews
          </p>
          <button
            className="font-semibold text-base leading-7 text-indigo-600"
            onClick={() => setIsReviewsOpen(!isReviewsOpen)}
          >
            Display All
          </button>
        </div>
        {isReviewsOpen &&
          reviews?.map((review) => (
            <div
              key={review._id}
              className="pb-8 border-b border-gray-200 max-xl:max-w-3xl max-xl:mx-auto"
            >
              <div className="flex sm:items-center flex-col sm:flex-row justify-between mb-4">
                <div className="flex items-center gap-3">
                  <h6 className="font-semibold text-lg leading-8 text-black">
                    @{(review.user as UserData).name}
                  </h6>
                  <p className="font-medium text-base leading-7 text-gray-400">
                    {new Date(review?.date!).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <p className="font-normal text-lg leading-8 text-gray-500">
                {review.comment}
              </p>
              <div className="flex items-center mt-4 gap-4">
                <span className="text-green-600 font-semibold">
                  👍 {review.likeAndDislike.like}
                </span>
                <span className="text-red-600 font-semibold">
                  👎 {review.likeAndDislike.dislike}
                </span>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}
