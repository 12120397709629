import React from "react";
import { url } from "@/utils";
import { Link } from "react-router-dom";

export default function CourseCard({ course }) {
  return (
    <div className="max-w-80 bg-white shadow rounded hover:shadow-lg hover:cursor-pointer hover:scale-105 ease-out transition-all duration-300">
      <Link to={url("Profile.Course", { id: course._id })}>
        <div
          className="h-48 w-full bg-gray-200 flex flex-col justify-between p-4 bg-cover bg-center"
          style={{
            backgroundImage: `url("${course.images[0].url}")`,
          }}
        >
          <div className="flex justify-between">
            <input type="checkbox" />
          </div>
        </div>
        <div className="p-4 flex flex-col items-center">
          <p className="text-gray-400 font-light text-xs text-center grid grid-cols-2 gap-2">
            {course.instructors?.map((instructor, index) => (
              <span className="flex gap-4" key={index}>
                {instructor.name}
              </span>
            ))}
          </p>
          <h1 className="text-gray-800 text-center mt-1">{course.title}</h1>
          <div className="flex w-full items-center justify-between gap-4">
            <span> Progress</span>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{
                  width: `${course.progress}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
