import { clearCart } from "@/actions/cart.actions";
import { url } from "@/utils";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearCart() as any);
    setTimeout(() => {
      navigate(url("Profile.My-Courses"));
    }, 3000);
  }, [navigate, dispatch]);

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-8 text-center rounded shadow flex flex-col h-[60vh]">
        <h1 className="text-2xl font-bold mb-4 text-green-600 uppercase font-sans">
          Ödeme Başarılı
        </h1>
        <div className="rounded-full mb-4 w-36 h-36 border-8 border-green-600 mx-auto flex justify-center text-center items-center">
          <FontAwesomeIcon icon={faCheck} className="text-green-600 text-7xl" />
        </div>
        <span>Kurslarınıza Yönlendiriliyorsunuz...</span>
      </div>
    </div>
  );
}
