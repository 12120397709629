import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { url } from "@/utils";

export default function NavItem({
  path,
  name,
}: {
  readonly path: string;
  readonly name: string;
}) {
  return (
    <li className="transform hover:scale-x-110 transition-transform text-base">
      <NavLink
        className={({ isActive }) =>
          isActive
            ? " text-orange-50 font-bold  h-20 flex items-center"
            : "h-20 flex items-center hover:text-orange-50 hover:font-bold text-secondary"
        }
        to={path}
      >
        {name}
      </NavLink>
    </li>
  );
}
