import {
  FILE_UPLOADING_STARTED,
  UPLOAD_FILE_FAILED,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from "../actions/type";

interface UploadProgressState {
  bytes: number;
  estimated: number;
  lengthComputable: boolean;
  loaded: number;
  progress: number;
  rate: number;
  total: number;
  upload: true;
  status: string;
  URL: string;
}

const initialState: UploadProgressState = {
  bytes: 0,
  estimated: 0,
  lengthComputable: true,
  loaded: 0,
  progress: 0,
  rate: 0,
  total: 0,
  upload: true,
  status: "idle",
  URL: "",
};

export default function uploadReducer(
  state = initialState,
  action: any
): UploadProgressState {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        status: "started",
      };

    case FILE_UPLOADING_STARTED:
      return {
        ...state,
        progress: payload.progress,
        loaded: payload.loaded,
        total: payload.total,
        status: "uploading",
      };

    case UPLOAD_FILE_SUCCESS:
      console.log(payload);
      return {
        ...state,
        URL: payload.data.URL,
        status: "success",
      };

    case UPLOAD_FILE_FAILED:
      return {
        ...state,
        status: "failed",
      };

    default:
      return state;
  }
}
