import {
  CreateOrderInterface,
  UpdateOrderInterface,
} from "@/data/interfaces/OrderInterface";
import orderService from "../services/orders.service";
import {
  CREATE_ORDER_FAILED,
  CREATE_ORDER_SUCCESS,
  FETCH_ORDER,
  FETCH_ORDER_FAILED,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDERS,
  FETCH_ORDERS_FAILED,
  FETCH_ORDERS_SUCCESS,
  REMOVE_ORDER_FAILED,
  REMOVE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILED,
  UPDATE_ORDER_SUCCESS,
} from "./type";
import { setMessage } from "./message";

export const create =
  (payload: CreateOrderInterface) => async (dispatch: any) => {
    await orderService
      .create(payload)
      .then((response: any) => {
        console.log(response);
        dispatch({ type: CREATE_ORDER_SUCCESS, payload: response.data });
        dispatch(setMessage(response.data.message, 5000));
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: CREATE_ORDER_FAILED });
        return Promise.reject(new Error(err.toString()));
      });
  };

export const getAll = () => async (dispatch: any) => {
  dispatch({ type: FETCH_ORDERS });
  await orderService
    .getAll()
    .then((response: any) => {
      console.log(response);
      dispatch({ type: FETCH_ORDERS_SUCCESS, payload: response.data });

      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: FETCH_ORDERS_FAILED });
      // dispatch(setError(err.response.data));
      return Promise.reject(new Error(err.toString()));
    });
};

export const get = (id: string) => async (dispatch: any) => {
  dispatch({ type: FETCH_ORDER });
  await orderService
    .get(id)
    .then((response: any) => {
      console.log(response);
      dispatch({ type: FETCH_ORDER_SUCCESS, payload: response.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: FETCH_ORDER_FAILED });
      // dispatch(setError(err.response.data));
      return Promise.reject(new Error(err.toString()));
    });
};

export const update =
  (id: string, payload: UpdateOrderInterface) => async (dispatch: any) => {
    await orderService
      .update(id, payload)
      .then((response: any) => {
        console.log(response);
        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: response.data });
        dispatch(setMessage(response.data.message, 5000));
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: UPDATE_ORDER_FAILED });
        // dispatch(setError(err.response.data));
        return Promise.reject(new Error(err.toString()));
      });
  };

export const remove = (id: string) => async (dispatch: any) => {
  await orderService
    .remove(id)
    .then((response: any) => {
      console.log(response);
      dispatch({ type: REMOVE_ORDER_SUCCESS, payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: REMOVE_ORDER_FAILED });
      // dispatch(setError(err.response.data));
      return Promise.reject(new Error(err.toString()));
    });
};
