import { clearCart } from "@/actions/cart.actions";
import { url } from "@/utils";
import { faCheck, faCross } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message } = useSelector((state: any) => state.message);
  useEffect(() => {
    setTimeout(() => {
      navigate(url("Cart"));
    }, 5000);
  }, [navigate, dispatch]);

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-8 text-center rounded shadow flex flex-col h-[60vh]">
        <h1 className="text-2xl font-bold mb-4 text-red-600 uppercase font-sans">
          Ödeme Başarısız
        </h1>
        <div className="rounded-full mb-4 w-36 h-36 border-8 border-red-600 mx-auto flex justify-center text-center items-center">
          <FontAwesomeIcon icon={faCross} className="text-red-600 text-7xl" />
        </div>
        <span>Sepetinize Yönlendiriliyorsunuz...</span>
        <span>{message?.message ?? ""}</span>
      </div>
    </div>
  );
}
