import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login, register } from "../../../../actions/authAction";
import InputLabel from "../../../../components/InputLabel";
import TextInput from "../../../../components/core/TextInput";
import InputError from "../../../../components/core/InputError";
import PrimaryButton from "../../../../components/PrimaryButton";
import { url } from "../../../../utils";

export default function Register() {
  let navigate = useNavigate();

  const { message } = useSelector((state: any) => state.message);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onChangeEmail = (e: any) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangeName = (e: any) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangePassword = (e: any) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleRegister = (e: any) => {
    e.preventDefault();
    setLoading(true);

    dispatch(register({ name, email, password, role: "student" }) as any)
      .then((res: any) => {
        dispatch(login({ email, password, remember: false }) as any)
          .then((res: any) => {
            navigate("/");
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="rounded w-full border-slate-100 dark:text-slate-100 grid grid-cols-1 gap-4">
      Kayıt Ol
      {message?.message && (
        <div className="flex flex-col gap-4 rounded bg-red-500 p-4">
          {message.message}
        </div>
      )}
      <div>
        <InputLabel htmlFor="name" value="Adınız" />
        <TextInput
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={onChangeName}
          className="mt-1 block w-full"
          isFocused={true}
          autoComplete="name"
        />
        <InputError messages={message?.errors?.name} className="mt-2" />
      </div>
      <div>
        <InputLabel htmlFor="email" value="E-posta" />
        <TextInput
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={onChangeEmail}
          className="mt-1 block w-full"
          autoComplete="email"
        />
        <InputError messages={message?.errors?.email} className="mt-2" />
      </div>
      <div>
        <InputLabel htmlFor="password" value="Sifre" />
        <TextInput
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={onChangePassword}
          className="mt-1 block w-full"
          autoComplete="password"
        />
        <InputError messages={message?.errors?.password} className="mt-2" />
      </div>
      <div className="flex justify-between items-center">
        <PrimaryButton
          disabled={loading}
          onClick={handleRegister}
          className="ms-4"
        >
          Kayıt Ol
        </PrimaryButton>

        <Link
          to={url("Auth.Login")}
          className="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
        >
          Already have an account?
        </Link>
      </div>
    </div>
  );
}
