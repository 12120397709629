import {
  CREATE_USER_REQUEST,
  UPDATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  FETCH_USERS_FAILED,
  FETCH_USERS_SUCCESS,
  REMOVE_USER_FAILED,
  REMOVE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCESS,
  FETCH_USERS_REQUEST,
  FETCH_USER_REQUEST,
} from "@/actions/type";

interface UsersState {
  users: any[];
  currentUser: any;
  status: string;
  listStatus: string;
}

const initialState: UsersState = {
  users: [],
  currentUser: null,
  status: "idle",
  listStatus: "idle",
};

export default function usersReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_USER_REQUEST:
      return { ...state, status: "loading" };

    case CREATE_USER_SUCCESS:
      return { ...state, currentUser: payload, status: "success" };

    case FETCH_USERS_REQUEST:
      return { ...state, listStatus: "loading" };

    case FETCH_USERS_SUCCESS:
      return { ...state, users: payload.data, listStatus: "success" };

    case FETCH_USERS_FAILED:
      return { ...state, listStatus: "failed" };

    case UPDATE_USER_REQUEST:
      return { ...state, status: "loading" };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        currentUser: payload,
        users: state.users.map((user: any) =>
          user._id === payload._id ? payload : user
        ),
        status: "success",
      };

    case REMOVE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user: any) => user._id !== payload._id),
        status: "success",
      };

    case FETCH_USER_REQUEST:
      return { ...state, status: "loading" };

    case FETCH_USER_SUCCESS:
      console.log(payload);
      return { ...state, currentUser: payload.data, status: "success" };

    case REMOVE_USER_FAILED:
    case FETCH_USER_FAILED:
    case UPDATE_USER_FAILED:
    case CREATE_USER_FAILED:
      return { ...state, status: "failed" };

    default:
      return state;
  }
}
