interface AddressState {
  addresses: any[];
  currentAddress: any;
  status: string;
}

const initialState: AddressState = {
  addresses: [],
  currentAddress: null,
  status: "idle",
};

export default function addressReducer(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case "FETCH_ADDRESSES_SUCCESS":
      return {
        ...state,
        addresses: payload,
        status: "success",
      };
    case "FETCH_ADDRESS_SUCCESS":
      return {
        ...state,
        currentAddress: payload,
        status: "success",
      };
    case "CREATE_ADDRESS_SUCCESS":
      return {
        ...state,
        addresses: [...state.addresses, payload],
        currentAddress: payload,
        status: "success",
      };

    case "UPDATE_ADDRESS_SUCCESS":
      return {
        ...state,
        addresses: state.addresses.map((address: any) =>
          address._id === payload._id ? payload : address
        ),
        currentAddress: payload,
        status: "success",
      };

    case "DELETE_ADDRESS_SUCCESS":
      return {
        ...state,
        addresses: state.addresses.filter(
          (address: any) => address._id !== payload._id
        ),
        currentAddress: null,
        status: "success",
      };

    case "CREATE_ADDRESS_FAIL":
    case "UPDATE_ADDRESS_FAILED":
    case "DELETE_ADDRESS_FAILED":
    case "FETCH_ADDRESSES_FAILED":
    case "FETCH_ADDRESS_FAILED":
      return {
        ...state,
        status: "failed",
      };
    default:
      return state;
  }
}
