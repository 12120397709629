import { ReviewDto } from "@/data/dtos/review.dto";
import axios from "axios";

const create = async (payload: any) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/courses",
    payload
  );
  console.log(response);
  return response;
};

const getAll = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/courses"
  );
  console.log(response);
  return response;
};

const get = async (id: any) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/courses/" + id
  );
  console.log(response);
  return response;
};

const getByInstructer = async (id: any) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/courses/instructor/" + id
  );
  return response;
};

const getBySlug = async (slug: any) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/courses/slug/" + slug
  );
  return response;
};

const update = async (id: string, payload: any) => {
  const response = await axios.patch(
    process.env.REACT_APP_API_BASE_URL + "/courses/" + id,
    payload
  );
  console.log(response);
  return response;
};

const remove = async (id: any) => {
  const response = await axios.delete(
    process.env.REACT_APP_API_BASE_URL + "/courses/" + id
  );
  return response;
};

const filter = async (payload: any) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/courses/filter",
    payload
  );
  console.log(response);
  return response.data;
};

const sales = async () => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/courses/sales"
  );
  console.log(response);
  return response;
};

const addSection = async (payload: any) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/sections",
    payload
  );

  return response;
};

const updateSection = async (id: string, payload: any) => {
  const response = await axios.patch(
    process.env.REACT_APP_API_BASE_URL + "/sections/" + id,
    payload
  );
  return response;
};

const removeSection = async (id: any) => {
  const response = await axios.delete(
    process.env.REACT_APP_API_BASE_URL + "/sections/" + id
  );
  return response;
};

const addResource = async (payload: any) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/resources",
    payload
  );
  return response;
};

const updateResource = async (id: string, payload: any) => {
  const response = await axios.patch(
    process.env.REACT_APP_API_BASE_URL + "/resources/" + id,
    payload
  );
  return response;
};

const removeResource = async (id: any) => {
  const response = await axios.delete(
    process.env.REACT_APP_API_BASE_URL + "/resources/" + id
  );
  return response;
};

export const uploadFile = async (
  payload: FormData,
  onUploadProgress: (progressEvent: any) => void
) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/resources/upload-file",
    payload,
    {
      onUploadProgress,
    }
  );
  return response;
};

export const uploadVideo = async (
  payload: any,
  onUploadProgress: (progressEvent: any) => void
) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/videos/upload",
    payload,
    {
      onUploadProgress,
    }
  );
  return response;
};

export const addReview = (id: string, review: ReviewDto) => {
  return axios.patch(
    process.env.REACT_APP_API_BASE_URL + "/courses/review/" + id,
    review
  );
};

const coursesService = {
  create,
  getAll,
  get,
  update,
  remove,
  filter,
  addSection,
  updateSection,
  removeSection,
  addResource,
  updateResource,
  removeResource,
  uploadFile,
  uploadVideo,
  sales,
  getBySlug,
  getByInstructer,
  addReview,
};

export default coursesService;
