import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../actions/authAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faFolder,
  faGear,
  faGraduationCap,
  faImages,
  faList,
  faPieChart,
  faPlus,
  faPowerOff,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Accordion from "./AdminAccordion";
import { url } from "@/utils";
import MenuItems from "./core/MenuItems";

export default function AdminSideMenu() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { user } = useSelector((state: any) => state.auth);
  const [isOpen, setIsOpen] = React.useState(false);
  console.log(user.role);
  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.setItem("rememberMe", "false");
    dispatch(logout() as any);
    navigator("/");
  };

  return (
    <div className="relative">
      <div
        className={`min-h-screen h-auto bg-white w-full md:w-56 fixed md:static transform z-50 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 transition-transform duration-300 ease-in-out`}
      >
        <div className="p-4">
          <ul className="flex flex-col">
            <MenuItems
              title="Dashboard"
              icon={faPieChart}
              to={url("Admin.Dashboard")}
            />

            {/* Course section */}
            {(user!.role === "teacher" || user!.role === "admin") && (
              <div>
                <Accordion title="Kurslar">
                  <MenuItems
                    to={url("Admin.Courses.Create")}
                    title="Kurs Oluştur"
                    icon={faGraduationCap}
                  />
                  <MenuItems
                    to={url("Admin.Courses")}
                    title="Oluşturulan Kurslar"
                    icon={faList}
                  />

                  {user!.role === "admin" && (
                    <MenuItems
                      to={url("Admin.Courses.Course-List")}
                      title="Kurslar"
                      icon={faList}
                    />
                  )}
                </Accordion>
              </div>
            )}

            {/* Settings section */}
            {user!.role === "admin" && (
              <div>
                <Accordion title="Ayarlar">
                  <MenuItems
                    title="Genel Ayarlar"
                    icon={faGear}
                    to={url("Admin.Site-Settings.General-Settings")}
                  />
                </Accordion>
                <Accordion title="Kategoriler">
                  <MenuItems
                    title="Kategori Listesi"
                    icon={faList}
                    to={url("Admin.Categories")}
                  />
                  <MenuItems
                    title="Kategori Ekle"
                    icon={faPlus}
                    to={url("Admin.Categories.Create")}
                  />
                </Accordion>
                <Accordion title="Kullanıcılar">
                  <MenuItems
                    title="Kullanıcı Listesi"
                    icon={faUsers}
                    to={url("Admin.Users")}
                  />
                  <MenuItems
                    title="Kullanıcı Oluştur"
                    icon={faPlus}
                    to={url("Admin.Users.Create")}
                  />
                </Accordion>
                <Accordion title="Afiş">
                  <MenuItems
                    title="Afiş Listesi"
                    icon={faImages}
                    to={url("Admin.Sliders")}
                  />
                  <MenuItems
                    title="Afiş Oluştur"
                    icon={faPlus}
                    to={url("Admin.Sliders.Create")}
                  />
                </Accordion>
                <Accordion title="Siparişler">
                  <MenuItems
                    title="Sipariş Listesi"
                    icon={faImages}
                    to={url("Admin.Orders")}
                  />
                  <MenuItems
                    title="Sipariş Oluştur"
                    icon={faPlus}
                    to={url("Admin.Orders.Create")}
                  />
                </Accordion>
                <MenuItems
                  title="İçerikler"
                  icon={faFolder}
                  to={url("Admin.Contents")}
                />
              </div>
            )}

            {/* Account section */}
            <div>
              <Accordion title="Hesap">
                <MenuItems title="Profil" icon={faUser} to={url("Profile")} />
                {/* <MenuItems title="Bildirimler" icon={faBell} to="#" /> */}
                <MenuItems
                  title="Çıkış Yap"
                  icon={faPowerOff}
                  to="#"
                  onClick={handleLogout}
                />
              </Accordion>
            </div>
          </ul>
        </div>
      </div>
      <button
        className="block md:hidden  fixed top-4 left-4 text-gray-400 bg-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={faList} />
      </button>
    </div>
  );
}
