// Accordion.tsx
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

interface AccordionProps {
  readonly title: string;
  readonly children: React.ReactNode;
}

export default function AdminAccordion({ title, children }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        className="my-px flex justify-between w-full cursor-pointer items-center text-gray-400 hover:text-gray-600"
        onClick={toggleAccordion}
      >
        <span className="flex font-medium text-sm px-4 my-4 uppercase ">
          {title}
        </span>
        <span>
          {isOpen ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </span>
      </button>
      {isOpen && (
        <div className="ml-4 border-s border-b border-e rounded-b-xl">
          {children}
        </div>
      )}
    </div>
  );
}
