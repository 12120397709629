import {
  CREATE_SLIDER_FAILED,
  CREATE_SLIDER_SUCCESS,
  FETCH_SLIDER_FAILED,
  FETCH_SLIDER_SUCCESS,
  FETCH_SLIDERS_FAILED,
  FETCH_SLIDERS_SUCCESS,
  REMOVE_SLIDER_FAILED,
  REMOVE_SLIDER_SUCCESS,
  SLIDER_REQUEST,
  UPDATE_SLIDER_FAILED,
  UPDATE_SLIDER_SUCCESS,
} from "../actions/type";

interface SlidersState {
  sliders: any[];
  currentSlider: any;
  status: string;
}

const initialState: SlidersState = {
  sliders: [],
  currentSlider: null,
  status: "idle",
};

export default function slidersReducer(
  state = initialState,
  action: any
): SlidersState {
  const { type, payload } = action;

  switch (type) {
    case SLIDER_REQUEST:
      return {
        ...state,
        status: "loading",
      };

    case CREATE_SLIDER_SUCCESS:
      console.log(payload);
      return {
        ...state,
        sliders: [...state.sliders, payload],
        currentSlider: payload,
        status: "success",
      };

    case FETCH_SLIDERS_SUCCESS:
      return {
        ...state,
        sliders: payload,
        status: "success",
      };

    case FETCH_SLIDER_SUCCESS:
      return {
        ...state,
        currentSlider: payload,
        status: "success",
      };

    case UPDATE_SLIDER_SUCCESS:
      return {
        ...state,
        sliders: state.sliders.map((slider: any) => {
          if (slider._id === payload._id) {
            return payload;
          } else {
            return slider;
          }
        }),
        currentSlider: payload,
        status: "success",
      };

    case REMOVE_SLIDER_SUCCESS:
      return {
        ...state,
        sliders: state.sliders.filter((slider: any) => slider._id !== payload),
        status: "success",
      };

    case REMOVE_SLIDER_FAILED:
    case UPDATE_SLIDER_FAILED:
    case FETCH_SLIDERS_FAILED:
    case FETCH_SLIDER_FAILED:
    case CREATE_SLIDER_FAILED:
      return {
        ...state,
        status: "failed",
      };

    default:
      return state;
  }
}
