import React, { useState } from "react";
import routes from "../../Routes";
import NavItem from "./NavItem";

export default function NavList() {
  const [openMenu, setOpenMenu] = useState(null); // Hangi menünün açık olduğunu takip eder

  const handleMouseEnter = (path) => {
    setOpenMenu(path); // Üzerine gelinen menüyü açar
  };

  const handleMouseLeave = () => {
    setOpenMenu(null); // Fare ayrıldığında menüyü kapatır
  };

  return (
    <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
      {routes.map((route) =>
        route.name === "Home"
          ? route?.children?.map((child) => (
              <li
                key={child.path}
                className="relative group"
                onMouseEnter={() => handleMouseEnter(child.path)}
                onMouseLeave={handleMouseLeave}
              >
                <NavItem path={child.path} name={child.title!} />
                {child.children && openMenu === child.path && (
                  <ul className="absolute left-0 top-3/4 mt-2 w-fit bg-white border border-gray-300 shadow-lg rounded-lg">
                    {child.children.map((subChild) => (
                      <li
                        key={subChild.path}
                        className="px-4 py-2 hover:bg-gray-100 block max-w-72"
                      >
                        <NavItem
                          path={child.path + "/" + subChild.path}
                          name={subChild.title!}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))
          : ""
      )}
    </ul>
  );
}
