import { useEffect, useState } from "react";

interface Slide {
  title: string;
  description: string;
  image: { url: string; altText: string };
}

interface SlideShowProps {
  slides: {
    title: string;
    description: string;
    items: Slide[];
  };
  interval?: number;
  id?: string;
}

const SlideShow: React.FC<SlideShowProps> = ({
  slides,
  interval = 3000,
  id,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Slaytlar arasında otomatik geçiş
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.items.length);
    }, interval);

    return () => clearInterval(slideInterval); // Temizleme işlemi
  }, [interval, slides.items.length]);

  // Slaytlar arasında manuel geçiş
  const changeSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div
      id={id}
      className="relative container mx-auto h-[20rem] overflow-hidden bg-gray-100"
    >
      {/* Slayt Gösterimi */}
      <div
        className="absolute inset-0 bg-cover bg-center transition-transform duration-700 ease-in-out"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          width: `${slides.items.length * 100}%`,
          display: "flex",
        }}
      >
        {slides.items.map((slide, index) => (
          <div
            key={index}
            className="w-full flex-shrink-0 bg-cover bg-center"
            style={{
              backgroundImage: `url(${slide.image.url})`,
            }}
          >
            <div className="relative top-10 left-10 p-4 shadow-md">
              {/* Yazı */}
              <div className="z-20 relative opacity-100">
                <h2 className="text-lg font-semibold text-white uppercase">
                  {slide.title}
                </h2>
                <p className="text-base text-white">{slide.description}</p>
              </div>

              {/* Arka plan */}
              <div className="w-fit absolute top-0 left-0 h-full w-full bg-black opacity-30 z-10"></div>
            </div>
          </div>
        ))}
      </div>

      {/* Önceki Slayt Butonu */}
      <button
        onClick={() =>
          changeSlide(
            currentIndex === 0 ? slides.items.length - 1 : currentIndex - 1
          )
        }
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full hover:bg-gray-800 transition"
      >
        ❮
      </button>

      {/* Sonraki Slayt Butonu */}
      <button
        onClick={() => changeSlide((currentIndex + 1) % slides.items.length)}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full hover:bg-gray-800 transition"
      >
        ❯
      </button>

      {/* Dots */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.items.map((_, index) => (
          <button
            key={index}
            onClick={() => changeSlide(index)}
            className={`w-3 h-3 rounded-full cursor-pointer ${
              currentIndex === index
                ? "bg-gray-800"
                : "bg-gray-400 hover:bg-gray-500"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export { SlideShow };
