interface MetricsState {
  metrics: any[];
  status: string;
}

const initialState: MetricsState = {
  metrics: [],
  status: "idle",
};

export default function metricsReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_METRICS_SUCCESS":
      return {
        ...state,
        metrics: payload,
        status: "success",
      };
    case "FETCH_METRICS_FAILED":
      return {
        ...state,
        status: "failed",
      };
    default:
      return state;
  }
}
