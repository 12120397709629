import React, { useRef, useState } from "react";
import TextInput from "../../../../components/core/TextInput";
import InputLabel from "../../../../components/InputLabel";
import InputError from "../../../../components/core/InputError";
import PrimaryButton from "../../../../components/PrimaryButton";
import Checkbox from "../../../../components/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../../actions/authAction";
import { url } from "../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
  let navigate = useNavigate();

  // const form = useRef(); for validation
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(
    localStorage.getItem("rememberMe") === "true" ? true : false
  );
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { message } = useSelector((state: any) => state.message);

  const dispatch = useDispatch();

  const onChangeEmail = (e: any) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e: any) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeRemember = (e: any) => {
    const remember = e.target.checked;
    localStorage.setItem("rememberMe", remember ? "true" : "false");
    setRemember(remember);
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    setLoading(true);

    // form.current.validateAll();

    dispatch(login({ email, password, remember }) as any)
      .then((res: any) => {
        navigate("/");
        // window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  return (
    <div className="rounded w-full border-slate-100 dark:text-slate-100 grid grid-cols-1 gap-4">
      Giriş Yap
      {message?.message && (
        <div className="flex flex-col gap-4 rounded bg-red-500 p-4">
          {message.message}
        </div>
      )}
      <div>
        <InputLabel htmlFor="email" value="Email" />
        <TextInput
          type="email"
          name="email"
          value={email}
          className="mt-1 block w-full"
          autoComplete="true"
          isFocused={true}
          onChange={(e: any) => onChangeEmail(e)}
        />
        <InputError messages={message?.errors?.email} className="mt-2" />
      </div>
      <div>
        <InputLabel htmlFor="password" value="Password" />
        <div className="relative">
          <TextInput
            type={showPassword ? "text" : "password"}
            name="password"
            value={password}
            className="mt-1 block w-full"
            autoComplete="true"
            isFocused={true}
            onChange={(e: any) => onChangePassword(e)}
          />
          <button
            className="absolute right-3 top-1"
            onClick={() => setShowPassword(!showPassword)}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </div>
        <InputError messages={message?.errors?.password} className="mt-2" />
      </div>
      <div>
        <div className="block mt-4">
          <label className="flex items-center">
            <Checkbox
              name="remember"
              checked={remember}
              onChange={(e: any) => onChangeRemember(e)}
              innerRef={checkBtn}
            />
            <span className="ms-2 text-sm text-gray-600 dark:text-gray-400">
              Remember me
            </span>
          </label>
        </div>
      </div>
      <div className="flex items-center justify-end mt-4">
        <Link
          to={url("Auth.Forgot-Password")}
          className="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
        >
          Forgot your password?
        </Link>

        <PrimaryButton
          className="ms-4"
          onClick={handleLogin}
          disabled={loading}
        >
          Giriş Yap
        </PrimaryButton>
      </div>
    </div>
  );
}
