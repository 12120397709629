import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, sales } from "../../../actions/courses.actions";
import { getAll as getAllCategories } from "../../../actions/category.actions";
import Accordion from "../../../components/core/Accordion";
import Slider from "@mui/material/Slider";
import { formatMoney } from "../../../utils";
import { StarRating } from "../../../components/Rating";
import Card from "@/components/core/Card";

export default function Courses() {
  const dispatch = useDispatch();
  const { categories } = useSelector((state: any) => state.categories);
  const { courses } = useSelector((state: any) => state.courses);
  const languages = ["Türkçe", "English"];
  const levels = ["Begginer", "Orta", "İleri"];

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 10000]);
  const [selectedRating, setSelectedRating] = useState<number | null>(null);

  useEffect(() => {
    dispatch(sales() as any);
    dispatch(getAllCategories() as any);
  }, [dispatch]);
  console.log(courses);
  const handleCategoryChange = (categoryId: string) => {
    setSelectedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  const handleLanguageChange = (language: string) => {
    setSelectedLanguages((prev) =>
      prev.includes(language)
        ? prev.filter((lang) => lang !== language)
        : [...prev, language]
    );
  };

  const handleLevelChange = (level: string) => {
    setSelectedLevels((prev) =>
      prev.includes(level)
        ? prev.filter((lvl) => lvl !== level)
        : [...prev, level]
    );
  };

  const handlePriceRangeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    if (Array.isArray(newValue)) {
      setPriceRange([newValue[0], newValue[1]]);
    }
  };

  const handleRatingChange = (rating: number) => {
    setSelectedRating(rating);
  };

  const applyFilters = () => {
    const filters = {
      categories: selectedCategories,
      languages: selectedLanguages,
      levels: selectedLevels,
      priceRange,
      rating: selectedRating,
    };

    console.log(filters);

    dispatch(filter(filters) as any)
      .then((res) => {})
      .catch((err) => {});
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-200 mt-24">
      <div className="flex flex-rows gap-4 pt-6 sm:pt-0 relative container mx-auto bg-gray-50 my-4">
        <div className="w-1/5 h-auto min-h-screen bg-slate-50 p-4">
          <div className="w-full space-y-4">
            {/* Kategoriler */}
            <Accordion title="Kategoriler">
              {categories?.map((category: any) => (
                <div
                  key={category._id}
                  className="border-b p-2 flex gap-4 items-center"
                >
                  <input
                    type="checkbox"
                    name={category.name}
                    id={category._id}
                    onChange={() => handleCategoryChange(category._id)}
                  />
                  <label htmlFor={category._id}>{category.name}</label>
                </div>
              ))}
            </Accordion>

            {/* Diller */}
            <Accordion title="Diller">
              {languages.map((language: string, index: number) => (
                <div
                  key={index}
                  className="border-b p-2 flex gap-4 items-center"
                >
                  <input
                    type="checkbox"
                    name={language}
                    id={language}
                    onChange={() => handleLanguageChange(language)}
                  />
                  <label htmlFor={language}>{language}</label>
                </div>
              ))}
            </Accordion>

            {/* Seviyeler */}
            <Accordion title="Seviyeler">
              {levels.map((level: string, index: number) => (
                <div
                  key={index}
                  className="border-b p-2 flex gap-4 items-center"
                >
                  <input
                    type="checkbox"
                    name={level}
                    id={level}
                    onChange={() => handleLevelChange(level)}
                  />
                  <label htmlFor={level}>{level}</label>
                </div>
              ))}
            </Accordion>

            {/* Fiyat Aralığı */}
            <Accordion title="Fiyat Aralığı">
              <div>
                <Slider
                  getAriaLabel={() => "Minimum distance"}
                  value={priceRange}
                  max={10000}
                  onChange={handlePriceRangeChange}
                  valueLabelDisplay="auto"
                  disableSwap
                />
                <div className="flex justify-between">
                  <span>{formatMoney(priceRange[0])}</span>
                  <span>{formatMoney(priceRange[1])}</span>
                </div>
              </div>
            </Accordion>

            {/* İnceleme Derecelendirmesi */}
            <Accordion title="İncelemeler">
              <StarRating maxStars={5} onRatingSelect={handleRatingChange} />
            </Accordion>

            {/* Filtreleri Uygulama Butonu */}
            <button
              onClick={applyFilters}
              className="bg-blue-500 text-white p-2 rounded"
            >
              Filtreleri Uygula
            </button>
          </div>
        </div>
        <div className="w-full min-h-screen bg-white grid grid-cols-3 gap-4 p-4">
          {courses?.map((course: any) => (
            <Card key={course._id} course={course} />
          ))}
        </div>
      </div>
    </div>
  );
}
