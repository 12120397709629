import React from "react";
import { Outlet } from "react-router-dom";
import Nav from "../layouts/Nav";
import Footer from "@/components/core/Footer";

const CourseLayout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <Nav />

      {/* Main Content */}
      <main className="flex-grow p-4 mt-16">
        <Outlet></Outlet>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default CourseLayout;
