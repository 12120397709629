import axios from "axios";

const create = async (payload: any) => {
  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + "/sliders",
    payload
  );
  return response;
};

const getAll = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/sliders"
  );
  console.log(response);
  return response;
};

const get = async (id: any) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "/sliders/" + id
  );
  return response;
};

const update = async (id: any, payload: any) => {
  const response = await axios.patch(
    process.env.REACT_APP_API_BASE_URL + "/sliders/" + id,
    payload
  );
  return response;
};

const remove = async (id: any) => {
  const response = await axios.delete(
    process.env.REACT_APP_API_BASE_URL + "/sliders/" + id
  );
  return response;
};

export const slidersService = { create, getAll, get, update, remove };
