import { AddressDto } from "@/data/dtos/address.dto";
import axios from "axios";
import { apiClient } from "./base.service";

// const create = async (payload: AddressDto) => {
//   const response = await axios.post(
//     process.env.REACT_APP_API_BASE_URL + "/addresses",
//     payload
//   );
//   return response;
// };

// const getAll = async () => {
//   const response = await axios.get(
//     process.env.REACT_APP_API_BASE_URL + "/addresses"
//   );
//   return response;
// };

// const get = async (id: string) => {
//   const response = await axios.get(
//     process.env.REACT_APP_API_BASE_URL + "/addresses/" + id
//   );
//   return response;
// };

// const findByContact = async (id: string) => {
//   const response = await axios.get(
//     process.env.REACT_APP_API_BASE_URL + "/addresses/contact/" + id
//   );
//   return response;
// };

// const update = async (id: string, payload: AddressDto) => {
//   const response = await axios.patch(
//     process.env.REACT_APP_API_BASE_URL + "/addresses/" + id,
//     payload
//   );
//   return response;
// };

// const remove = async (id: string) => {
//   const response = await axios.delete(
//     process.env.REACT_APP_API_BASE_URL + "/addresses/" + id
//   );
//   return response;
// };

const addressService = {
  create: async (payload: AddressDto) =>
    await apiClient.post("/addresses", payload),
  getAll: async () => await apiClient.get("/addresses"),
  get: async (id: string) => await apiClient.get("/addresses/" + id),
  update: async (id: string, payload: AddressDto) =>
    await apiClient.patch("/addresses/" + id, payload),
  remove: async (id: string) => await apiClient.delete("/addresses/" + id),
  findByContact: async (id: string) =>
    await apiClient.get("/addresses/contact/" + id),
};

export default addressService;
