import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputLabel from "../../../../components/InputLabel";
import TextInput from "../../../../components/core/TextInput";
import InputError from "../../../../components/core/InputError";
import PrimaryButton from "../../../../components/PrimaryButton";
import { resetPassword } from "../../../../actions/authAction";
import { setError } from "../../../../actions/message";
import { url } from "../../../../utils";

export default function ResetPassword() {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { message } = useSelector((state: any) => state.message);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const onChangePassword = (e: any) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangeRePassword = (e: any) => {
    const rePassword = e.target.value;
    setRePassword(rePassword);
  };

  const handleResetPassword = (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (password !== rePassword) {
      setLoading(false);
      dispatch(
        setError({
          message: "Şifre İçerleri Aynı Olmalı!",
          errors: {},
        })
      );
      return;
    }

    dispatch(resetPassword({ password, token }) as any)
      .then((res: any) => {
        navigate(url("auth.login"));
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  return (
    <div className="rounded w-full border-slate-100 dark:text-slate-100 grid grid-cols-1 gap-4">
      Şifremi Değiştir
      {message?.message && (
        <div className="flex flex-col gap-4 rounded bg-red-500 p-4">
          {message.message}
        </div>
      )}
      <div>
        <InputLabel htmlFor="password" value="Password" />
        <TextInput
          type="password"
          name="password"
          value={password}
          className="mt-1 block w-full"
          autoComplete="true"
          isFocused={true}
          onChange={(e: any) => onChangePassword(e)}
        />
        <InputError messages={message?.errors?.password} className="mt-2" />
      </div>
      <div>
        <InputLabel htmlFor="rePassword" value="Re Password" />
        <TextInput
          type="password"
          name="rePassword"
          value={rePassword}
          className="mt-1 block w-full"
          autoComplete="true"
          isFocused={true}
          onChange={(e: any) => onChangeRePassword(e)}
        />
      </div>
      <div className="flex items-center justify-end mt-4">
        <PrimaryButton
          className="ms-4"
          onClick={handleResetPassword}
          disabled={loading}
        >
          Şifremi Değiştir.
        </PrimaryButton>
      </div>
    </div>
  );
}
