// AddResourceModal.tsx

import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { uploadVideo } from "../../../../../../actions/courses.actions";
import { useParams } from "react-router-dom";
import { ResourceDto } from "@/data/dtos/resource.dto";
import { QuestionDto } from "@/data/dtos/question.dto";
import { ResourceType } from "@/data/enums/resource-type.enum";

interface AddResourceModalProps {
  open: boolean;
  onClose: () => void;
  onAddResource: (resource: ResourceDto) => void;
  onUpdateResource: (resource: ResourceDto) => void;
  sectionId: string;
  resource: any;
}

const AddResourceModal: React.FC<AddResourceModalProps> = ({
  open,
  onClose,
  onAddResource,
  onUpdateResource,
  sectionId,
  resource,
}) => {
  const { id } = useParams();
  const { URL } = useSelector((state: any) => state.uploads);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState(0);
  const [resourceType, setResourceType] = useState<ResourceType>(
    ResourceType.VIDEO
  );
  // Video için ek alanlar
  const [videoDuration, setVideoDuration] = useState(0);
  // Quiz için ek alanlar
  const [quizTitle, setQuizTitle] = useState("");
  const [questions, setQuestions] = useState<QuestionDto[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentOptions, setCurrentOptions] = useState<string[]>([""]);
  const [currentCorrectAnswer, setCurrentCorrectAnswer] = useState<number>(0);
  // Canlı Ders için ek alanlar
  const [liveClassStartTime, setLiveClassStartTime] = useState<string | null>(
    null
  );
  const [liveClassDuration, setLiveClassDuration] = useState(0);

  const resetForm = () => {
    setTitle("");
    setOrder(0);
    setVideoDuration(0);
    setLiveClassStartTime(null);
    setLiveClassDuration(0);
    setQuizTitle("");
    setQuestions([]);
    setCurrentQuestion("");
    setCurrentOptions([""]);
    setCurrentCorrectAnswer(0);
    onClose();
  };

  // Quiz sorularını yönetmek için fonksiyonlar
  const addOption = () => {
    setCurrentOptions([...currentOptions, ""]);
  };

  const removeOption = (index: number) => {
    const updatedOptions = currentOptions.filter((_, i) => i !== index);
    setCurrentOptions(updatedOptions);
  };

  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions = [...currentOptions];
    updatedOptions[index] = value;
    setCurrentOptions(updatedOptions);
  };

  const addQuestion = () => {
    if (currentQuestion && currentOptions.length > currentCorrectAnswer) {
      const newQuestion: QuestionDto = {
        question: currentQuestion,
        options: currentOptions,
        correctAnswer: currentCorrectAnswer,
      };
      setQuestions([...questions, newQuestion]);
    } else {
      alert("Tüm alanları doldurun ve doğru cevabı seçin.");
    }
  };

  const removeQuestion = (index: number) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append("file", file);
      // formData.append("section", formData.);
      dispatch(uploadVideo(formData) as any);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "video/mp4": [".mp4", ".MP4"],
    },
    maxFiles: 1,
  });

  const handleSaveResource = () => {
    const updatedResource: ResourceDto = {
      ...resource,
      title,
      order,
      type: resourceType,
      section: sectionId,
      course: id!,
      video:
        resourceType === ResourceType.VIDEO
          ? {
              _id: resource?.video?._id,
              title,
              url: URL,
              duration: videoDuration,
              visibility: "private",
            }
          : undefined,
      quizz:
        resourceType === ResourceType.QUIZZ
          ? { _id: resource?.quizz?._id, title: quizTitle, questions }
          : undefined,
      liveClass:
        resourceType === ResourceType.LIVE_CLASS
          ? {
              _id: resource?.liveClass?._id,
              title,
              startTime: liveClassStartTime,
              duration: liveClassDuration,
              instructor: [],
              students: [],
            }
          : undefined,
    };

    resource
      ? onUpdateResource(updatedResource)
      : onAddResource(updatedResource);
    resetForm();
  };

  useEffect(() => {
    console.log("useEffect 3");
    if (resource) {
      setTitle(resource.title);
      setOrder(resource.order);
      setResourceType(resource.type as ResourceType);
      setVideoDuration(resource.video?.duration || 0);
      setQuizTitle(resource.quizz?.title || "");
      setQuestions(resource.quizz?.questions || []);
      setLiveClassStartTime(
        resource.liveClass?.startTime ? resource.liveClass.startTime : null
      );
      setLiveClassDuration(resource.liveClass?.duration || 0);
    }
  }, [resource]);

  return (
    <Modal open={open} onClose={resetForm}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          {resource ? "Düzenle" : "Kaynak Ekle"}
        </Typography>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Kaynak Türü</InputLabel>
          <Select
            value={resourceType}
            label="Kaynak Türü"
            onChange={(e) => setResourceType(e.target.value as ResourceType)}
          >
            <MenuItem value="video">Video</MenuItem>
            <MenuItem value="quiz">Quiz</MenuItem>
            <MenuItem value="liveClass">Canlı Ders</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Başlık"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />

        <TextField
          label="Sıra"
          type="number"
          value={order}
          onChange={(e) => setOrder(Number(e.target.value))}
          fullWidth
          sx={{ mb: 2 }}
        />

        {resourceType === ResourceType.VIDEO && (
          <Box
            sx={{
              border: "2px solid gray",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
              padding: 4,
              borderRadius: 5,
            }}
          >
            <Typography variant="h3">Video</Typography>

            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed gray",
                padding: 2,
                textAlign: "center",
              }}
            >
              <input {...getInputProps()} />
              <Typography>
                Video yüklemek için tıklayın veya sürükleyin
              </Typography>
            </Box>

            <Box>
              <div className="w-full">
                <video key={URL} src={URL} controls></video>
              </div>
            </Box>
          </Box>
        )}

        {resourceType === ResourceType.QUIZZ && (
          <>
            <Typography variant="h6">Sorular</Typography>
            {questions.length > 0 && (
              <List>
                {questions.map((q, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => removeQuestion(index)}
                      >
                        <Remove />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={`Soru ${index + 1}: ${q.question}`}
                      secondary={`Seçenekler: ${q.options.join(
                        ", "
                      )} | Doğru Cevap: ${q.options[q.correctAnswer]}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}

            <Box sx={{ border: "1px solid #ccc", p: 2, mt: 2 }}>
              <Typography variant="subtitle1">Soru Ekle</Typography>
              <TextField
                label="Soru"
                value={currentQuestion}
                onChange={(e) => setCurrentQuestion(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />

              {currentOptions.map((option, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <TextField
                    label={`Seçenek ${index + 1}`}
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    fullWidth
                  />
                  <IconButton onClick={() => removeOption(index)}>
                    <Remove />
                  </IconButton>
                </Box>
              ))}

              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={addOption}
                sx={{ mb: 2 }}
              >
                Seçenek Ekle
              </Button>

              <TextField
                label="Doğru Cevap Indexi (0'dan başlar)"
                type="number"
                value={currentCorrectAnswer}
                onChange={(e) =>
                  setCurrentCorrectAnswer(Number(e.target.value))
                }
                fullWidth
                sx={{ mb: 2 }}
              />

              <Button variant="contained" onClick={addQuestion}>
                Soruyu Ekle
              </Button>
            </Box>
          </>
        )}

        {resourceType === ResourceType.LIVE_CLASS && (
          <>
            <TextField
              label="Başlangıç Zamanı"
              type="time"
              value={liveClassStartTime || ""} // "HH:mm" formatında string
              onChange={(e) => setLiveClassStartTime(e.target.value)} // Direkt "HH:mm" olarak alıyoruz
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Süre (dk)"
              type="number"
              value={liveClassDuration}
              onChange={(e) => setLiveClassDuration(Number(e.target.value))}
              fullWidth
              sx={{ mb: 2 }}
            />
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveResource}
          sx={{ mt: 2 }}
        >
          {resource ? "Düzenle" : "Ekle"}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddResourceModal;
