export enum PaymentChannels {
  MOBILE = 'MOBILE',
  WEB = 'WEB',
  MOBILE_WEB = 'MOBILE_WEB',
  MOBILE_IOS = 'MOBILE_IOS',
  MOBILE_ANDROID = 'MOBILE_ANDROID',
  MOBILE_WINDOWS = 'MOBILE_WINDOWS',
  MOBILE_TABLET = 'MOBILE_TABLET',
  MOBILE_PHONE = 'MOBILE_PHONE',
}
