import { LanguageEnum } from '../enums/language.enum';

export const languageArray = [
  LanguageEnum.TURKISH,
  LanguageEnum.ENGLISH,
  LanguageEnum.FRENCH,
  LanguageEnum.GERMAN,
  LanguageEnum.ITALIAN,
  LanguageEnum.JAPANESE,
  LanguageEnum.KOREAN,
  LanguageEnum.PORTUGUESE,
  LanguageEnum.RUSSIAN,
  LanguageEnum.CHINESE,
  LanguageEnum.ARABIC,
  LanguageEnum.INDONESIAN,
  LanguageEnum.HINDI,
  LanguageEnum.VIETNAMESE,
  LanguageEnum.MALAY,
  LanguageEnum.THAI,
  LanguageEnum.TELUGU,
  LanguageEnum.PERSIAN,
  LanguageEnum.HEBREW,
  LanguageEnum.CATALAN,
  LanguageEnum.POLISH,
  LanguageEnum.BULGARIAN,
  LanguageEnum.CROATIAN,
  LanguageEnum.CZECH,
  LanguageEnum.DANISH,
  LanguageEnum.DUTCH,
  LanguageEnum.FINNISH,
  LanguageEnum.GREEK,
  LanguageEnum.LATVIAN,
  LanguageEnum.LITHUANIAN,
  LanguageEnum.NORWEGIAN,
  LanguageEnum.ROMANIAN,
  LanguageEnum.SERBIAN,
  LanguageEnum.SLOVAK,
  LanguageEnum.SLOVENIAN,
  LanguageEnum.SWEDISH,
  LanguageEnum.UKRAINIAN,
  LanguageEnum.OTHER,
];
