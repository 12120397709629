import {
  CREATE_ORDER_FAILED,
  CREATE_ORDER_SUCCESS,
  FETCH_ORDER,
  FETCH_ORDER_FAILED,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDERS,
  FETCH_ORDERS_FAILED,
  FETCH_ORDERS_SUCCESS,
  UPDATE_ORDER_FAILED,
  UPDATE_ORDER_SUCCESS,
  REMOVE_ORDER_FAILED,
  REMOVE_ORDER_SUCCESS,
} from "../actions/type";

interface OrderState {
  order: any;
  orders: any[];
  currentOrder: any;
  status: string;
  listStatus: string;
}

const initialState: OrderState = {
  order: null,
  orders: [],
  currentOrder: null,
  status: "idle",
  listStatus: "idle",
};

export default function orderReducer(
  state = initialState,
  action: any
): OrderState {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ORDER_SUCCESS:
      console.log(payload);
      if (payload.statuscode === 200) {
        return { ...state, order: payload, status: "success" };
      } else {
        return { ...state, order: payload, status: "failed" };
      }

    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.map((order: any) =>
          order._id === payload._id ? payload : order
        ),
        status: "success",
      };

    case REMOVE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.filter((order: any) => order._id !== payload._id),
        status: "success",
      };

    case FETCH_ORDERS:
      return {
        ...state,
        listStatus: "loading",
      };

    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: payload.data,
        listStatus: "success",
      };

    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        currentOrder: payload.data,
        status: "success",
      };

    case FETCH_ORDER:
      return {
        ...state,
        currentOrder: null,
        status: "loading",
      };

    case FETCH_ORDER_FAILED:
    case FETCH_ORDERS_FAILED:
    case UPDATE_ORDER_FAILED:
    case REMOVE_ORDER_FAILED:
    case CREATE_ORDER_FAILED:
      return { ...state, order: null, status: "failed", listStatus: "failed" };

    default:
      return state;
  }
}
