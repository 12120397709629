import React from "react";

const About = () => {
  return (
    <div className="">
      <section className="py-24 relative">
        <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
          <div className="w-full justify-start items-center gap-12 grid lg:grid-cols-2 grid-cols-1">
            <div className="w-full justify-center items-start gap-6 grid sm:grid-cols-2 grid-cols-1 lg:order-first order-last">
              <div className="pt-24 lg:justify-center sm:justify-end justify-start items-start gap-2.5 flex">
                <img
                  className=" rounded-xl object-cover"
                  src="/assets/3.jpeg"
                  alt="about Us background"
                />
              </div>
              <img
                className="sm:ml-0 ml-auto rounded-xl object-cover"
                src="/assets/4.jpeg"
                alt="about Us  2"
              />
            </div>
            <div className="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
              <div className="w-full flex-col justify-center items-start gap-8 flex">
                <div className="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                  <h2 className="text-gray-900 text-4xl font-bold font-manrope leading-normal lg:text-start text-center">
                    Hakkımızda
                  </h2>
                  <p className="text-gray-500 text-base font-normal leading-relaxed lg:text-start text-center">
                    ay Online Eğitim ve Kurgusal Etkinlik Platformu Burçin
                    Alpacar Arda ve Erhan Arda tarafından kurulmuş olup, eş
                    başkanlıkta yönetilmektedir. Stratejik İletişim Danışmanı
                    Burçin Alpacar Arda, U.Ü. İ.İ.B.F/İktisat Bölümü mezunudur.
                    1996 yılında Cenajans Grey Barışçı ofisi ile reklam
                    dünyasına adım atmış olup profesyonel yaşamını İlbak Holding
                    bünyesinde tamamlamıştır. 2003 yılında kurduğu Say Medya ile
                    markalara, ulusal/uluslararası platformlarda reklam ve pr
                    başlığı altında tüm alanlarda hizmet vermeye devam
                    etmektedir. Bugüne kadar yayınlanmış dört eseri
                    bulunmaktadır. Yazar kimliği ile de mevcudiyetini
                    sürdürmektedir. 90’lı yılların sonunda yapımcı ve
                    sunuculuğunu üstlendiği radyo programını bir yılı aşkın süre
                    sürdürmüştür.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full justify-start items-center gap-12 grid lg:grid-cols-2 grid-cols-1 my-16">
            <p className="text-gray-500 text-base font-normal leading-relaxed lg:text-start text-center">
              2010’lu yıllarda ise TV Programı hazırlamış; yapımcı ve
              sunuculuğunu üstlenerek yüzden fazla program hazırlayıp sunmuştur.
              Yine aynı yıllarda birçok gazetede köşe yazıları ve sanat içerikli
              yazıları ile gündeme gelmiştir. Kişisel gelişim alanında birçoğu
              uluslararası düzeyde geçerli yaklaşık elliye yakın sertifikaya
              sahiptir. İş dünyasının yanı sıra toplumsal fayda merkezinde kalan
              ve topluma karşı sorumluluklarını çocuklar özelinde sürdürmeyi
              amaç edinen Sn.Arda 2021 yılında bu alanda yaptığı çalışmalar ile
              ‘Fark Yaratanlar’ ödülüne layık görülmüş olup halen Bursa Koruyucu
              Aile Derneği Başkanlık görevini yürütmektedir. Eş zamanlı olarak
              dernek bünyesinde yazdığı Koruyucu Ailelik ile Evlat Edinmede Aile
              Ruhu projesinin yürütücüsü olarak belirlenen üniversitelerdeki
              panellere hali hazırda ilham veren konuşmacı sıfatı ile katılım
              sağlamaktadır. Birçok kurumsal markanın da ilham veren konuşmacısı
              ve ‘Ruhumuz olsun; Satış Pazarlama Stratejilerine Ruh Katalım’
              eğitiminin de hazırlayıcısı ve eğitmenidir. Yayıncı yazar Erhan
              Arda, Uludağ Üniversitesi İ.İ.B.F Kamu Yönetimi mezunudur. Başvuru
              kitapları Ekonomi Sözlüğü, Sosyal Bilimler Sözlüğü, müfettişlik
              uzmanlık alanlarında uluslararsı ilişkiler iktisat ve maliye
              dallarında akademisyenlerle beraber elliye yakın eser yazmış
              popüler bir yazardır. Başlıca kitapları KPSS ve Kurum Sınavlarına
              Yönelik iktisat tarihi, mikro ve makro iktisat, Maliye,
              Uluslararası İlişkiler olarak sayılabilir. İktisat, uluslararası
              ilşkiler ve edebiyata özel bir ilgisi vardır. Bir dönem Haber
              16’da köşe yazarlığı yapmıştır. Yazar kimliği ile yakın zamanda
              ekonomi kategorisi dışında ekonomi edebiyatı alanında da çalışmaya
              başlayan ve Covid-19 sürecinin dünyaya ve ülkelere etkisi üzerine
              bu alanda yayınladığı ilk eseri 'Çin Hapşırığı' adlı eser ile
              gündeme oturan ARDA, konuya getirdiği farklı bakış açısı ile
              uluslararası platformda okurlar ile buluşmaktadır. 2017 yılı
              itibari ile ‘Sayda Yayıncılık’ adı altında YENİ BİR yayıncılık
              hayatına başlamış olan ARDA, çeşitli kategorilerde yüze yakın
              kitap yayınlamış ve okurlarla buluşmasını sağlamıştır.
            </p>
            <div className="w-full justify-center items-start gap-6 grid sm:grid-cols-2 grid-cols-1 order-last">
              <div className="pt-24 lg:justify-center sm:justify-end justify-start items-start gap-2.5 flex">
                <img
                  className=" rounded-xl object-cover"
                  src="/assets/2.jpeg"
                  alt="about Us"
                />
              </div>
              <img
                className="sm:ml-0 ml-auto rounded-xl object-cover"
                src="/assets/1.jpeg"
                alt="about Us"
              />
            </div>
          </div>
          <div className="w-full lg:justify-center justify-center items-center sm:gap-10 gap-5 inline-flex">
            <div className="flex-col justify-start items-start inline-flex">
              <h3 className="text-gray-900 text-4xl font-bold font-manrope leading-normal">
                21+
              </h3>
              <h6 className="text-gray-500 text-base font-normal leading-relaxed">
                Years of Experience
              </h6>
            </div>
            <div className="flex-col justify-start items-start inline-flex">
              <h4 className="text-gray-900 text-4xl font-bold font-manrope leading-normal">
                125+
              </h4>
              <h6 className="text-gray-500 text-base font-normal leading-relaxed">
                Successful Projects
              </h6>
            </div>
            <div className="flex-col justify-start items-start inline-flex">
              <h4 className="text-gray-900 text-4xl font-bold font-manrope leading-normal">
                52+
              </h4>
              <h6 className="text-gray-500 text-base font-normal leading-relaxed">
                Happy Clients
              </h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
