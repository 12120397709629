import React, { useState } from "react";
import { AiFillStar } from "react-icons/ai";

const StarRating = ({ maxStars = 5, onRatingSelect }) => {
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);
  const [selectedStar, setSelectedStar] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredStar(index);
  };

  const handleMouseLeave = () => {
    setHoveredStar(null);
  };

  const handleClick = (index: number) => {
    setSelectedStar(index);
    onRatingSelect(index); // seçilen yıldız sayısını üst bileşene iletin
  };

  return (
    <div className="flex gap-1">
      {[...Array(maxStars)].map((_, index) => {
        const starIndex = index + 1;
        return (
          <AiFillStar
            key={index}
            className={`text-xl cursor-pointer ${
              starIndex <= (hoveredStar ?? selectedStar ?? 0)
                ? "text-yellow-500"
                : "text-gray-300"
            }`}
            onMouseEnter={() => handleMouseEnter(starIndex)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(starIndex)}
          />
        );
      })}
    </div>
  );
};

// Bu bileşeni istediğiniz yerde kullanabilirsiniz
const ReviewsComponent = () => {
  const [selectedRating, setSelectedRating] = useState<number | null>(null);

  const handleRatingSelect = (rating: number) => {
    setSelectedRating(rating);
    console.log(`Seçilen yıldız sayısı: ${rating}`);
  };

  return (
    <div>
      <StarRating maxStars={5} onRatingSelect={handleRatingSelect} />
    </div>
  );
};

export default ReviewsComponent;
export { StarRating };
