import { OrderStatus } from "@/data/enums/order-status.enum";
import { DataGrid } from "@mui/x-data-grid";
import { head } from "lodash";
import React from "react";
import { render } from "react-dom";

export default function OrdersChart({ recentOrders }) {
  const columns = [
    {
      field: "_id",
      headerName: "Order Id",
      width: 300,
    },
    {
      field: "buyer",
      headerName: "Buyer",
      width: 200,
      valueGetter: (param: any) => param.name + " " + param.surname,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
    },
    {
      field: "paidPrice",
      headerName: "Paid Price",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        const statusClass =
          params.row.status === OrderStatus.PENDING
            ? "bg-yellow-400 text-black"
            : "bg-red-600";

        return (
          <div
            className={`text-white flex justify-center font-bold  ${
              params.row.status === OrderStatus.PAID
                ? "bg-green-500"
                : statusClass
            }`}
          >
            {params.row.status}
          </div>
        );
      },
      // renderCell: (params) => {
      //   console.log(params);
      //   return <div>{params}</div>;
      // return (
      //   <div
      //     className={
      //       params === OrderStatus.PAID
      //         ? "bg-green-500"
      //         : params === OrderStatus.PENDING
      //         ? "bg-yellow-500"
      //         : "bg-red-500"
      //     }
      //   >
      //     {params}
      //   </div>
      // );
    },
    // },
  ];

  const pageSizeOptions = [5, 25, 50, 100];

  return (
    <section className="bg-white rounded shadow-md p-6">
      <DataGrid
        rows={recentOrders}
        columns={columns}
        pageSizeOptions={pageSizeOptions}
        checkboxSelection
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
          },
        }}
        getRowId={(row) => row._id} // Benzersiz ID için _id kullan
      />
    </section>
  );
}
