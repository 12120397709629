import { setError } from "@/actions/message";
import { changePassword } from "@/actions/users.actions";
import InputError from "@/components/core/InputError";
import { UserContext } from "@/context/UserContext";
import { ChangePasswordDto } from "@/data/dtos/change-password.dto";
import message from "@/reducers/message";
import { Container, Typography, Grid, TextField, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Security() {
  const dispatch = useDispatch();
  const [passwordForm, setPasswordForm] = useState<ChangePasswordDto>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { message } = useSelector((state: any) => state.message);
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }
  const { user } = userContext;

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePasswordChangeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Data Submitted:", passwordForm);
    if (user?._id) {
      if (passwordForm.newPassword !== passwordForm.confirmPassword) {
        return dispatch(
          setError({
            message: "Sifreler uyuşmuyor",
            errors: {
              newPassword: { errors: ["Sifreler uyuşmuyor"] },
              confirmPassword: { errors: ["Sifreler uyuşmuyor"] },
            },
          })
        );
      }
      dispatch(changePassword(user?._id, passwordForm) as any);
    } else {
      dispatch(setError("Kullanıcı bulunamadı"));
    }
  };

  return (
    <div>
      <Container maxWidth="sm" className="mt-4 rounded shadow p-4 bg-white">
        <Typography variant="h5" component="h1" gutterBottom>
          Change my password
        </Typography>
        <form onSubmit={handlePasswordChangeSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                name="currentPassword"
                type="password"
                value={passwordForm.currentPassword}
                onChange={handlePasswordChange}
                variant="outlined"
              />
              <InputError
                messages={message?.errors?.currentPassword}
                className="mt-2"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="New Password"
                type="password"
                name="newPassword"
                value={passwordForm.newPassword}
                onChange={handlePasswordChange}
                variant="outlined"
              />
              <InputError
                messages={message?.errors?.newPassword}
                className="mt-2"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                value={passwordForm.confirmPassword}
                onChange={handlePasswordChange}
                type="password"
                variant="outlined"
              />
              <InputError
                messages={message?.errors?.confirmPassword}
                className="mt-2"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Save Password
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
