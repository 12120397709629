import { get } from "@/actions/orders.actions";
import { getAll as getAllCourses } from "@/actions/courses.actions";
import { OrderData } from "@/data/dtos/order.dto";
import { UserData } from "@/data/dtos/user.dto";
import { CurrencyCode } from "@/data/enums/currency.enum";
import { OrderStatus } from "@/data/enums/order-status.enum";
import { PaymentChannels } from "@/data/enums/payment-channels.enum";
import {
  Typography,
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAll } from "@/actions/users.actions";

export default function UpdateOrder() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentOrder } = useSelector((state: any) => state.order);
  const { users, listStatus } = useSelector((state: any) => state.users);
  const { courses, status } = useSelector((state: any) => state.courses);
  const [selectedUser, setSelectedUser] = useState<UserData>();
  const [orderData, setOrderData] = React.useState<OrderData>({
    price: 0,
    paidPrice: 0,
    currency: CurrencyCode.TRY,
    installment: 0,
    buyer: "",
    courses: [],
    paymentChannel: PaymentChannels.WEB,
    shippingAddress: "",
    billingAddress: "",
    status: OrderStatus.PAID,
  });

  useEffect(() => {
    if (listStatus === "idle") {
      dispatch(getAll() as any);
    }
  }, [listStatus, dispatch]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAllCourses() as any);
    }
  }, [dispatch, status]);

  useEffect(() => {
    dispatch(get(id!) as any);
  }, [dispatch, id]);

  useEffect(() => {
    if (currentOrder) {
      setOrderData((prev) => ({
        ...prev,
        ...currentOrder,
        buyer: currentOrder.buyer._id,
      }));
    }
  }, [currentOrder]);

  const onChange = (field: string, value: any) => {
    console.log(value);
    if (orderData && field) {
      console.log(value);
      setOrderData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleUpdateOrder = async () => {};

  return (
    <div className="w-full bg-white rounded shadow p-4">
      <Typography variant="h6">Sipariş Oluştur</Typography>
      <Box sx={{ width: "100%" }}>
        <div className="grid grid-cols-4 gap-4 items-center">
          <TextField
            label="Fiyat"
            type="number"
            fullWidth
            margin="normal"
            value={orderData.price}
            onChange={(e) => onChange("price", e.target.value)}
            className="col-span-1"
          />
          <TextField
            label="Odenen Fiyat"
            type="number"
            fullWidth
            margin="normal"
            value={orderData.paidPrice}
            onChange={(e) => onChange("paidPrice", e.target.value)}
            className="col-span-1"
          />

          <TextField
            label="Taksit Sayısı"
            type="number"
            fullWidth
            margin="normal"
            value={orderData.installment}
            onChange={(e) => onChange("installment", e.target.value)}
            className="col-span-1"
          />
          <div>
            <InputLabel id="currency">Para Birimi</InputLabel>
            <Select
              labelId="currency"
              id="currency"
              fullWidth
              value={orderData.currency}
              onChange={(e) => onChange("currency", e.target.value)}
              className="col-span-1"
            >
              {Object.values(CurrencyCode).map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <InputLabel id="paymentChannel">Ödeme Kanalı</InputLabel>
            <Select
              labelId="paymentChannel"
              id="paymentChannel"
              fullWidth
              value={orderData.paymentChannel}
              onChange={(e) => onChange("paymentChannel", e.target.value)}
              className="col-span-1"
            >
              {Object.values(PaymentChannels).map((channel) => (
                <MenuItem key={channel} value={channel}>
                  {channel}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="col-span-1">
            <InputLabel id="buyer">Müşteri</InputLabel>
            <Select
              labelId="buyer"
              id="buyer"
              value={orderData.buyer}
              fullWidth
              label="Müşteri"
              onChange={(e) => {
                onChange("buyer", e.target.value);
                const selectedUser = users.find(
                  (user: any) => user._id === e.target.value
                );
                setSelectedUser(selectedUser);
              }}
            >
              {users?.map((user: any) => (
                <MenuItem key={user._id} value={user._id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="col-span-1">
            <InputLabel id="shippingAddress">Kargo Adresi</InputLabel>
            <Select
              labelId="shippingAddress"
              id="shippingAddress"
              value={orderData.shippingAddress}
              fullWidth
              label="Müşteri"
              onChange={(e) => onChange("shippingAddress", e.target.value)}
            >
              {selectedUser?.addresses?.map((address: any) => (
                <MenuItem key={address._id} value={address._id}>
                  {address.address}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="col-span-1">
            <InputLabel id="billingAddress">Fatura Adresi</InputLabel>
            <Select
              labelId="billingAddress"
              id="billingAddress"
              value={orderData.billingAddress}
              fullWidth
              label="Müşteri"
              onChange={(e) => onChange("billingAddress", e.target.value)}
            >
              {selectedUser?.billingAddresses?.map((address: any) => (
                <MenuItem key={address._id} value={address._id}>
                  {address.address}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="col-span-1">
            <InputLabel id="courses">Kurslar</InputLabel>
            <Select
              labelId="courses"
              id="courses"
              value={orderData.courses}
              fullWidth
              label="Müşteri"
              multiple
              onChange={(e) => onChange("courses", e.target.value)}
            >
              {courses.map((course: any) => (
                <MenuItem key={course._id} value={course._id}>
                  {course.title}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="col-span-1">
            <InputLabel id="status">Ödeme Durumu</InputLabel>
            <Select
              labelId="status"
              id="status"
              value={orderData.status}
              fullWidth
              label="Müşteri"
              onChange={(e) => onChange("status", e.target.value)}
            >
              {Object.values(OrderStatus).map((status: any) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </Box>
      <div className="mt-4">
        <Button
          variant="contained"
          color="warning"
          onClick={() => handleUpdateOrder()}
        >
          Güncelle
        </Button>
      </div>
    </div>
  );
}
