import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

interface AccordionProps {
  title: string;
  icon: any;
  to: string;
  onClick?: any;
}

export default function MenuItems({
  title,
  icon,
  to,
  onClick,
}: AccordionProps) {
  return (
    <li className="my-px" onClick={onClick}>
      <Link
        to={to}
        className="flex flex-row items-center h-12 px-4 rounded-lg text-gray-600 hover:bg-gray-100"
      >
        <FontAwesomeIcon icon={icon} className="text-gray-400" />
        <span className="ml-3">{title}</span>
      </Link>
    </li>
  );
}
