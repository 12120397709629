import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  updateSection,
  addSection,
} from "../../../../../../actions/courses.actions";
import { useParams } from "react-router-dom";
import { SectionDto } from "@/data/dtos/section.dto";

interface AddSectionModalProps {
  open: boolean;
  onClose: () => void;
  onAddSection?: (section: SectionDto) => void; // Opsiyonel hale getirdik
  onUpdateSection?: (section: SectionDto) => void; // Yeni ekledik
  section?: SectionDto; // Düzenlenecek bölüm (opsiyonel)
}

const AddSectionModal: React.FC<AddSectionModalProps> = ({
  open,
  onClose,
  onAddSection,
  onUpdateSection,
  section,
}) => {
  const dispatch = useDispatch();
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionOrder, setSectionOrder] = useState(0);
  const { id } = useParams();
  const courseId = id;
  useEffect(() => {
    console.log("useEffect 1");
    if (section) {
      setSectionTitle(section.title);
      setSectionOrder(section.order || 0);
    } else {
      setSectionTitle("");
      setSectionOrder(0);
    }
  }, [section]);

  const handleSaveSection = () => {
    const newSection = {
      title: sectionTitle,
      order: sectionOrder,
      resources: section?.resources || [],
      course: courseId,
    };

    if (section?._id) {
      // Güncelleme işlemi
      dispatch(updateSection(section._id, newSection) as any);
    } else {
      // Ekleme işlemi
      dispatch(addSection(newSection) as any);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <h2>{section ? "Bölüm Düzenle" : "Bölüm Ekle"}</h2>
        <TextField
          label="Bölüm Başlığı"
          value={sectionTitle}
          onChange={(e) => setSectionTitle(e.target.value)}
          fullWidth
          sx={{ my: 2 }}
        />

        <TextField
          label="Sıra"
          type="number"
          value={sectionOrder}
          onChange={(e) => setSectionOrder(Number(e.target.value))}
          fullWidth
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveSection}
          sx={{ mt: 2 }}
        >
          {section ? "Güncelle" : "Ekle"}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddSectionModal;
